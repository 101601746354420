/* eslint-disable no-console */
import React, { useState, useContext } from 'react'
import {
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  AccordionDetails,
  Accordion as MuiAccordion,
  AccordionSummaryProps,
  styled,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  ListItem,
  List
} from '@mui/material'
import { FiberManualRecord as FiberManualRecordIcon } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useNavigate } from 'react-router-dom'
import { Color } from '@mathison-inc/components'
import { AccessContext } from 'context/AccessContext'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `none`,
  width: '100%',
  padding: 0,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(() => ({
  flexDirection: 'row-reverse',
  padding: 0,
  height: 32,
  marginLeft: 0,
  minHeight: 32,

  '& .MuiAccordionSummary-content': {
    minWidth: 0,
    flex: '1 1 auto',
    height: 32
  }
}))

interface ExpandableListProps {
  label: string
  icon?: React.ReactNode
  sublist?: { label: string }[]
  sidePanelExpanded: boolean
  url: string
}

const ExpandableList = ({
  label,
  icon,
  sublist,
  sidePanelExpanded,
  url
}: ExpandableListProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const navigate = useNavigate()
  const isMainTabSelected = url === window.location.pathname
  const { hasCompletedAnAssessment } = useContext(AccessContext)

  return (
    <div style={{ marginBottom: '8px' }}>
      <Accordion expanded={expanded} disableGutters>
        <AccordionSummary expandIcon={null} tabIndex={-1}>
          <ListItemButton
            sx={{
              minHeight: 32,
              height: 32,
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: Color.grape400,
                '& .MuiListItemIcon-root': {
                  // use the class name of the ListItemIcon
                  color: Color.white
                },
                '& .MuiTypography-root': { color: Color.white },
                '& .MuiSvgIcon-root': { color: Color.white }
              },
              justifyContent: sidePanelExpanded ? 'initial' : 'center',
              px: sidePanelExpanded ? 2.5 : 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
              color: isMainTabSelected ? '#fff' : Color.grey700,
              backgroundColor: isMainTabSelected ? Color.grape400 : '#fff'
            }}
            onClick={() => {
              navigate(url)
              setExpanded(true)
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
                width: sidePanelExpanded ? '24px' : '20px',
                mr: sidePanelExpanded ? 1.5 : 'auto',
                ml: sidePanelExpanded ? 0 : '18px',
                color: isMainTabSelected ? '#fff' : Color.grey500
              }}
            >
              {icon ?? <div style={{ height: '24px', width: '24px' }} />}
            </ListItemIcon>
            {sidePanelExpanded && (
              <>
                <ListItemText
                  sx={{ opacity: sidePanelExpanded ? 1 : 0 }}
                  primary={
                    <Typography variant='body14Light'>{label}</Typography>
                  }
                />
                <IconButton
                  onClick={(e: any) => {
                    setExpanded(!expanded)
                    e.stopPropagation()
                  }}
                  disableRipple
                  sx={{
                    marginLeft: 'auto',
                    minWidth: 0,
                    flex: '0 0 auto'
                  }}
                >
                  <ExpandMoreIcon
                    tabIndex={0}
                    sx={{
                      color: isMainTabSelected ? '#fff' : Color.grey700,
                      transform: expanded ? 'rotate(180deg)' : '',
                      ':focus': {
                        backgroundColor: Color.grey500,
                        borderRadius: '50%'
                      }
                    }}
                  />
                </IconButton>
              </>
            )}
          </ListItemButton>
        </AccordionSummary>
        {sidePanelExpanded && (
          <AccordionDetails sx={{ padding: 0 }}>
            <List sx={{ padding: 0 }}>
              {sublist?.map(
                ({
                  label: sublabel,
                  url: subUrl,
                  checkAssessmentHistory: needCheckAssessmentHistory
                }: any) => {
                  const isSelected = window.location.pathname.includes(subUrl)

                  if (needCheckAssessmentHistory && !hasCompletedAnAssessment) {
                    return null
                  }
                  return (
                    <div key={label}>
                      <ListItem
                        key={label}
                        disablePadding
                        sx={{ display: 'block' }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 32,
                            height: 32,
                            borderRadius: '4px',
                            '&:hover': {
                              backgroundColor: Color.grape400,
                              '& .MuiListItemIcon-root': {
                                // use the class name of the ListItemIcon
                                color: Color.white
                              },
                              '& .MuiTypography-root': { color: Color.white }
                            },
                            justifyContent: sidePanelExpanded
                              ? 'initial'
                              : 'center',
                            px: 2.5,
                            color: isSelected ? '#fff' : Color.grey700,
                            backgroundColor: isSelected
                              ? Color.grape400
                              : '#fff',
                            my: 1
                          }}
                          onClick={() => {
                            navigate(subUrl)
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: sidePanelExpanded ? 1.5 : 'auto',
                              justifyContent: 'center',
                              width: '24px',
                              color: isSelected ? '#fff' : Color.grey500
                            }}
                          >
                            {isSelected ? (
                              <FiberManualRecordIcon
                                sx={{
                                  color: Color.white,
                                  '&.MuiSvgIcon-root': { fontSize: '5px' }
                                }}
                              />
                            ) : (
                              <div style={{ height: '24px', width: '24px' }} />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant='body14Light'>
                                {sublabel}
                              </Typography>
                            }
                            sx={{ opacity: sidePanelExpanded ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </div>
                  )
                }
              )}
            </List>
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  )
}

export default ExpandableList
