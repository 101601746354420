import { useEpActivation } from 'hooks/useEpActivation'
import React from 'react'

export type ActivationContextType = {
  deactivateEp: (epCode: string) => any
  deactivateEpSuccess: boolean
  deactivateEpError: boolean
  deactivateEpUnauthorized: boolean
  setDeactivateEpSuccess: React.Dispatch<React.SetStateAction<boolean>>
  setDeactivateEpError: React.Dispatch<React.SetStateAction<boolean>>
  setDeactivateEpUnauthorized: React.Dispatch<React.SetStateAction<boolean>>
  activateEp: (epCode: string) => any
  activateEpSuccess: boolean
  activateEpError: boolean
  activateEpUnauthorized: boolean
  setActivateEpSuccess: React.Dispatch<React.SetStateAction<boolean>>
  setActivateEpError: React.Dispatch<React.SetStateAction<boolean>>
  setActivateEpUnauthorized: React.Dispatch<React.SetStateAction<boolean>>
}

export const INITIAL_DATA: ActivationContextType = {
  deactivateEp: () => {},
  deactivateEpSuccess: false,
  deactivateEpError: false,
  deactivateEpUnauthorized: false,
  setDeactivateEpSuccess: () => false,
  setDeactivateEpError: () => false,
  setDeactivateEpUnauthorized: () => false,
  activateEp: () => {},
  activateEpSuccess: false,
  activateEpError: false,
  activateEpUnauthorized: false,
  setActivateEpSuccess: () => false,
  setActivateEpError: () => false,
  setActivateEpUnauthorized: () => false
}

export const EmployerActivationContext =
  React.createContext<ActivationContextType>(INITIAL_DATA)

export const EmployerActivationProvider = ({ children }: any): JSX.Element => {
  const {
    activateEp,
    activateEpSuccess,
    activateEpError,
    activateEpUnauthorized,
    setActivateEpSuccess,
    setActivateEpError,
    setActivateEpUnauthorized,
    deactivateEp,
    deactivateEpSuccess,
    deactivateEpError,
    deactivateEpUnauthorized,
    setDeactivateEpSuccess,
    setDeactivateEpError,
    setDeactivateEpUnauthorized
  } = useEpActivation()

  const value = React.useMemo(
    () => ({
      activateEp,
      activateEpSuccess,
      activateEpError,
      activateEpUnauthorized,
      setActivateEpSuccess,
      setActivateEpError,
      setActivateEpUnauthorized,
      deactivateEp,
      deactivateEpSuccess,
      deactivateEpError,
      deactivateEpUnauthorized,
      setDeactivateEpSuccess,
      setDeactivateEpError,
      setDeactivateEpUnauthorized
    }),
    [
      activateEp,
      activateEpSuccess,
      activateEpError,
      activateEpUnauthorized,
      setActivateEpSuccess,
      setActivateEpError,
      setActivateEpUnauthorized,
      deactivateEp,
      deactivateEpSuccess,
      deactivateEpError,
      deactivateEpUnauthorized,
      setDeactivateEpSuccess,
      setDeactivateEpError,
      setDeactivateEpUnauthorized
    ]
  )

  return (
    <EmployerActivationContext.Provider value={value}>
      {children}
    </EmployerActivationContext.Provider>
  )
}
