import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'

export const GET_EMPLOYEE_SURVEY_SUMMARY = gql`
  query GetSurveySummary {
    employerSurveySummary {
      startAt
      endAt
      categorySummary
      departmentSummary
      representationSummary
      surveyCountSummary
    }
  }
`

const useSurvey = () => {
  const [hasCompletedAnEmployeeSurvey, setHasCompletedAnEmployeeSurvey] =
    useState<boolean>(false)

  const [employeeSurvey, setEmployeeSurvey] = useState<any>(null)

  const { loading } = useQuery(GET_EMPLOYEE_SURVEY_SUMMARY, {
    onCompleted: (result: any) => {
      if (result.employerSurveySummary) {
        setHasCompletedAnEmployeeSurvey(true)
        setEmployeeSurvey(result.employerSurveySummary)
      }
    },
    variables: {}
  })

  return { employeeSurvey, hasCompletedAnEmployeeSurvey, isLoading: loading }
}
export default useSurvey
