import React, { useContext } from 'react'
import { Typography, Grid } from '@mui/material'

import { Color, ErrorIcon } from 'components'
import { AccountContext } from 'context/AccountContext'
import { AuthenticationContext } from 'context/AuthenticationContext'
import { Container, Button, EmployerName } from './style'

const ImpersonatorBanner = () => {
  const { data } = useContext(AccountContext)
  const { handleLogout } = useContext(AuthenticationContext)

  return (
    <Container container justifyContent='center' padding='12px'>
      <ErrorIcon filled />
      <Grid item xs={5} marginLeft='18px'>
        <Typography variant='h7' color={Color.black87}>
          Super admin view
        </Typography>
        <Typography color={Color.black87} variant='body2'>
          You are viewing this page as if you are a staff member of{' '}
          <EmployerName> {data?.currentAccount.employer.name}, </EmployerName>
          <b>
            but be aware that you will not be able to save any changes to the EI
            assessment.
          </b>
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Button onClick={() => handleLogout()} variant='outlined'>
          Exit session
        </Button>
      </Grid>
    </Container>
  )
}

export default ImpersonatorBanner
