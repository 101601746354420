import React, { Button, ButtonProps, styled, Stack } from '@mui/material'
import { Download as DownloadIcon } from '@mui/icons-material'

const StyledButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#3148D3',
  color: 'white',
  padding: '13px 17px 13px 20px',
  border: '1px solid #2a3ca5',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: '#0c196a',
    border: '1px solid #0c196a',
    color: 'white'
  }
}))

interface AtsDownloadButtonProps {
  onClick: () => void
}

const AtsDownloadButton = ({ onClick }: AtsDownloadButtonProps) => {
  return (
    <StyledButton onClick={onClick}>
      <Stack direction='row' alignContent='center'>
        <DownloadIcon
          sx={{ fontSize: '20px', marginRight: '5px', marginTop: '1px' }}
        />
        Download
      </Stack>
    </StyledButton>
  )
}
export default AtsDownloadButton
