export const Content = [
  {
    link: '/legal/privacy-policy',
    document: {
      title: 'Privacy Policy',
      updatedAt: 'Oct 10, 2021',
      name: 'privacy-policy.md'
    }
  },
  {
    link: '/legal/privacy-notice-for-california-residents',
    document: {
      title: 'Privacy Notice for California Residents',
      updatedAt: 'Oct 29, 2021',
      name: 'privacy-notice-for-california-residents.md'
    }
  },
  {
    link: '/legal/terms-of-service',
    document: {
      title: 'Terms of Service',
      updatedAt: 'Oct 11, 2022',
      name: 'terms-of-service.md'
    }
  },
  {
    link: '/commitment-to-inclusion/accessibility',
    document: {
      title: 'Mathison Accessiblity Statement',
      updatedAt: 'Nov 8, 2021',
      name: 'accessiblity-statement.md'
    }
  }
]
