import React, { createContext, useMemo } from 'react'
import { useLaunchDarkly } from 'hooks/useLaunchDarkly'
import { EmployerStaffAccount } from 'model/organization'
import { LDFlagSet } from 'launchdarkly-js-client-sdk'

export type FeatureFlagsContextData = {
  identify: (account: EmployerStaffAccount, authSettings: string) => void
  flags?: LDFlagSet
  isFlagEnabled: (flagName: string) => boolean | undefined
}

export const FeatureFlagsContext = createContext<FeatureFlagsContextData>({
  identify: () => undefined,
  flags: undefined,
  isFlagEnabled: () => undefined
})

export const FeatureFlagsProvider = ({ children }: any): JSX.Element => {
  const { identify, flags, isFlagEnabled } = useLaunchDarkly()

  const value = useMemo<FeatureFlagsContextData>(
    () => ({ identify, flags, isFlagEnabled }),
    [flags, identify, isFlagEnabled]
  )

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}
