import React, { useState, useEffect } from 'react'
import { ListItem, List, Typography, Tab } from '@mui/material'
import { Navigation } from 'model'
import { Color } from 'components'
import { Navigations } from './constant'
import { Drawer, DrawerContainer, HashLink, Tabs, classes } from './style'

const parsePath = () => {
  const navs = window.location.pathname.split('/')
  const currentNav = Navigations.find(
    (nav: Navigation) => nav.link === `/${navs[2]}/${navs[3]}`
  )
  return { currentNav }
}
const SidebarNav = () => {
  const { currentNav } = parsePath()
  const [selectedNav, setSelectedNav] = useState(
    currentNav ? currentNav.label : Navigations[1].label
  )

  useEffect(() => {
    if (currentNav) {
      setSelectedNav(currentNav.label)
    }
  }, [currentNav])

  return (
    <Drawer variant='permanent' PaperProps={{ elevation: 1 }}>
      <Typography variant='h5' color={Color.main}>
        Contents
      </Typography>
      <DrawerContainer>
        <List style={{ paddingTop: 8, paddingBottom: 8 }}>
          <ListItem disablePadding sx={{ padding: 0 }}>
            <Tabs
              orientation='vertical'
              value={selectedNav || false}
              indicatorColor='primary'
              onChange={(_e, value) => {
                setSelectedNav(value)
              }}
              classes={{
                indicator: 'indicator',
                flexContainerVertical: 'flexContainerVertical'
              }}
            >
              {Navigations.map((navigation: Navigation, index: number) => (
                <Tab
                  value={navigation.label}
                  id='vertical-tab'
                  className={
                    navigation.isTitle ? classes.tabsBarTitle : classes.tabsBar
                  }
                  classes={{
                    root:
                      navigation.isTitle && index > 0 ? 'root subTitle' : 'root'
                  }}
                  disabled={navigation.isTitle}
                  label={
                    navigation.isTitle ? (
                      <Typography
                        variant='body2Medium'
                        sx={{
                          fontSize: 13,
                          lineHeight: '16px',
                          letterSpacing: '0.094em'
                        }}
                      >
                        {navigation.label}
                      </Typography>
                    ) : (
                      <HashLink to={`/statement${navigation.link}`}>
                        <Typography
                          variant='body16Light'
                          textAlign='left'
                          sx={{
                            paddingLeft: '18px',
                            fontWeight: 400,
                            fontSize: 18,
                            textTransform: 'none',
                            lineHeight: '24px',
                            color:
                              selectedNav === navigation.label
                                ? Color.blue400
                                : Color.main
                          }}
                        >
                          {navigation.label}
                        </Typography>
                      </HashLink>
                    )
                  }
                />
              ))}
              )
            </Tabs>
          </ListItem>
        </List>
      </DrawerContainer>
    </Drawer>
  )
}
export default SidebarNav
