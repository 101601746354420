/* eslint-disable no-console */
import React, { useMemo, useRef, useState } from 'react'
import {
  CreateEmployerResponseType,
  Organization,
  UpdateEmployerNameResponseType
} from 'model/organization'
import {
  Typography,
  Stack,
  Box,
  Button,
  ButtonProps,
  styled,
  AlertColor
} from '@mui/material'
import { Circle as StatusIcon, Add as AddIcon } from '@mui/icons-material'
import {
  DataGridPro,
  GridValueGetterParams,
  GridRenderCellParams,
  GridEventListener,
  GridRowParams
} from '@mui/x-data-grid-pro'
import {
  getFilteredOrgsByStatus,
  getFormattedDate,
  truncateString
} from 'utils/adminData'
import SidePanel from 'components/SidePanel'
import LoadingIndicator from 'components/LoadingIndicator'
import FilterButton from 'components/FilterButton'
import { Color } from '@mathison-inc/components'
import SearchBar from 'components/SearchBar'
import AlertErrorMessage from 'components/AlertErrorMessage'
import { ApolloError } from '@apollo/client'
import { EmployerActivationProvider } from 'context/EmployerActivationContext'
import EmployerSidePanel from './EmployerSidePanel'
import { tableStyles } from './styles'
import CreateEpSidePanel from './CreateEpSidePanel'

const CreateButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#3148D3',
  color: '#FFFFFF',
  padding: '11px 20px 11px 13px',
  '&:hover': {
    backgroundColor: '#050B0F'
  }
}))

interface IProps {
  organizations: Organization[]
  loadSelectedEmployer: (employerId?: string) => void
  isLoading: boolean
  error: any
}

const EmployerTable = ({
  organizations,
  loadSelectedEmployer,
  isLoading,
  error
}: IProps) => {
  const [search, setSearch] = useState('')
  const [filterValue, setFilterValue] = useState('Active')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const [severity, setSeverity] = useState<AlertColor>('error')
  const [filteredOrgs, setFilteredOrgs] =
    useState<Organization[]>(organizations)

  const editEpPanelRef = useRef<any>(null)
  const createEpPanelRef = useRef<any>(null)

  useMemo(() => {
    setFilteredOrgs(getFilteredOrgsByStatus(organizations, filterValue))
  }, [filterValue, organizations])

  const handleRowClick: GridEventListener<'rowClick'> = (
    params: GridRowParams
  ) => {
    loadSelectedEmployer(params?.row?.id)
    editEpPanelRef.current?.openSidePanel()
  }

  const createEP = () => {
    createEpPanelRef.current?.openSidePanel()
  }

  const beforePanelOnClose: any = () => {
    loadSelectedEmployer()
  }

  const panelOverrideProps = {
    beforePanelOnClose
  }

  const closeEpCreatePanel = (onExitedCallback?: any) => {
    createEpPanelRef.current?.closeSidePanel(onExitedCallback)
  }

  const closeEpEditSidePanel = (onExitedCallback?: any) => {
    editEpPanelRef.current?.closeSidePanel(onExitedCallback)
  }

  const handleOnChange = (e: any) => {
    setSearch(e.target.value)
  }

  const handleError = (requestError: ApolloError | undefined) => {
    const message =
      requestError && requestError?.graphQLErrors[0]
        ? requestError?.graphQLErrors[0]?.message
        : 'There was an error. Please try again.'
    setSeverity('error')
    setErrorMessage(message)
    setOpenAlert(true)
    setTimeout(() => {
      setOpenAlert(false)
    }, 5000)
  }

  const handleSuccess = (
    response:
      | CreateEmployerResponseType
      | UpdateEmployerNameResponseType
      | undefined
      | null,
    isUpdateName?: boolean
  ) => {
    const message = isUpdateName
      ? 'The organization name has been updated.'
      : `The organization ${response?.name} has successfully been created.`
    setSeverity('success')
    setErrorMessage(message)
    setOpenAlert(true)
    setTimeout(() => {
      setOpenAlert(false)
    }, 5000)
  }

  const clearSearch = () => {
    setSearch('')
  }

  const searchFilteredOrgs = (orgsArray: Organization[]) => {
    return orgsArray.filter((org: Organization) =>
      search.toLowerCase() === ''
        ? org
        : org.name.toLowerCase().includes(search.toLowerCase()) ||
          org.code.toLowerCase().includes(search.toLowerCase())
    )
  }

  return (
    <Box sx={{ padding: '40px 0 100px 0' }}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '20px 5px',
          marginBottom: '40px'
        }}
      >
        <Typography variant='h1' sx={{ marginBottom: '20px' }}>
          Employer Partners
        </Typography>
        <CreateButton onClick={createEP}>
          <Stack alignItems='center' direction='row' gap={1}>
            <AddIcon />
            Add New EP
          </Stack>
        </CreateButton>
      </Box>
      <Box>
        <AlertErrorMessage
          severity={severity}
          message={errorMessage}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
        />
      </Box>
      <Stack
        padding={4}
        borderRadius={2}
        border={`1px solid ${Color.grey300}`}
        sx={{ backgroundColor: 'white' }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '20px 5px'
          }}
        >
          <SearchBar
            value={search}
            placeHolderText='Search organizations by name or code'
            onChange={handleOnChange}
            clearSearch={clearSearch}
          />
          <FilterButton
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            dataLabel='Status'
            options={['All', 'Active', 'Inactive']}
          />
        </Box>

        <Box>
          {isLoading && <LoadingIndicator />}
          {error && !isLoading ? (
            <Box>
              <Typography variant='h3'>
                There was an error loading the employers.
              </Typography>
            </Box>
          ) : (
            <Box>
              {!isLoading && (
                <>
                  <DataGridPro
                    sx={tableStyles}
                    disableColumnMenu
                    pagination
                    getRowId={(row: any) => row?.code}
                    pageSizeOptions={[5, 10, 15, 50]}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 50, page: 0 }
                      }
                    }}
                    rows={searchFilteredOrgs(filteredOrgs)}
                    onRowClick={handleRowClick}
                    columns={[
                      {
                        field: 'code',
                        headerName: 'Code',
                        flex: 1,
                        minWidth: 75,
                        maxWidth: 150,
                        headerClassName: 'headers',
                        valueGetter: (params: GridValueGetterParams) => {
                          return params?.row?.code
                        },
                        renderCell: (params: GridRenderCellParams) => {
                          return (
                            <Typography
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                },
                                height: 35,
                                width: 35,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 'normal',
                                fontSize: '14px'
                              }}
                            >
                              {params?.row?.code}
                            </Typography>
                          )
                        }
                      },
                      {
                        field: 'name',
                        headerName: 'Name',
                        minWidth: 300,
                        flex: 1,
                        headerClassName: 'headers',
                        valueGetter: (params: GridValueGetterParams) => {
                          return params?.row?.name
                        },
                        renderCell: (params: GridRenderCellParams) => {
                          return (
                            <Typography
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                },
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 'normal',
                                fontSize: '14px'
                              }}
                            >
                              {truncateString(params?.row?.name, 35)}
                            </Typography>
                          )
                        }
                      },
                      {
                        field: 'status',
                        headerName: 'Status',
                        flex: 1,
                        headerClassName: 'headers',
                        valueGetter: (params: GridValueGetterParams) => {
                          return params?.row?.status
                        },
                        renderCell: (params: GridRenderCellParams) => {
                          return (
                            <Stack alignItems='center' direction='row' gap={1}>
                              <StatusIcon
                                sx={{
                                  fontSize: '9px',
                                  color:
                                    params?.row?.status === 'active'
                                      ? '#5DEFA9'
                                      : '#F12757'
                                }}
                              />
                              <Typography
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0
                                  },
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontWeight: 700,
                                  textTransform: 'uppercase',
                                  fontSize: '10px'
                                }}
                              >
                                {params?.row?.status}
                              </Typography>
                            </Stack>
                          )
                        }
                      },
                      {
                        field: 'created',
                        headerName: 'Created',
                        flex: 1,
                        headerClassName: 'headers',
                        valueGetter: (params: GridValueGetterParams) => {
                          return params?.row?.insertedAt
                        },
                        renderCell: (params: GridRenderCellParams) => {
                          return (
                            <Typography
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                },
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 'normal',
                                textTransform: 'uppercase',
                                fontSize: '14px'
                              }}
                            >
                              {getFormattedDate(params?.row?.insertedAt)}
                            </Typography>
                          )
                        }
                      },
                      {
                        field: 'updated',
                        headerName: 'Updated',
                        flex: 1,
                        headerClassName: 'headers',
                        valueGetter: (params: GridValueGetterParams) => {
                          return params?.row?.updatedAt
                        },
                        renderCell: (params: GridRenderCellParams) => {
                          return (
                            <Typography
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                },
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 'normal',
                                textTransform: 'uppercase',
                                fontSize: '14px'
                              }}
                            >
                              {getFormattedDate(params?.row?.updatedAt)}
                            </Typography>
                          )
                        }
                      }
                    ]}
                  />
                  <EmployerActivationProvider>
                    <SidePanel ref={editEpPanelRef} {...panelOverrideProps}>
                      <EmployerSidePanel
                        closeSidePanel={closeEpEditSidePanel}
                      />
                    </SidePanel>
                  </EmployerActivationProvider>
                  <SidePanel ref={createEpPanelRef}>
                    <CreateEpSidePanel
                      closeSidePanel={closeEpCreatePanel}
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  </SidePanel>
                </>
              )}
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  )
}
export default EmployerTable
