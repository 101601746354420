import { styled } from '@mui/material/styles'
import { Color } from '@mathison-inc/components'

export const WithBackground = styled('div')({
  width: '100vw',
  minWidth: '100%',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover'
})
export const WithoutBackground = styled('div')({
  backgroundColor: Color.white
})
export const DefaultBackground = {
  backgroundColor: '#F7F7F7',
  backgroundSize: '100% 3739px',
  backgroundPositionY: 0
}
export const WithBackgroundProvider = styled('div')({
  '& .withBackground': {
    width: '100vw',
    minWidth: '100%',
    height: '100%'
  },
  '& .withoutBackground': {
    backgroundColor: Color.white
  }
})
