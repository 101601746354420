import React from 'react'
import { Box, BoxProps, styled, createTheme } from '@mui/material'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1350,
      xl: 1536
    }
  }
})

const StyledBox = styled(Box)<BoxProps>(() => ({
  height: '85vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    height: '130vh'
  }
}))

const PulsarSpacer = ({ children }: any): JSX.Element => {
  return <StyledBox>{children}</StyledBox>
}

export default PulsarSpacer
