/* eslint-disable no-console */
import React from 'react'
import {
  Box,
  Stack,
  Typography,
  Button,
  ButtonProps,
  styled
} from '@mui/material'
import {
  AssignmentInd as EditUserIcon,
  ManageAccounts as AccountIcon,
  Edit as EditIcon,
  EditOff as CancelIcon
} from '@mui/icons-material'
import { UserProfile } from 'model/organization'
import RolesButton from 'components/RolesButton'
import StatusPill from 'components/StatusPill'
import DeactivateButton from 'components/DeactivateButton'
import { getPrettyRoleName } from 'utils/adminData'
import DeactivateConfirm from 'components/DeactivateConfirm'
import ActivateButton from 'components/ActivateButton'

interface EditButtonProps extends ButtonProps {
  isEditing: boolean
}

const EditButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'isEditing'
})<EditButtonProps>(({ isEditing }) => ({
  backgroundColor: isEditing ? '#050B0E' : '#FFFFFF',
  border: isEditing ? '1px solid #050B0E' : '1px solid #3148D3',
  color: isEditing ? '#FFFFFF' : '#3148D3',
  padding: '2px 10px',
  '&:hover': {
    backgroundColor: isEditing ? '#F1F1F1' : '#3148D3',
    color: isEditing ? '#050B0E' : '#FFFFFF',
    border: isEditing ? '1px solid #CCCCCC' : '1px solid #3148D3'
  }
}))

const SubmitButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#050B0E',
  border: '1px solid #050B0E',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F1F1F1',
    color: '#050B0E',
    border: '1px solid #CCCCCC'
  }
}))

interface EditUserSidePanelProps {
  user: UserProfile | undefined | null
  epId: string
  isLoading: boolean
  error: any
  submitRoleChanges: (epId: string, userId: string, roles: string[]) => void
  deactivateUser: (userId: string, epId: string) => void
  activateUser: (email: string, epId: string) => void
  closeSidePanel: (onExitedCallback?: any) => void
}

const EditUserSidePanel = ({
  user,
  epId,
  isLoading,
  error,
  submitRoleChanges,
  deactivateUser,
  activateUser,
  closeSidePanel
}: EditUserSidePanelProps) => {
  const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const [isDeactivating, setIsDeactivating] = React.useState<boolean>(false)
  const [adminRole, setAdminRole] = React.useState<string>('BASIC_USER')
  const [accessRole, setAccessRole] = React.useState<string>('NO_EHI_ACCESS')
  const adminRoleOptions = ['BASIC_USER', 'MANAGER', 'SUPER_MANAGER']
  const accessRoleOptions = [
    'NO_EHI_ACCESS',
    'BASIC_EHI_ACCESS',
    'FULL_EHI_ACCESS'
  ]

  React.useMemo(() => {
    if (user && user?.roles && user.roles[0]) {
      setAdminRole(user.roles[0])
    }
  }, [user])

  const handleEditCancelUser = () => {
    setAdminRole(
      user && user.roles && user.roles.length > 0 ? user.roles[0] : 'BASIC_USER'
    )
    setAccessRole(
      user && user.roles && user.roles.length > 0
        ? user.roles[2]
        : 'NO_EHI_ACCESS'
    )
    setIsEditing(!isEditing)
  }

  return (
    <Box>
      {isLoading && !error && !user ? (
        <Box>
          <Typography>Loading...</Typography>
        </Box>
      ) : (
        <Box>
          {error ? (
            <Box>
              <Typography>There was an error</Typography>
            </Box>
          ) : (
            <Box>
              {user && user.roles && user.roles.length > 0 ? (
                <Box>
                  {isDeactivating ? (
                    <DeactivateConfirm
                      identifier={user.fullName || user.email}
                      isEp={false}
                      epId={epId}
                      userId={user.id}
                      deactivateUser={deactivateUser}
                      setIsDeactivating={setIsDeactivating}
                      closeSidePanel={closeSidePanel}
                    />
                  ) : (
                    <Box>
                      <Box
                        sx={{
                          borderBottom: '1px solid #050B0E',
                          marginBottom: '20px'
                        }}
                      >
                        <Stack direction='row' justifyContent='space-between'>
                          <Box
                            sx={{
                              width: '300px',
                              display: 'flex',
                              flexDirection: 'row'
                            }}
                          >
                            <EditUserIcon
                              sx={{
                                fontSize: '25px',
                                color: '#3148D3',
                                marginTop: '3px',
                                marginRight: '5px'
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: '20px',
                                fontWeight: 700
                              }}
                            >
                              {user.fullName || user.email}
                            </Typography>
                          </Box>
                          <StatusPill status={user.status} />
                        </Stack>
                        <Box sx={{ padding: '10px 10px 10px 5px' }}>
                          <Stack direction='row' alignItems='left'>
                            <Typography
                              sx={{ paddingRight: '10px', fontWeight: 'bold' }}
                            >
                              ID:
                            </Typography>
                            <Typography>{user.userId}</Typography>
                          </Stack>
                        </Box>
                      </Box>

                      <Box>
                        <Stack direction='row' alignItems='left'>
                          <Typography
                            sx={{ paddingRight: '10px', fontWeight: 'bold' }}
                          >
                            Name:
                          </Typography>
                          <Typography>{user.fullName}</Typography>
                        </Stack>
                      </Box>

                      <Box>
                        <Stack direction='row' alignItems='left'>
                          <Typography
                            sx={{ paddingRight: '10px', fontWeight: 'bold' }}
                          >
                            Email:
                          </Typography>
                          <Typography>{user.email}</Typography>
                        </Stack>
                      </Box>

                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        sx={{
                          paddingTop: '20px',
                          borderTop: '1px solid rgba(219, 219, 219, 1)',
                          marginTop: '20px'
                        }}
                      >
                        <Stack direction='row' alignItems='left'>
                          <AccountIcon
                            sx={{
                              fontSize: '25px',
                              color: '#3148D3',
                              marginTop: '3px',
                              marginRight: '5px'
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: '20px',
                              fontWeight: 700
                            }}
                          >
                            User Access
                          </Typography>
                        </Stack>
                        <Box>
                          <EditButton
                            onClick={handleEditCancelUser}
                            isEditing={isEditing}
                          >
                            <Stack alignItems='center' direction='row' gap={1}>
                              {isEditing ? (
                                <>
                                  <CancelIcon sx={{ fontSize: '15px' }} />
                                  <Typography
                                    sx={{
                                      fontSize: '15px',
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Cancel
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <EditIcon sx={{ fontSize: '15px' }} />
                                  <Typography
                                    sx={{
                                      fontSize: '15px',
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Edit
                                  </Typography>
                                </>
                              )}
                            </Stack>
                          </EditButton>
                        </Box>
                      </Stack>

                      <Box sx={{ marginTop: '20px' }}>
                        {isEditing ? (
                          <Box>
                            <Box sx={{ margin: '40px 0' }}>
                              <RolesButton
                                role={adminRole}
                                options={adminRoleOptions}
                                dataLabel='Admin Role'
                                setRoleValue={setAdminRole}
                              />
                            </Box>

                            <Box sx={{ margin: '40px 0' }}>
                              <RolesButton
                                role={accessRole}
                                options={accessRoleOptions}
                                dataLabel='Access Role'
                                setRoleValue={setAccessRole}
                              />
                            </Box>

                            <Box
                              sx={{
                                margin: '40px 0',
                                padding: '20px 0'
                              }}
                            >
                              <SubmitButton
                                onClick={() => {
                                  closeSidePanel()
                                  submitRoleChanges(epId, user.userId, [
                                    adminRole,
                                    user.roles[1],
                                    accessRole
                                  ])
                                }}
                              >
                                Save
                              </SubmitButton>
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            <Box>
                              <Stack direction='row' alignItems='left'>
                                <Typography
                                  sx={{
                                    paddingRight: '10px',
                                    fontWeight: 'bold'
                                  }}
                                >
                                  Admin Role:
                                </Typography>
                                <Typography>
                                  {getPrettyRoleName(user.roles[0])}
                                </Typography>
                              </Stack>
                            </Box>
                            <Box>
                              <Stack direction='row' alignItems='left'>
                                <Typography
                                  sx={{
                                    paddingRight: '10px',
                                    fontWeight: 'bold'
                                  }}
                                >
                                  EI Access:
                                </Typography>
                                <Typography>
                                  {getPrettyRoleName(user.roles[2])}
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>
                        )}
                        <Box
                          sx={{
                            margin: '100px 0 40px',
                            padding: '20px 0',
                            borderTop: '1px solid rgba(219, 219, 219, 1)',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right'
                          }}
                        >
                          {user.status.toLowerCase() === 'inactive' ? (
                            <ActivateButton
                              onClick={() => {
                                setIsDeactivating(false)
                                closeSidePanel()
                                activateUser(user.email, epId)
                              }}
                            />
                          ) : (
                            <DeactivateButton
                              onClick={() => {
                                setIsDeactivating(true)
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box>
                  <Typography>
                    There was an issue retrieving the user.
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
export default EditUserSidePanel
