import { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { GET_EMPLOYER } from 'context/AdminContext/gql'
import { INVITE_USERS } from 'context/InviteUserContext/gql'

export const useInviteUsers = () => {
  const [inviteSuccess, setInviteSuccess] = useState<boolean>(false)
  const [inviteError, setInviteError] = useState<boolean>(false)
  const [inviteUnauthorized, setInviteUnauthorized] = useState<boolean>(false)

  const { refetch: refetchEmployer } = useQuery(GET_EMPLOYER)
  const [handleInviteUsers] = useMutation(INVITE_USERS, {})

  const inviteUsers = async (emails: string[], epId: string) => {
    setInviteSuccess(false)
    setInviteError(false)
    setInviteUnauthorized(false)
    try {
      const result = await handleInviteUsers({
        variables: { emails, organizationId: epId }
      })
      if (result && result.data) {
        setInviteSuccess(true)
        refetchEmployer({ id: epId })
      } else if (result) {
        setInviteUnauthorized(true)
      } else {
        setInviteError(true)
      }
    } catch (e) {
      setInviteError(true)
    }
  }

  return {
    inviteUsers,
    inviteSuccess,
    inviteError,
    inviteUnauthorized,
    setInviteSuccess,
    setInviteError,
    setInviteUnauthorized
  }
}
