import React from 'react'
import { Typography, Button } from '@mui/material'
import { Color, WithGradientBackground } from 'components'
import { Container } from './style'

const NotAuthorized = () => {
  return (
    <Container>
      <WithGradientBackground />
      <div className='container'>
        <Typography variant='largeTitle' color={Color.main}>
          Unauthorized
        </Typography>
        <Typography
          variant='body16Light'
          color='#64737D'
          className='introduction'
        >
          You do not have permission to view this page. Please press the button
          below to go back to the homepage.
        </Typography>
        <Button
          variant='contained'
          href='/'
          className='backBtn'
          color='secondary'
        >
          Back to Homepage
        </Button>
      </div>
    </Container>
  )
}
export default NotAuthorized
