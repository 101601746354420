import React from 'react'
import { PieChart } from '@mui/x-charts/PieChart'
import { Box, Typography, createTheme, Grid } from '@mui/material'
import { PieChartDataPoint } from 'model/atsMock'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1350,
      xl: 1536
    }
  }
})

interface PieChartProps {
  title: string
  data: PieChartDataPoint[]
}

const AtsPieChart = ({ title, data }: PieChartProps) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 600,
          textAlign: 'left',
          [theme.breakpoints.down('lg')]: {
            fontSize: '16px'
          }
        }}
      >
        {title}
      </Typography>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '40px',
          [theme.breakpoints.down('lg')]: {
            marginTop: '20px'
          }
        }}
      >
        <PieChart
          colors={[
            '#5F71E5',
            '#DD66AD',
            '#6DCCCD',
            '#FFC165',
            '#373C3F',
            '#9AE5F3',
            '#8E63DE',
            '#FF9292'
          ]}
          series={[
            {
              data
            }
          ]}
          width={400}
          height={200}
          sx={{
            tspan: {
              letterSpacing: '0.05em'
            }
          }}
        />
      </Grid>
    </Box>
  )
}
export default AtsPieChart
