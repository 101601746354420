import { useEffect } from 'react'
import { loadMicroApp } from 'qiankun'
import { orbitEntry } from 'utils/entryProvider'
import Client from 'utils/createApolloMaestroClient'

const Orbit = () => {
  useEffect(() => {
    const app = loadMicroApp({
      name: 'orbit', // app name registered, same as the one in package.json
      entry: orbitEntry,
      container: '#orbit-root',
      props: { maestroClient: Client() }
    })

    return () => {
      if (app.getStatus() === 'MOUNTED') {
        app.unmount()
      }
    }
    // eslint-disable-next-line
  }, [])

  return null
}

export default Orbit
