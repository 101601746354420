import React, { createContext, useMemo, useState } from 'react'

type PathContextData = {
  path: string
  setPath: (path: string) => void
  showLogInBanner: boolean
  setShowLogInBanner: (toggle: boolean) => void
}
export const PathContext = createContext<PathContextData>({
  path: '',
  setPath: () => null,
  showLogInBanner: false,
  setShowLogInBanner: () => null
})
const PathContextProvider = ({ children }: any) => {
  const [path, setPath] = useState('/')
  const [showLogInBanner, setShowLogInBanner] = useState<boolean>(false)
  const values = useMemo(
    () => ({
      path,
      setPath,
      showLogInBanner,
      setShowLogInBanner
    }),
    [path, showLogInBanner]
  )

  return <PathContext.Provider value={values}>{children}</PathContext.Provider>
}
export default PathContextProvider
