import React, { createContext, useContext, useMemo } from 'react'
import { Access } from 'model/access'
import { EmployerStaffAccountEhiAccessLevel } from 'model/organization'
import { AccountContext } from 'context/AccountContext'
import useAssessment from 'hooks/useAssessment'
import useSurvey from 'hooks/useSurvey'

export const AccessContext = createContext<Access>({
  hasCompletedAnAssessment: false,
  hasCompletedAnEmployeeSurvey: false,
  hasAccessToViewDashboardAndAssessment: false,
  hasAccessToViewDataViz: false,
  hasAccessToViewAssessment: false,
  hasAccessToViewDashboard: false,
  hasAccessToViewRecommendations: false,
  hasAccessToSurveySummary: false
})

export const AccessProvider = ({ children }: any): JSX.Element => {
  const {
    data: {
      currentAccount: { ehiAccess }
    }
  } = useContext(AccountContext)

  const { hasCompletedAnAssessment } = useAssessment()
  const { hasCompletedAnEmployeeSurvey } = useSurvey()
  const value = useMemo(
    () => ({
      hasCompletedAnAssessment,
      hasCompletedAnEmployeeSurvey,
      hasAccessToSurveySummary:
        ehiAccess === EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS,
      hasAccessToViewDashboardAndAssessment:
        ehiAccess === EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS,
      hasAccessToViewDataViz:
        ehiAccess === EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS,
      hasAccessToViewAssessment:
        ehiAccess === EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS,
      hasAccessToViewDashboard:
        ehiAccess === EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS,
      hasAccessToViewRecommendations:
        ehiAccess !== EmployerStaffAccountEhiAccessLevel.NO_EHI_ACCESS
    }),
    [ehiAccess, hasCompletedAnAssessment, hasCompletedAnEmployeeSurvey]
  )
  return (
    <AccessContext.Provider value={value}>{children}</AccessContext.Provider>
  )
}
