/* eslint-disable no-console */
import React from 'react'
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Box
} from '@mui/material'

interface FilterButtonProps {
  dataLabel: string
  options: string[] | number[] | readonly string[]
  setFilterValue: React.Dispatch<React.SetStateAction<string>>
  filterValue: string
}

const FilterButton = ({
  dataLabel,
  options,
  setFilterValue,
  filterValue
}: FilterButtonProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    setFilterValue(
      (event.target.value as string) === 'All'
        ? ''
        : (event.target.value as string)
    )
  }

  return (
    <Box sx={{ margin: '0 10px', backgroundColor: '#F7F7F7' }}>
      <FormControl
        fullWidth
        sx={{
          minWidth: '150px',
          backgroundColor: '##F7F7F7',
          border: '1px solid #E5E5E5',
          boxShadow: 'none'
        }}
      >
        <InputLabel id='status-select-label'>{dataLabel}</InputLabel>
        <Select
          labelId='status-select-label'
          id='status-select'
          value={filterValue}
          label={dataLabel}
          onChange={handleChange}
        >
          {options.map(option => (
            <MenuItem value={option}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
export default FilterButton
