import { Color } from 'components'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Header = styled('header')({
  width: '100vw',
  position: 'fixed',
  left: 0,
  right: 0,
  padding: '16px',
  backgroundColor: Color.white,
  zIndex: 1201,
  boxShadow:
    '0px 2px 4px rgba(97, 116, 128, 0.16), 0px 4px 8px rgba(176, 186, 191, 0.12)'
})
export const MainContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  width: '100%',
  height: '100%'
})
export const DocumentContainer = styled('div')({
  documentContainer: {
    flexGrow: 1,
    padding: '56px 56px 0 56px',
    height: 'calc(100vh - 60px - 56px)',
    overflowY: 'scroll',
    '& p': {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.01em',
      textAlign: 'left',
      color: '#050B0E'
    },
    '& h1': {
      fontFamily: 'IBM Plex Sans',
      fontSize: '48px',
      fontStyle: 'normal',
      fontSeight: 700,
      lineHeight: '60px',
      letterSpacing: '-0.026em',
      textAlign: 'left',
      color: '#050B0E'
    },
    '& em': {
      color: '#395260',
      fontFamily: 'IBM Plex Sans',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0.03em',
      textAlign: 'left'
    }
  }
})
