// hooks/useLaunchDarkly.js
import { useState } from 'react'
import {
  LDClient,
  LDFlagSet,
  initialize as ldInitialize
} from 'launchdarkly-js-client-sdk'
import { EmployerStaffAccount } from 'model/organization'

export const useLaunchDarkly = () => {
  const [flags, setFlags] = useState<LDFlagSet>()

  const identify = async (
    accountOrUser: EmployerStaffAccount,
    authSettings: string
  ) => {
    let user: any = accountOrUser
    const key = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID as string

    const account = accountOrUser as EmployerStaffAccount

    const organizationId = `EP::${account?.employer.code}`
    const roles = account.roles.map(role => role?.toLowerCase())
    user = {
      key: account?.id,
      email: account?.email,
      name: account?.user?.name,
      custom: {
        organizationId,
        roles,
        authSettings
      }
    }
    const client = ldInitialize(key, user) as LDClient
    client.on('ready', () => {
      const allFlags = client.allFlags()
      setFlags(allFlags)
    })
  }

  const isFlagEnabled = (flagName: string) => flags && flags[flagName]

  return {
    identify,
    flags,
    isFlagEnabled
  }
}
