import { Components, Icons } from 'importHandler'

export const {
  theme,
  Color,
  ThemeInheritor,
  TextDivider,
  Paper,
  UserAvatar,
  DialogWithoutAction,
  NotificationContext,
  NotificationProvider,
  InfoBanner,
  TextField,
  fontsToLoad
} = Components

export const { ErrorIcon } = Icons
