import { gql } from '@apollo/client'

export const ENIGMA_CURRENT_ACCOUNT = gql`
  query ENIGMA_CURRENT_ACCOUNT {
    currentAccount {
      id
      userId
      email
      employer {
        id
        name
        code
      }
      employerId
      organizationId
      roles
      status
    }
    cmsApiKey
    employerAuthenticationSettings {
      strategy
    }
  }
`
