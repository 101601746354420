import React from 'react'
import { Typography, Button } from '@mui/material'
import { Color, WithGradientBackground } from 'components'
import { Container } from './style'

const NotFound = () => {
  return (
    <Container>
      <WithGradientBackground />
      <div className='container'>
        <Typography variant='largeTitle' color={Color.main}>
          Sorry, we can’t find that page
        </Typography>
        <Typography
          variant='body16Light'
          color='#64737D'
          className='introduction'
        >
          Check that the address you typed is correct and try again, or try
          navigating to the page from the Dashboard.
        </Typography>
        <Button
          variant='contained'
          href='/'
          className='backBtn'
          color='secondary'
        >
          Back to Dashboard
        </Button>
      </div>
    </Container>
  )
}
export default NotFound
