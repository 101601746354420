/* eslint-disable no-nested-ternary */
import React from 'react'
import {
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import { Stack, Typography } from '@mui/material'
import { Circle as StatusIcon } from '@mui/icons-material'
import { getFormattedDate } from 'utils/adminData'

export const jobColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Job Title',
    description: 'The title for this job in the ATS.',
    headerClassName: 'job-table-header',
    cellClassName: 'job-title',
    type: 'string',
    flex: 1
  },
  {
    field: 'code',
    headerName: 'Job Code',
    description: 'The unique job code from ATS.',
    type: 'string',
    valueGetter: (params: GridValueGetterParams) => {
      return params?.row?.code
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            '&:last-child td, &:last-child th': {
              border: 0
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '14px',
            width: '100%'
          }}
        >
          {params?.row?.code}
        </Typography>
      )
    },
    flex: 1
  },
  {
    field: 'createdAt',
    headerName: 'Date Created',
    description: 'The date this job was created in the ATS.',
    type: 'string',
    valueGetter: (params: GridValueGetterParams) => {
      return params?.row?.createdAt
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            '&:last-child td, &:last-child th': {
              border: 0
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '14px',
            width: '100%'
          }}
        >
          {getFormattedDate(params?.row?.createdAt)}
        </Typography>
      )
    },
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    description: 'The job status - open, pending, draft, archived or closed.',
    type: 'string',
    valueGetter: (params: GridValueGetterParams) => {
      return params?.row?.status
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Stack
          width='100%'
          display='flex'
          justifyContent='left'
          alignItems='center'
          direction='row'
          gap={1}
        >
          <StatusIcon
            sx={{
              fontSize: '9px',
              color:
                params?.row?.status === 'OPEN'
                  ? '#3eb59d'
                  : params?.row?.status === 'PENDING' ||
                    params?.row?.status === 'DRAFT'
                  ? '#fcff72'
                  : '#F12757'
            }}
          />
          <Typography
            sx={{
              '&:last-child td, &:last-child th': {
                border: 0
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 700,
              textTransform: 'uppercase',
              fontSize: '12px'
            }}
          >
            {params?.row?.status}
          </Typography>
        </Stack>
      )
    },
    flex: 1
  },
  {
    field: 'totalApplicants',
    headerName: 'Total Applicants',
    description:
      'The total number of applicants who have applied to this position',
    type: 'number',
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row?.totalApplicants) {
        return params.row?.totalApplicants
      }
      return 0
    },
    flex: 1
  }
]
