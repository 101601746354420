import { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  ACTIVATE_EP,
  DEACTIVATE_EP
} from 'context/EmployerActivationContext/gql'
import { GET_EMPLOYERS } from 'context/AdminContext/gql'

export const useEpActivation = () => {
  const [deactivateEpSuccess, setDeactivateEpSuccess] = useState<boolean>(false)
  const [deactivateEpError, setDeactivateEpError] = useState<boolean>(false)
  const [deactivateEpUnauthorized, setDeactivateEpUnauthorized] =
    useState<boolean>(false)
  const [activateEpSuccess, setActivateEpSuccess] = useState<boolean>(false)
  const [activateEpError, setActivateEpError] = useState<boolean>(false)
  const [activateEpUnauthorized, setActivateEpUnauthorized] =
    useState<boolean>(false)

  const { refetch: refetchEmployers } = useQuery(GET_EMPLOYERS)
  const [handleDeactivateEmployer] = useMutation(DEACTIVATE_EP, {})
  const [handleActivateEmployer] = useMutation(ACTIVATE_EP, {})

  const deactivateEp = async (epCode: string) => {
    setDeactivateEpError(false)
    setDeactivateEpSuccess(false)
    setDeactivateEpUnauthorized(false)
    try {
      const result = await handleDeactivateEmployer({
        variables: {
          input: {
            code: epCode
          }
        }
      })
      if (result && result.data) {
        setDeactivateEpSuccess(true)
      } else if (result) {
        setDeactivateEpUnauthorized(true)
      } else {
        setDeactivateEpError(true)
      }
      const deactivateEpProfileId = result?.data?.deactivateEmployer?.id
      if (deactivateEpProfileId) {
        refetchEmployers()
      }
    } catch (e) {
      setDeactivateEpError(true)
    }
  }

  const activateEp = async (epCode: string) => {
    setActivateEpError(false)
    setActivateEpSuccess(false)
    setActivateEpUnauthorized(false)
    try {
      const result = await handleActivateEmployer({
        variables: { input: { code: epCode } }
      })
      if (result && result.data) {
        setActivateEpSuccess(true)
      } else if (result) {
        setActivateEpUnauthorized(true)
      } else {
        setActivateEpError(true)
      }
      const activateEpProfileId = result?.data?.activateEmployer?.id
      if (activateEpProfileId) {
        refetchEmployers()
      }
    } catch (e) {
      setActivateEpError(true)
    }
  }

  return {
    activateEp,
    activateEpSuccess,
    activateEpError,
    activateEpUnauthorized,
    setActivateEpSuccess,
    setActivateEpError,
    setActivateEpUnauthorized,
    deactivateEp,
    deactivateEpSuccess,
    deactivateEpError,
    deactivateEpUnauthorized,
    setDeactivateEpSuccess,
    setDeactivateEpError,
    setDeactivateEpUnauthorized
  }
}
