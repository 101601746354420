/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect, useContext } from 'react'
import { Document as DocumentElement } from 'model'
import { NotificationContext } from 'context/NotificationContext'
import Markdown from 'markdown-to-jsx'
import { DocumentContainer } from './style'

interface Props {
  documentProps: DocumentElement
}

const Document = ({ documentProps }: Props): React.ReactElement => {
  const { displayNotification } = useContext(NotificationContext)

  const [post, setPost] = useState('')

  useEffect(() => {
    let unmounted = false

    import(`assets/docs/${documentProps.name}`)
      .then(res => {
        fetch(res.default)
          .then(res => res.text())
          .then(res => !unmounted && setPost(res))
          .catch(() => {
            displayNotification('something goes wrong')
          })
      })
      .catch(() => displayNotification('something goes wrong'))

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line
  }, [documentProps])

  return (
    <DocumentContainer>
      <Markdown>{post}</Markdown>
    </DocumentContainer>
  )
}

export default Document
