/* eslint-disable no-console */
import React, { createContext, useMemo, useCallback } from 'react'
import { UserAccountEdge } from 'model/users'
import useUserAdmin from 'hooks/useUserAdmin'
import { UserProfile } from 'model/organization'

type UserAdminContextType = {
  inviteStaff: (variables: any) => any
  resendInvite: (variables: any) => void
  employerStaffAccounts: UserAccountEdge[]
  getUsers: (id: string) => void
  getUsersError: any
  getUsersResponse: any
  getUsersIsLoading: boolean
  totalCount: number
  loading: boolean
  paginationState: any
  filterState: any
  dispatchFilter: any
  dispatchPaginationState: any
  fetchMore: any
  changePermission: (userId: string, epId: string, roles: string[]) => any
  changePermissionSuccess: boolean
  changePermissionError: boolean
  changePermissionUnauthorized: boolean
  refetchUsers: any
  deactivateUser: (userId: string, epId: string) => any
  deactivateUserSuccess: boolean
  deactivateUserError: boolean
  deactivateUserUnauthorized: boolean
  activateUser: (email: string, epId: string) => any
  activateUserSuccess: boolean
  activateUserError: boolean
  activateUserUnauthorized: boolean
  getSelectedUser: (epId: string, userEmail: string) => void
  selectedUser: UserProfile | undefined | null
  selectedUserError: any
  selectedUserIsLoading: boolean
}

const INITIAL_DATA: UserAdminContextType = {
  inviteStaff: () => {},
  resendInvite: () => {},
  employerStaffAccounts: [],
  getUsers: () => {},
  getUsersError: null,
  getUsersIsLoading: true,
  getUsersResponse: null,
  totalCount: 0,
  loading: true,
  paginationState: {},
  filterState: {},
  dispatchFilter: () => {},
  dispatchPaginationState: () => {},
  fetchMore: () => {},
  changePermission: () => {},
  changePermissionSuccess: false,
  changePermissionError: false,
  changePermissionUnauthorized: false,
  refetchUsers: () => {},
  deactivateUser: () => {},
  deactivateUserUnauthorized: false,
  deactivateUserError: false,
  deactivateUserSuccess: false,
  activateUser: () => {},
  activateUserUnauthorized: false,
  activateUserError: false,
  activateUserSuccess: false,
  getSelectedUser: () => {},
  selectedUser: null,
  selectedUserError: null,
  selectedUserIsLoading: true
}

export const UserAdminContext =
  createContext<UserAdminContextType>(INITIAL_DATA)

export const UserAdminProvider = ({ children }: any): JSX.Element => {
  const {
    inviteStaff,
    resendInvite,
    employerStaffAccounts,
    getEmployerUsers,
    getEmployerUsersError,
    getEmployerUsersIsLoading,
    getEmployerUsersResponse,
    loading,
    paginationState,
    filterState,
    dispatchFilter,
    dispatchPaginationState,
    fetchMore,
    changePermission,
    changePermissionSuccess,
    changePermissionUnauthorized,
    changePermissionError,
    refetchUsers,
    deactivateUser,
    deactivateUserError,
    deactivateUserSuccess,
    deactivateUserUnauthorized,
    activateUser,
    activateUserSuccess,
    activateUserError,
    activateUserUnauthorized,
    getEmployerUser,
    selectedUserResponse,
    selectedUserError,
    selectedUserIsLoading
  } = useUserAdmin()

  const [selectedUser, setSelectedUser] = React.useState<
    UserProfile | null | undefined
  >({} as UserProfile)

  const [selectedUserEmail, setSelectedUserEmail] = React.useState<
    string | undefined | null
  >('')

  const getSelectedUser = useCallback(
    async (epId: string, userEmail: string) => {
      setSelectedUserEmail(userEmail)
      if (epId && userEmail) {
        await getEmployerUser({
          variables: {
            searchTerm: userEmail,
            roles: [],
            statuses: [],
            epId
          }
        })
      }
    },
    [getEmployerUser]
  )

  React.useMemo(() => {
    if (selectedUserResponse && selectedUserEmail) {
      setSelectedUser(
        selectedUserResponse?.employerStaffAccounts?.edges[0].node
      )
    }
  }, [selectedUserResponse, selectedUserEmail])

  const getUsers = useCallback(
    async (id: string) => {
      if (id) {
        await getEmployerUsers({
          variables: {
            searchTerm: '',
            roles: [],
            statuses: [],
            epId: id
          }
        })
      }
    },
    [getEmployerUsers]
  )

  const value = useMemo(
    () => ({
      inviteStaff,
      resendInvite,
      employerStaffAccounts: employerStaffAccounts.edges,
      totalCount: employerStaffAccounts.totalCount,
      getUsers,
      getUsersError: getEmployerUsersError,
      getUsersIsLoading: getEmployerUsersIsLoading,
      getUsersResponse: getEmployerUsersResponse,
      loading,
      paginationState,
      filterState,
      dispatchFilter,
      dispatchPaginationState,
      fetchMore,
      changePermission,
      changePermissionSuccess,
      changePermissionError,
      changePermissionUnauthorized,
      refetchUsers,
      deactivateUser,
      deactivateUserError,
      deactivateUserSuccess,
      deactivateUserUnauthorized,
      activateUser,
      activateUserSuccess,
      activateUserError,
      activateUserUnauthorized,
      getSelectedUser,
      selectedUser,
      selectedUserError,
      selectedUserIsLoading
    }),
    [
      employerStaffAccounts.edges,
      employerStaffAccounts.totalCount,
      getUsers,
      getEmployerUsersError,
      getEmployerUsersIsLoading,
      getEmployerUsersResponse,
      inviteStaff,
      loading,
      resendInvite,
      paginationState,
      filterState,
      dispatchFilter,
      dispatchPaginationState,
      fetchMore,
      changePermission,
      changePermissionSuccess,
      changePermissionError,
      changePermissionUnauthorized,
      refetchUsers,
      deactivateUser,
      deactivateUserError,
      deactivateUserSuccess,
      deactivateUserUnauthorized,
      activateUser,
      activateUserError,
      activateUserSuccess,
      activateUserUnauthorized,
      getSelectedUser,
      selectedUser,
      selectedUserError,
      selectedUserIsLoading
    ]
  )

  return (
    <UserAdminContext.Provider value={value}>
      {children}
    </UserAdminContext.Provider>
  )
}
