/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  CREATE_EMPLOYER,
  GET_EMPLOYER,
  GET_EMPLOYER_LOGIN_TYPE,
  GET_EMPLOYERS,
  UPDATE_EMPLOYER_IMPACT_MANAGER,
  UPDATE_EMPLOYER_NAME
} from 'context/AdminContext/gql'
import { OrganizationEdge, OrganizationsResponseType } from 'model/organization'

export const useAdmin = () => {
  const [
    getEmployer,
    {
      data: getEmployerData,
      error: getEmployerError,
      loading: getEmployerIsLoading
    }
  ] = useLazyQuery(GET_EMPLOYER)

  const INITIAL_ORGANIZATIONS_RESPONSE: OrganizationsResponseType = {
    data: null
  }

  const INITIAL_ORGANIZATION_DATA: OrganizationEdge[] = [] as OrganizationEdge[]

  const [organizationEdges, setOrganizationEdges] = useState<
    OrganizationEdge[]
  >(INITIAL_ORGANIZATION_DATA)

  const {
    data: organizationsResponse = INITIAL_ORGANIZATIONS_RESPONSE,
    error: getOrganizationsError,
    loading: getOrganizationsIsLoading,
    refetch: refetchOrgs
  } = useQuery(GET_EMPLOYERS)

  const [getEmployerLoginType, { data: employerLoginType }] = useLazyQuery(
    GET_EMPLOYER_LOGIN_TYPE
  )

  const [
    submitCreateEmployer,
    { data: createEmployerResponse, error: createEmployerError }
  ] = useMutation(CREATE_EMPLOYER, { fetchPolicy: 'no-cache' })

  const [
    submitUpdateEmployerName,
    { data: updateEmployerNameResponse, error: updateEmployerNameError }
  ] = useMutation(UPDATE_EMPLOYER_NAME)

  const [
    submitUpdateEmployerImpactManager,
    { error: updateEmployerImpactManagerError }
  ] = useMutation(UPDATE_EMPLOYER_IMPACT_MANAGER)

  useEffect(() => {
    if (organizationsResponse && organizationsResponse.employers) {
      setOrganizationEdges(organizationsResponse.employers.edges)
    }
  }, [organizationsResponse])

  return {
    organizationEdges,
    refetchOrgs,
    getOrganizationsError,
    getOrganizationsIsLoading,
    getEmployer,
    getEmployerResponse: getEmployerData?.employer,
    getEmployerError,
    getEmployerIsLoading,
    submitCreateEmployer,
    createEmployerError,
    createEmployerResponse: createEmployerResponse?.createEmployer,
    submitUpdateEmployerImpactManager,
    updateEmployerImpactManagerError,
    submitUpdateEmployerName,
    updateEmployerNameError,
    updateEmployerNameResponse: updateEmployerNameResponse?.updateEmployerName,
    getEmployerLoginType,
    employerLoginType
  }
}
