/* eslint-disable no-console */
import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { Requisition } from 'model/atsMock'
import { AtsMockContext } from 'context/AtsMockContext'
import Feedback from 'components/Feedback'
import AtsDownloadButton from 'components/AtsDownloadButton/AtsDownloadButton'
import MockMergeModal from 'components/MockMergeModal'
import SalesPage from '../SalesPage'
import MockJobsPage from '../MockJobsPage'
import MockJobPage from '../MockJobPage'

interface MockPageProps {
  jobId: string | undefined
}

const MockPage = ({ jobId }: MockPageProps) => {
  // TODO: Replace values with actual
  const lastSyncedDate = 'November 8, 2024'
  const integration = 'Rippling'

  const {
    getJobById,
    jobByIdResponse,
    jobByIdIsLoading,
    jobByIdError,
    selectedJobId,
    jobsResponse
  } = useContext(AtsMockContext)

  const [job, setJob] = useState<Requisition | null | undefined>(null)
  const [isAtsLinked, setIsAtsLinked] = useState<boolean>(true)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (jobId && (!job || selectedJobId !== jobId)) {
      getJobById(jobId)
    }
  }, [jobId, job, getJobById, selectedJobId])

  useMemo(() => {
    if (jobByIdResponse) {
      setJob(jobByIdResponse)
    }
  }, [jobByIdResponse, setJob])

  const handleDownload = () => {
    console.log('downloaded')
  }

  const openMockMergeModal = () => {
    setIsModalOpen(true)
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        position: 'relative',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ width: '100%' }}>
        {isAtsLinked && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              marginBottom: '50px'
            }}
          >
            <Box>
              <Typography
                sx={{ textAlign: 'left' }}
                variant='h1'
                marginBottom='24px'
              >
                ATS Dashboard
              </Typography>
              <Typography
                sx={{ paddingLeft: '3px' }}
                variant='body2'
                marginBottom='10px'
              >
                Last refreshed{' '}
                <span
                  style={{ fontWeight: 'bold' }}
                >{`${lastSyncedDate} `}</span>
                from <span style={{ fontWeight: 'bold' }}>{integration}</span>
              </Typography>
              <Box>
                <Feedback />
              </Box>
            </Box>
            <AtsDownloadButton onClick={handleDownload} />
          </Box>
        )}
      </Box>
      {jobId ? (
        <Box sx={{ width: '100%' }}>
          <MockJobPage
            job={job || ({} as Requisition)}
            isLoading={jobByIdIsLoading}
            error={jobByIdError ? 'server error' : ''}
          />
        </Box>
      ) : (
        <Box sx={{ width: '100%' }}>
          {isAtsLinked ? (
            <MockJobsPage jobs={jobsResponse} />
          ) : (
            <SalesPage onClick={openMockMergeModal} />
          )}
        </Box>
      )}
      <MockMergeModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        setIsAtsLinked={setIsAtsLinked}
      />
    </Box>
  )
}
export default MockPage
