import { gql } from '@apollo/client'

export const GET_ATS_JOBS = gql`
  query GetAtsJobs($before: String, $after: String, $first: Int) {
    atsJobs(before: $before, after: $after, first: $first) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          name
          code
          createdAt
          currentApplicants
          totalApplicants
          status
          interviewStages {
            id
            name
            stageOrder
          }
        }
      }
    }
  }
`

export const GET_ATS_JOB_BY_ID = gql`
  query GetJobWithStats($id: ID!) {
    atsJob(id: $id) {
      id
      name
      code
      createdAt
      currentApplicants
      totalApplicants
      status
      stats {
        race {
          key
          value
        }
      }
      statsByInterviewStages {
        stage {
          id
          name
          stageOrder
        }
        stats {
          gender {
            key
            value
          }
          race {
            key
            value
          }
          disabilityStatus {
            key
            value
          }
          veteranStatus {
            key
            value
          }
        }
      }
    }
  }
`
