/* eslint-disable no-console */
import React, {
  createContext,
  Context,
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef
} from 'react'
import { useAdmin } from 'hooks/useAdmin'
import {
  OrganizationEdge,
  Organization,
  EmployerLoginType,
  UpdateEmployerNameResponseType,
  CreateEmployerResponseType,
  GetEmployerResponseType
} from 'model/organization'

type CreateEmployerInput = {
  name: string
  code: string
  status: 'active' | 'inactive'
}

type UpdateEmployerNameInput = {
  name: string
  id: string
}

// userId is the id of the new impact manager
type UpdateEmployerImpactManagerInput = {
  orgId: string
  userId: string
}

const INITIAL_LOGIN_TYPE: EmployerLoginType = {
  employerId: '',
  strategy: null,
  idpType: null
}

export type AdminContextType = {
  organizationEdges: OrganizationEdge[]
  getOrganizationsError: any
  getOrganizationsIsLoading: boolean
  refetchOrgs: () => void
  getEmployerData: (id: string) => void
  getEmployerResponse: GetEmployerResponseType | undefined | null
  getEmployerError: any
  getEmployerIsLoading: boolean
  createEmployer: (input: CreateEmployerInput) => void
  createEmployerError: any
  createEmployerResponse: CreateEmployerResponseType | undefined | null
  updateEmployerName: (input: UpdateEmployerNameInput) => void
  updateEmployerNameError: any
  updateEmployerNameResponse: UpdateEmployerNameResponseType | undefined | null
  updateEmployerImpactManager: (input: UpdateEmployerImpactManagerInput) => void
  selectedEmployer?: Organization
  setSelectedEmployerId: (id: string) => void
  loadSelectedEmployer: (employerId?: string) => void
  hasAccessToSearchParamEmployer: boolean
  loginType: EmployerLoginType
}

export const AdminContext: Context<AdminContextType> =
  createContext<AdminContextType>({
    organizationEdges: [] as OrganizationEdge[],
    getOrganizationsError: null,
    getOrganizationsIsLoading: true,
    refetchOrgs: () => {},
    getEmployerData: () => {},
    getEmployerResponse: null,
    getEmployerError: null,
    getEmployerIsLoading: true,
    createEmployer: () => {},
    createEmployerError: null,
    createEmployerResponse: null,
    updateEmployerName: () => {},
    updateEmployerNameError: null,
    updateEmployerNameResponse: null,
    updateEmployerImpactManager: () => {},
    selectedEmployer: undefined,
    setSelectedEmployerId: () => {},
    loadSelectedEmployer: () => {},
    hasAccessToSearchParamEmployer: false,
    loginType: INITIAL_LOGIN_TYPE
  })

export const AdminProvider = ({ children }: any): JSX.Element => {
  const {
    organizationEdges,
    refetchOrgs,
    getOrganizationsError,
    getOrganizationsIsLoading,
    getEmployer,
    getEmployerResponse,
    getEmployerError,
    getEmployerIsLoading,
    submitCreateEmployer,
    createEmployerError,
    createEmployerResponse,
    submitUpdateEmployerName,
    updateEmployerNameError,
    updateEmployerNameResponse,
    submitUpdateEmployerImpactManager,
    getEmployerLoginType,
    employerLoginType
  } = useAdmin()

  const firstRender = useRef(true)
  const [selectedEmployerId, setSelectedEmployerId] = useState('')
  const [loginType, setLoginType] =
    useState<EmployerLoginType>(INITIAL_LOGIN_TYPE)
  const [hasAccessToSearchParamEmployer, setHasAccessToSearchParamEmployer] =
    useState(true)
  const [selectedEmployer, setSelectedEmployer] = useState<
    Organization | undefined
  >(undefined)

  const getEmployerData = useCallback(
    async (id: string) => {
      if (id) {
        await getEmployer({
          variables: { id }
        })
      }
    },
    [getEmployer]
  )

  const getLoginType = useCallback(
    async (employerId: string) => {
      if (employerId) {
        await getEmployerLoginType({
          variables: { employerId }
        })
      }
    },
    [getEmployerLoginType]
  )

  const loadSelectedEmployer = useCallback(
    (employerId: string = '') => {
      setSelectedEmployerId(employerId)
      getLoginType(employerId)
    },
    [getLoginType]
  )

  useEffect(() => {
    if (organizationEdges) {
      const edges = [...organizationEdges]
      setSelectedEmployer(
        edges.find(({ node }: any) => node?.id === selectedEmployerId)?.node
      )
      if (selectedEmployer && selectedEmployer?.id.length > 0) {
        const searchParamEmployer: OrganizationEdge | undefined = edges.find(
          ({ node }) => node.id === selectedEmployer.id
        )
        if (searchParamEmployer) {
          if (firstRender.current === true) {
            firstRender.current = false
            loadSelectedEmployer(selectedEmployer.id)
          } else {
            setHasAccessToSearchParamEmployer(true)
          }
        } else {
          setHasAccessToSearchParamEmployer(false)
        }
      }
    }
  }, [
    selectedEmployer,
    loadSelectedEmployer,
    organizationEdges,
    selectedEmployerId
  ])

  useEffect(() => {
    if (employerLoginType) {
      setLoginType(employerLoginType?.employerAuthenticationSettings)
    }
  }, [employerLoginType])

  const createEmployer = useCallback(
    async (input: CreateEmployerInput) => {
      if (input) {
        await submitCreateEmployer({
          variables: { name: input.name, code: input.code, status: 'active' }
        })
      }
    },
    [submitCreateEmployer]
  )

  const updateEmployerName = useCallback(
    async (input: UpdateEmployerNameInput) => {
      if (input) {
        await submitUpdateEmployerName({
          variables: { name: input.name, id: input.id }
        })
      }
    },
    [submitUpdateEmployerName]
  )

  const updateEmployerImpactManager = useCallback(
    async (input: UpdateEmployerImpactManagerInput) => {
      if (input) {
        await submitUpdateEmployerImpactManager({ variables: { input } })
      }
    },
    [submitUpdateEmployerImpactManager]
  )

  const values = useMemo(
    () => ({
      organizationEdges,
      getOrganizationsError,
      getOrganizationsIsLoading,
      refetchOrgs,
      getEmployerData,
      getEmployerResponse,
      getEmployerError,
      getEmployerIsLoading,
      createEmployer,
      createEmployerError,
      createEmployerResponse,
      updateEmployerName,
      updateEmployerNameError,
      updateEmployerNameResponse,
      updateEmployerImpactManager,
      setSelectedEmployerId,
      loadSelectedEmployer,
      selectedEmployer,
      hasAccessToSearchParamEmployer,
      loginType
    }),
    [
      organizationEdges,
      getOrganizationsError,
      getOrganizationsIsLoading,
      refetchOrgs,
      getEmployerData,
      getEmployerResponse,
      getEmployerError,
      getEmployerIsLoading,
      createEmployer,
      createEmployerError,
      createEmployerResponse,
      updateEmployerName,
      updateEmployerNameError,
      updateEmployerNameResponse,
      updateEmployerImpactManager,
      setSelectedEmployerId,
      loadSelectedEmployer,
      selectedEmployer,
      hasAccessToSearchParamEmployer,
      loginType
    ]
  )

  return (
    <AdminContext.Provider value={values}>{children}</AdminContext.Provider>
  )
}
