/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { generateMockRepresentationStatus } from './useJobsData'

interface IGetJobData {
  variables: { id: string }
}

export const useAtsMock = () => {
  const [requisitions, setRequisitions] = useState<any[]>([])
  const [reqsError, setReqsError] = useState<boolean>(false)
  const [reqsIsLoading, setReqsIsLoading] = useState<boolean>(true)
  const [atsData, setAtsData] = useState([])
  const [atsDataIsLoading, setAtsDataIsLoading] = useState<boolean>(true)
  const [atsDataError, setAtsDataError] = useState<boolean>(false)
  const [atsCurrentData, setAtsCurrentData] = useState([])
  const [atsCurrentDataIsLoading, setAtsCurrentDataIsLoading] =
    useState<boolean>(true)
  const [atsCurrentDataError, setAtsCurrentDataError] = useState<boolean>(false)

  useEffect(() => {
    const fetchRequisitions = async () => {
      try {
        const response = await fetch(
          'https://pulsarmockdata.s3.us-east-2.amazonaws.com/positions.json'
        )
        if (!response.ok) {
          setReqsError(true)
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const json = await response.json()
        // set mock diversity statuses to each job
        const jobs = generateMockRepresentationStatus(json)
        setRequisitions(jobs)
        setReqsIsLoading(false)
      } catch (error) {
        setReqsError(true)
        console.error('Error fetching JSON:', error)
      }
    }

    const fetchAtsData = async () => {
      try {
        const response = await fetch(
          'https://pulsarmockdata.s3.us-east-2.amazonaws.com/atsData.json'
        )
        if (!response.ok) {
          setAtsDataError(true)
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const json = await response.json()
        setAtsData(json)
        setAtsDataIsLoading(false)
      } catch (error) {
        setAtsDataError(true)
        console.error('Error fetching JSON:', error)
      }
    }

    const fetchAtsCurrentData = async () => {
      try {
        const response = await fetch(
          'https://pulsarmockdata.s3.us-east-2.amazonaws.com/atsCurrentData.json'
        )
        if (!response.ok) {
          setAtsCurrentDataError(true)
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const json = await response.json()
        setAtsCurrentData(json)
        setAtsCurrentDataIsLoading(false)
      } catch (error) {
        setAtsCurrentDataError(true)
        console.error('Error fetching JSON:', error)
      }
    }

    fetchRequisitions()
    fetchAtsData()
    fetchAtsCurrentData()
  }, [])

  const jobsResponse = requisitions
  const jobsError = reqsError
  const jobsAreLoading = reqsIsLoading

  const jobCumulativeResponse = atsData
  const jobCumulativeError = atsDataError
  const jobCumulativeIsLoading = atsDataIsLoading

  const jobResponse = atsCurrentData
  const jobError = atsCurrentDataError
  const jobIsLoading = atsCurrentDataIsLoading

  const getJobDataById = async (input: IGetJobData) => {
    console.log(input)
  }

  const jobByIdError = null
  const jobByIdIsLoading = false

  return {
    jobsResponse,
    jobsError,
    jobsAreLoading,
    getJobDataById,
    jobByIdError,
    jobByIdIsLoading,
    jobCumulativeError,
    jobCumulativeIsLoading,
    jobCumulativeResponse,
    jobResponse,
    jobError,
    jobIsLoading
  }
}
