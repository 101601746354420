import { Socket as PhoenixSocket, LongPoll } from 'phoenix'
import { getAccessToken } from 'utils/authTokenProvider'
import * as impersonationStorage from 'utils/impersonationStorage'

const reconnectIntervals = [50, 100, 200, 500, 1000, 2000]

/**
 * The current type definition of Phoenix `Socket` class does not include the
 * `replaceTransport` function nor the `transport` property, so we define an
 * interface extending the base these included, and then cast the returned value
 * into this interface.
 */
interface ReplaceableSocket extends PhoenixSocket {
  replaceTransport(newTransport: Function): void
  transport: any
}

export const phoenixSocket = new PhoenixSocket(
  `${
    process.env.REACT_APP_SERVER_ENDPOINT_URL || 'ws://localhost:4000'
  }/socket`,
  {
    params: () => {
      const impersonationCode = impersonationStorage.get()

      return {
        access_token: getAccessToken(),
        client: 'app_portal',
        impersonation_code: impersonationCode
      }
    }
  }
) as ReplaceableSocket

// Safeguard around (very) old browsers that do not support WebSockets. Since
// Phoenix sockets implementation will do the same check and fallback to
// longpoll, if WebSockets are not supported we bypass the transport switching
// completely.
const canReplaceTransport =
  typeof WebSocket !== 'undefined' && phoenixSocket.transport === WebSocket

/**
 * Attempts socket reconnection and switches transports if applicable.
 * Reconnection intervals are incremental based on the `reconnectIntervals`
 * array, once the end of the array is reached, it won't attempt to reconnect
 * anymore.
 *
 * Transport switches are implemented in a very simple round-robin fashion:
 * first, WebSocket is used (the default if WebSocket is available), if the
 * connection fails, a LongPoll connection is attempted immediately, reconnect
 * counter is not increased and the interval is ignored. Then, if the LongPoll
 * attempt fails as well, a new WebSocket connection is attempted, but this time
 * the counter is incremented and the interval is respected. In practice, this
 * has the effect of attempting two consecutive connections, one with WebSocket
 * and another one with LongPoll, then wait the corresponding interval, and then
 * it starts again.
 *
 * This simple algorithm could be improved in different ways and be made smarter
 * (e.g. for users we know WebSockets should work there's no need to perform a
 * fallback). However, at this stage it's kept simple to first evaluate how it
 * behaves in the wild.
 */

export const reconnect = (reconnectAfterMs: number = 0) => {
  phoenixSocket.disconnect(() => {
    setTimeout(() => phoenixSocket.connect(), reconnectAfterMs)
  })
}

export const attemptSocketReconnect = (reconnectCounter: number) => {
  // skip transport switching if applicable
  if (canReplaceTransport) {
    if (phoenixSocket.transport === WebSocket) {
      phoenixSocket.replaceTransport(LongPoll)
      // WebSocket is the first method that is tried, so when switching to
      // LongPoll do not wait and attempt the connection immediately
      reconnect(reconnectIntervals[reconnectCounter])

      return
    }
    phoenixSocket.replaceTransport(WebSocket)
  }

  reconnect()
}
