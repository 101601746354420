import React from 'react'
import { Color } from 'components'
import { HashLink as Link } from 'react-router-hash-link'
import { Drawer as MuiDrawer, Tabs as MuiTabs } from '@mui/material'
import { styled } from '@mui/material/styles'

const PREFIX = 'Drawer'

const classes = {
  tabsBar: `${PREFIX}-tabsBar`,
  tabsBarTitle: `${PREFIX}-tabsBarTitle`,
  tabTitle: `${PREFIX}-tabTitle`
}

const StyledMuiDrawer = styled(MuiDrawer)(() => ({
  [`& .${classes.tabsBar}`]: {
    padding: '0px 16px',
    marginBottom: 24,
    '&:last-child': {
      marginBottom: 0
    }
  },

  [`& .${classes.tabsBarTitle}`]: {
    alignItems: 'flex-start',
    paddingTop: 4,
    marginBottom: 16,
    paddingLeft: 22
  },

  [`& .${classes.tabTitle}`]: {
    fontSize: 13,
    lineHeight: '16px',
    letterSpacing: '0.094em'
  }
}))

const drawerWidth = 284
export const Drawer = styled((props: any) => <StyledMuiDrawer {...props} />)({
  width: drawerWidth,
  position: 'relative',
  '.MuiDrawer-paper': {
    width: drawerWidth,
    position: 'absolute',
    right: 0,
    paddingTop: 56,
    left: 'auto',
    height: 'calc(100% - 56px)',
    paddingLeft: '21px',
    '&.MuiPaper-elevation1': {
      boxShadow: '7px 0px 10px rgba(5, 11, 14, 0.02)'
    },
    borderRight: 'none'
  }
})
export const DrawerContainer = styled('div')({
  overflow: 'auto',
  overflowX: 'hidden'
})
export const HashLink = styled(Link)({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  width: '100%'
})
export const Tabs = styled(MuiTabs)({
  paddingLeft: 8,
  marginTop: 29,
  width: '100%',
  '& .flexContainerVertical': {
    borderLeft: 'solid 2px #E8EBED'
  },
  '& .indicator': {
    left: 0,
    width: 3,
    backgroundColor: Color.blue400
  },
  '& .root': {
    minHeight: 38
  },
  '& .subTitle': {
    marginTop: 8
  }
})
export { classes }
