/* eslint-disable no-console */
import React, { useContext, useRef } from 'react'
import { styled } from '@mui/material/styles'
import { RollbarContext } from '@rollbar/react'
import { AuthenticationContext } from 'context/AuthenticationContext'
import { FeatureFlagsContext } from 'context/FeatureFlagsContext'
import { AccountProvider } from 'context/AccountContext'
import PathContextProvider from 'context/PathContext'
import { ApolloProvider } from '@apollo/client'
import { Routes, Route } from 'react-router-dom'
import StatementHub from 'pages/StatementHub'
import Impersonate from 'pages/Imersonate'
import LoginCallback from 'pages/Imersonate/callback'
import Client from 'utils/createApolloMaestroClient'
import Asgard from 'pages/Asgard'
import { checkAdminViewMode, getIdentity } from 'utils/authTokenProvider'
import ProtectedRoutes from './ProtectedRoutes'
import Redirect from './Redirect'
import { MicroAppContainer } from './ProtectedRoutes/MicroServiceContainer'

const AppUIWrapper = styled('div')({
  minHeight: '100vh'
})
const client = Client()
const MAHITSON_ORG = 'EP::MTSN'
const RoutesContainer = () => {
  const { isAuthenticated } = useContext(AuthenticationContext)
  const divRef: any = useRef<HTMLDivElement>(null)
  const { pathname } = window.location
  const identity = getIdentity()

  // TODO: We'll need to remove this feature flag when the new Asgard Service is ready for production
  const { isFlagEnabled } = useContext(FeatureFlagsContext)
  const isAsgardEnabled = isFlagEnabled('enable-asgard')

  return (
    <AppUIWrapper>
      <PathContextProvider>
        <MicroAppContainer
          id='asgard-root'
          ref={divRef}
          isServicePath={pathname.startsWith('/welcome')}
        />

        <Routes>
          <Route path={'/statement/*'} element={<StatementHub />} />
          <Route path='/login/impersonate/*' element={<Impersonate />} />
          <Route path='/login/callback/*' element={<LoginCallback />} />
          <Route
            path='/logout'
            element={
              <Redirect
                path={`${
                  process.env.REACT_APP_IDENTITY_ENTRY ??
                  'http://localhost:7106'
                }/logout?redirect_uri=${window.location.origin}`}
              />
            }
          />
          {identity.organizations.includes(MAHITSON_ORG) &&
            isAuthenticated &&
            isAsgardEnabled &&
            checkAdminViewMode() === undefined && (
              <Route path='/*' element={<Asgard />} />
            )}
          <Route
            path='/*'
            element={
              isAuthenticated ? (
                <ApolloProvider client={client}>
                  <AccountProvider>
                    <RollbarContext context='protected'>
                      <ProtectedRoutes />
                    </RollbarContext>
                  </AccountProvider>
                </ApolloProvider>
              ) : (
                <Redirect />
              )
            }
          />
        </Routes>
      </PathContextProvider>
    </AppUIWrapper>
  )
}
export default RoutesContainer
