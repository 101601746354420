/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import {
  DataGridPro,
  GridEventListener,
  GridRowParams
} from '@mui/x-data-grid-pro'
import { useNavigate } from 'react-router-dom'
import { Requisition } from 'model/atsMock'
import SearchBar from 'components/SearchBar'
import FilterButton from 'components/FilterButton'
import { tableStyles } from './styles'
import { jobColumns } from './config'

interface MockJobsPageProps {
  jobs: Requisition[]
}

const MockJobsPage = ({ jobs }: MockJobsPageProps) => {
  const navigate = useNavigate()

  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('OPEN')
  const [diversityStatus, setDiversityStatus] = useState('All')
  const [filteredJobs, setFilteredJobs] = useState<Requisition[]>(jobs)

  const handleRowClick: GridEventListener<'rowClick'> = (
    params: GridRowParams
  ) => {
    const jobId = params?.row?.id
    if (jobId) {
      navigate(`/ats/job/${jobId}`)
    }
  }

  const handleOnChange = (e: any) => {
    setSearch(e.target.value)
  }

  const clearSearch = () => {
    setSearch('')
  }

  const searchFilteredJobs = (jobsArray: Requisition[]) => {
    return jobsArray.filter((job: Requisition) =>
      search.toLowerCase() === ''
        ? job
        : job.title.toLowerCase().includes(search.toLowerCase()) ||
          job.id.toLowerCase().includes(search.toLowerCase())
    )
  }

  const getFilteredJobs = (
    allJobs: Requisition[],
    reqStatus: string,
    divStatus: string
  ) => {
    const result = allJobs.filter(job => {
      return (
        (!reqStatus || reqStatus === 'All' || job.status === reqStatus) &&
        (!divStatus || divStatus === 'All' || job.diversityStatus === divStatus)
      )
    })
    return result
  }

  useMemo(() => {
    setFilteredJobs(getFilteredJobs(jobs, status, diversityStatus))
  }, [status, diversityStatus, jobs])

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='h2' sx={{ textAlign: 'left', marginBottom: '40px' }}>
        Requisitions
      </Typography>
      <Box
        sx={{
          backgroundColor: 'white',
          border: '1px solid rgb(229, 229, 229)',
          borderRadius: '8px',
          padding: '40px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '20px 5px'
          }}
        >
          <SearchBar
            value={search}
            placeHolderText='Search positions by name or id'
            onChange={handleOnChange}
            clearSearch={clearSearch}
          />
          <FilterButton
            filterValue={status}
            setFilterValue={setStatus}
            dataLabel='Status'
            options={['All', 'OPEN', 'CLOSED', 'PENDING']}
          />
          <FilterButton
            filterValue={diversityStatus}
            setFilterValue={setDiversityStatus}
            dataLabel='Goal'
            options={['All', 'achieved', 'improving', 'lagging']}
          />
        </Box>
        <DataGridPro
          sx={tableStyles}
          rows={searchFilteredJobs(filteredJobs)}
          columns={jobColumns}
          pagination
          disableColumnMenu
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              }
            }
          }}
          pageSizeOptions={[5, 10, 20]}
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  )
}
export default MockJobsPage
