import React, { useState, useEffect } from 'react'
import { CircularProgress, Box, BoxProps, styled } from '@mui/material'

interface IProps {
  timeout?: number
  containerHeight?: number | undefined | null
  circleSize?: number | undefined | null
}

interface StyledBoxProps extends BoxProps {
  enabled?: boolean
  containerHeight?: number | undefined | null
}

const StyledBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'containerHeight' && prop !== 'enabled'
})<StyledBoxProps>(({ enabled, containerHeight }) => ({
  height: containerHeight ? `${containerHeight}px` : '50vh',
  display: enabled ? 'flex' : 'none',
  justifyContent: 'center',
  alignItems: 'center'
}))

const LoadingIndicator = ({ timeout, containerHeight, circleSize }: IProps) => {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true)

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        setShowLoadingIndicator(false)
      }, timeout)
    }
  }, [timeout])

  return (
    <StyledBox containerHeight={containerHeight} enabled={showLoadingIndicator}>
      <CircularProgress size={circleSize || 75} thickness={3} />
    </StyledBox>
  )
}

export default LoadingIndicator
