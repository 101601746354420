import {
  CompanyAdminAccessCodes,
  CurrentAccount,
  EmployerStaffAccountRole
} from 'model/organization'

export const hasAccessToAdminPortal = (
  currentAccount: CurrentAccount
): boolean => {
  if (currentAccount) {
    const companyIsAllowed: boolean = !!(
      currentAccount.organizationId ===
        CompanyAdminAccessCodes.CHANGEFORCE_ORG ||
      currentAccount.organizationId === CompanyAdminAccessCodes.MAHITSON_ORG
    )
    const roleIsAllowed: boolean = !!currentAccount.roles.includes(
      EmployerStaffAccountRole.SUPER_MANAGER
    )
    return companyIsAllowed && roleIsAllowed
  }
  return false
}
