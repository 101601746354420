import Cookies from 'js-cookie'
import * as impersonationStorage from './impersonationStorage'

const getAccessToken = () => {
  const tokens = JSON.parse(Cookies.get('tokens') ?? '{}')
  const impersonationToken = impersonationStorage.getAccessToken()

  if (tokens?.access_token?.token) {
    return tokens.access_token.token
  }
  if (impersonationToken.accessToken) {
    return impersonationToken.accessToken.accessToken
  }

  return ''
}

const getIdToken = () => {
  const tokens = JSON.parse(Cookies.get('tokens') ?? '{}')

  if (tokens?.id_token?.token) {
    return tokens.id_token.token
  }
  return ''
}

const getIdentity = () => {
  const tokens = JSON.parse(Cookies.get('tokens') ?? '{}')

  if (tokens?.id_token) {
    const { claims } = tokens.id_token
    const { name, email, organizations } = claims
    return { name: name.replace('+', ' '), email, organizations }
  }
  return { name: '', email: '', organizations: [] }
}

const checkAdminViewMode = () => {
  const isForAdmin = localStorage.getItem('isForAdmin')

  if (isForAdmin === null) {
    return undefined
  }
  return JSON.parse(isForAdmin!)
}

const setAdminViewMode = (isForAdmin: boolean) => {
  localStorage.setItem('isForAdmin', JSON.stringify(isForAdmin))
}
export {
  getAccessToken,
  getIdToken,
  getIdentity,
  checkAdminViewMode,
  setAdminViewMode
}
