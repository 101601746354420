/* eslint-disable import/no-mutable-exports */
import axios from 'axios'

let podiumEntry = process.env.REACT_APP_PODIUM_ENTRY as string
let kcEntry = process.env.REACT_APP_KC_ENTRY as string
let namoraEntry = process.env.REACT_APP_NAMORA_ENTRY as string
let bumperEntry = process.env.REACT_APP_BUMPER_ENTRY as string
let fenderEntry = process.env.REACT_APP_FENDER_ENTRY as string
let orbitEntry = process.env.REACT_APP_ORBIT_ENTRY as string
let pulsarEntry = process.env.REACT_APP_PULSAR_ENTRY as string
let asgardEntry = process.env.REACT_APP_ASGARD_ENTRY as string

const checkServices = async () => {
  if (process.env.NODE_ENV === 'development') {
    Promise.allSettled([
      await axios.get(process.env.REACT_APP_ORBIT_ENTRY as string).catch(() => {
        orbitEntry = 'https://orbit.services.mathison-qa.com/'
      }),
      await axios
        .get(process.env.REACT_APP_PODIUM_ENTRY as string)
        .catch(() => {
          podiumEntry = 'https://podium.services.mathison-qa.com/'
        }),
      await axios.get(process.env.REACT_APP_KC_ENTRY as string).catch(() => {
        kcEntry = 'https://knowledge-center-client.services.mathison-qa.com/'
      }),
      await axios
        .get(process.env.REACT_APP_NAMORA_ENTRY as string)
        .catch(() => {
          namoraEntry = 'https://namora.services.mathison-qa.com/'
        }),
      await axios
        .get(process.env.REACT_APP_BUMPER_ENTRY as string)
        .catch(() => {
          bumperEntry = 'https://bumper.services.mathison-qa.com/'
        }),
      await axios
        .get(process.env.REACT_APP_FENDER_ENTRY as string)
        .catch(() => {
          fenderEntry = 'https://fender.services.mathison-qa.com/'
        }),
      await axios
        .get(process.env.REACT_APP_PULSAR_ENTRY as string)
        .catch(() => {
          pulsarEntry = 'https://pulsar.services.mathison-qa.com/'
        }),
      await axios
        .get(process.env.REACT_APP_ASGARD_ENTRY as string)
        .catch(() => {
          asgardEntry = 'https://asgard.services.mathison-qa.com/'
        })
    ])
  }
}

export {
  checkServices,
  podiumEntry,
  kcEntry,
  namoraEntry,
  fenderEntry,
  bumperEntry,
  orbitEntry,
  pulsarEntry,
  asgardEntry
}
