import React from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { Business as BusinessIcon } from '@mui/icons-material'

interface IProps {
  id: string
  created: string
  loginType: string
}

const SidePanelBody = ({ id, created, loginType }: IProps) => {
  return (
    <Box>
      <Box
        sx={{
          padding: '10px 0',
          margin: '20px 0',
          borderBottom: '1px solid #e5e5e5'
        }}
      >
        <Stack direction='row'>
          <BusinessIcon
            sx={{
              fontSize: '18px',
              color: '#3148D3',
              marginTop: '1px',
              marginRight: '5px'
            }}
          />
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 700,
              textTransform: 'uppercase'
            }}
          >
            Org Details
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ marginBottom: '25px' }}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '14px',
            paddingTop: '10px',
            paddingBottom: 0
          }}
        >
          Login Type
        </Typography>
        <Typography
          sx={{ fontSize: '12px', paddingBottom: '10px', paddingTop: '5px' }}
        >
          Only Org Admin Users can configure SSO for their org
        </Typography>
        <Typography sx={{ fontSize: '14px' }}>{loginType}</Typography>
      </Box>
      <Box sx={{ marginBottom: '25px' }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: '14px', padding: '10px 0' }}
        >
          Employer ID
        </Typography>
        <Typography sx={{ fontSize: '14px' }}>{id}</Typography>
      </Box>
      <Box sx={{ marginBottom: '25px' }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: '14px', padding: '10px 0' }}
        >
          Date Created
        </Typography>
        <Typography sx={{ fontSize: '14px' }}>{created}</Typography>
      </Box>
    </Box>
  )
}
export default SidePanelBody
