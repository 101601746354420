/* eslint-disable no-console */
import React, { useContext } from 'react'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import {
  Typography,
  List,
  Drawer as MuiDrawer,
  Grid,
  ListItemButton
} from '@mui/material'
import MathisonColourLogoWithoutText from 'assets/images/Mathison_Colour_Logo_Without_Text.png'
import MathisonColourLogo from 'assets/images/Mathison_Colour_Logo.png'
import { Color } from '@mathison-inc/components'

import { AccountContext } from 'context/AccountContext'
import { useNavigate } from 'react-router-dom'
import { FeatureFlagsContext } from 'context/FeatureFlagsContext'
import { AccessContext } from 'context/AccessContext'
import NavigationTab from './NavigationTab'
import { Navigations } from './constants'
import AppBar from './AppBar'

const drawerWidth = 250
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `72px`,
  [theme.breakpoints.up('sm')]: {
    width: `72px`
  }
})
interface DrawerHeaderProps {
  expanded: boolean
}

const DrawerHeader = styled('div')<DrawerHeaderProps>(
  ({ theme, expanded }) => ({
    display: 'flex',
    alignItems: 'center',

    marginLeft: expanded ? '20px' : '16px',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  })
)

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: 0,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  zIndex: 1201,
  ...(open && {
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const ListLabel = styled(Typography)(() => ({
  color: Color.grey500,
  textTransform: 'uppercase',
  textAlign: 'left',
  marginLeft: '26px'
}))

const fadeInOutStyle = {
  WebkitTransition: 'opacity 0.3s ease, visibility 0.3s ease',
  transition: 'opacity 0.3s ease, visibility 0.3s ease'
}

const hiddenStyle = {
  opacity: 0,
  position: 'absolute'
}

const SideNavigation = ({
  basicUser,
  isAdmin,
  user,
  expanded,
  setOpen,
  setOnHover,
  open
}: any) => {
  const {
    data: { currentAccount }
  } = useContext(AccountContext)
  const { roles } = currentAccount

  const navigate = useNavigate()
  const { hasCompletedAnEmployeeSurvey } = useContext(AccessContext)
  const { flags, isFlagEnabled } = useContext(FeatureFlagsContext)
  const surveyIsEnabled = isFlagEnabled('enable_employee_survey')
  const adminIsEnabled = isFlagEnabled('enable-new-admin-portal')

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        open={open}
        setOpen={setOpen}
        expanded={expanded}
        basicUser={basicUser}
        user={user}
      />
      <Drawer
        variant='permanent'
        open={expanded}
        sx={{ width: 0 }}
        onMouseEnter={() => {
          setOnHover(true)
        }}
        onMouseLeave={() => {
          setOnHover(false)
        }}
      >
        <DrawerHeader expanded={expanded}>
          <Grid
            onClick={() => {
              navigate('/')
            }}
            sx={{ cursor: 'pointer', position: 'relative' }}
          >
            <img
              src={MathisonColourLogo}
              width='149'
              alt='Mathison'
              style={
                {
                  ...fadeInOutStyle,
                  ...(expanded ? {} : hiddenStyle)
                } as any
              }
            />

            <img
              src={MathisonColourLogoWithoutText}
              width='32'
              alt='Mathison'
              style={
                {
                  ...fadeInOutStyle,
                  ...(expanded ? hiddenStyle : {})
                } as any
              }
            />
          </Grid>
        </DrawerHeader>

        <ListItemButton
          className='skip-to-main-content-link'
          onClick={() => {
            navigate('#main-content')
            document.getElementById('main-content')?.focus()
          }}
        >
          Skip to main content
        </ListItemButton>

        {Navigations.map(
          ({
            title,
            list,
            access,
            noAccess,
            checkIfHasAdminPortalAccess,
            flagKey: mainFlagKey
          }) => {
            if (
              (access && !roles.includes(access)) ||
              (noAccess && roles.includes(noAccess)) ||
              (mainFlagKey && !flags![mainFlagKey])
            ) {
              return null
            }
            if (checkIfHasAdminPortalAccess) {
              if (!isAdmin || !adminIsEnabled) return null
            }
            return (
              <div key={title}>
                {expanded && <ListLabel variant='subtitle2'>{title}</ListLabel>}
                <List sx={{ marginBottom: 1 }}>
                  {list.map(
                    ({
                      label,
                      icon,
                      sublist,
                      url,
                      access: listAccess,
                      noAccess: listNoAccess,
                      checkIfSurveyExists,
                      flagKey
                    }: any) => {
                      if (
                        (listAccess && !roles.includes(listAccess)) ||
                        (flagKey && !flags![flagKey]) ||
                        (listNoAccess && roles.includes(listNoAccess))
                      ) {
                        return null
                      }
                      if (
                        checkIfSurveyExists &&
                        !hasCompletedAnEmployeeSurvey &&
                        !surveyIsEnabled
                      ) {
                        return null
                      }
                      if (checkIfHasAdminPortalAccess) {
                        if (!isAdmin || !adminIsEnabled) return null
                      }
                      return (
                        <NavigationTab
                          key={label}
                          label={label}
                          icon={icon}
                          sublist={sublist}
                          expanded={expanded}
                          url={url}
                        />
                      )
                    }
                  )}
                </List>
              </div>
            )
          }
        )}
      </Drawer>
    </Box>
  )
}
export default SideNavigation
