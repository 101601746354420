/* eslint-disable no-console */
import React, { createContext, Context, useMemo, useCallback } from 'react'
import { Job } from 'model/ats'
import { useAts } from 'hooks/useAts'

type AtsContextType = {
  jobs: Job[]
  jobsIsLoading: boolean
  jobsError: boolean
  getJobById: (jobId: string) => void
  job: Job | null
  jobIsLoading: boolean
  jobError: boolean
}

export const AtsContext: Context<AtsContextType> =
  createContext<AtsContextType>({
    jobs: [],
    jobsIsLoading: true,
    jobsError: false,
    getJobById: () => {},
    job: null,
    jobIsLoading: true,
    jobError: false
  })

export const AtsProvider = ({ children }: any): JSX.Element => {
  const {
    jobs,
    jobsIsLoading,
    jobsError,
    getAtsJobById,
    job,
    jobIsLoading,
    jobError
  } = useAts()

  const getJobById = useCallback(
    async (jobId: string) => {
      if (jobId) {
        await getAtsJobById({
          variables: { id: jobId }
        })
      }
    },
    [getAtsJobById]
  )

  const value: AtsContextType = useMemo(
    () => ({
      jobs,
      jobsIsLoading,
      jobsError,
      getJobById,
      job,
      jobIsLoading,
      jobError
    }),
    [jobs, jobsIsLoading, jobsError, getJobById, job, jobIsLoading, jobError]
  )

  return <AtsContext.Provider value={value}>{children}</AtsContext.Provider>
}
