import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const BiasScanner = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    if (pathname === '/bias-scanner') document.title = 'Bias Scanner | Mathison'
    return () => {
      document.title = 'Mathison'
    }
  }, [pathname])
  return (
    <iframe
      title='bias-scanner'
      src='https://app.dsk.mathison.io/bias-scanner/'
      style={{ width: '100%', height: 'calc(100vh - 56px - 147px)' }}
      frameBorder='0'
    />
  )
}

export default BiasScanner
