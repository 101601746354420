import React from 'react'
import { Box, Typography } from '@mui/material'
import AtsCard from 'components/AtsCard'
import { SALES_CARD_DATA } from '../constants/copy'

interface SalesPageProps {
  onClick: () => void
}

const SalesPage = ({ onClick }: SalesPageProps) => {
  return (
    <Box>
      <AtsCard data={SALES_CARD_DATA} onClick={onClick} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        <Box sx={{ paddingBottom: '40px', width: '100%' }}>
          <Box sx={{ width: '100%', textAlign: 'left', margin: '40px 0' }}>
            <Typography variant='h2'>Sales Page Continued</Typography>
            <Typography sx={{ padding: '40px 0', fontWeight: 'bold' }}>
              This could be charts showing sample data, sales marketing images,
              etc.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default SalesPage
