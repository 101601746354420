import { useEffect, useContext } from 'react'
import { NotificationContext } from 'context/NotificationContext'
import Client from 'utils/createApolloMaestroClient'
import { loadMicroApp } from 'qiankun'
import { AccountContext } from 'context/AccountContext'
import { asgardEntry } from 'utils/entryProvider'
import { setAdminViewMode } from 'utils/authTokenProvider'

const AppPortal = () => {
  const { displayNotification } = useContext(NotificationContext)
  const {
    data: { currentAccount }
  } = useContext(AccountContext)

  const continueAsRegualrUser = () => {
    setAdminViewMode(false)

    window.location.reload()
  }
  useEffect(() => {
    const app = loadMicroApp({
      name: 'asgard', // app name registered, same as the one in package.json
      entry: asgardEntry, // app entry
      container: '#asgard-root',
      props: {
        maestroClient: Client(),
        currentAccount,
        displayNotification,
        continueAsRegualrUser
      }
    })

    // eslint-disable-next-line consistent-return
    return () => {
      if (app.getStatus() === 'MOUNTED') {
        app.unmount()
      }
    }
    // eslint-disable-next-line
  }, [])

  return null
}

export default AppPortal
