import React from 'react'
import { Button, ButtonProps, styled, Stack } from '@mui/material'
import { PowerSettingsNew as ActivateIcon } from '@mui/icons-material'

const StyledButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#F2F3F3',
  color: '#747779',
  padding: '13px 17px 13px 20px',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: '#3148D3',
    color: '#FFFFFF'
  }
}))

interface ActivateButtonProps {
  onClick: () => void
}

const ActivateButton = ({ onClick }: ActivateButtonProps) => {
  return (
    <StyledButton onClick={onClick}>
      <Stack direction='row' alignContent='center'>
        <ActivateIcon
          sx={{ fontSize: '20px', marginRight: '5px', marginTop: '1px' }}
        />
        Reactivate
      </Stack>
    </StyledButton>
  )
}
export default ActivateButton
