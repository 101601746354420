import React, { useEffect, useContext } from 'react'
import { captureException } from '@sentry/react'
import { AuthenticationContext } from 'context/AuthenticationContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import * as impersonationStorage from 'utils/impersonationStorage'

import queryString from 'query-string'
import { oktaAuthClient } from '.'

const parseState = (state: string): any => {
  const parsedState = queryString.parse(state)
  const result: any = {}

  Object.keys(parsedState).forEach(key => {
    const value: any = parsedState[key]
    if (value) {
      result[key] = typeof value === 'string' ? value : value[0]
    }
  })

  return result
}
const parseTokenFromUrl = async () => {
  const { tokens, state } = await oktaAuthClient.token.parseFromUrl()

  if (tokens && tokens.idToken && tokens.accessToken) {
    oktaAuthClient.tokenManager.setTokens(tokens)

    const parsedState = parseState(state)
    const { employerCode } = parsedState

    if (employerCode) {
      impersonationStorage.set(employerCode)
    }

    return { tokens, state: parsedState }
  }
  throw new Error('Invalid tokens')
}

const LoginCallback = (): React.ReactElement => {
  const { search } = useLocation()

  const { isAuthenticated, handleLogout, setIsAuthenticated } = useContext(
    AuthenticationContext
  )

  const navigate = useNavigate()
  const parseUrl = async () => {
    try {
      await parseTokenFromUrl()
      setIsAuthenticated(true)
      navigate('/')
    } catch (error) {
      captureException(error)
      handleLogout('Sorry, something went wrong')
    }
  }

  useEffect(() => {
    if (isAuthenticated === false && search) parseUrl()
    // eslint-disable-next-line
  }, [isAuthenticated])

  return <CircularProgress />
}

export default LoginCallback
