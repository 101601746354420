import React, { useMemo } from 'react'
import { GradientBackground } from 'components'

interface State {
  hasBackground: boolean
  style?: any
}

const initialState: State = {
  hasBackground: false
}

type Action = { type: 'setBackground' | 'clearBackground'; data?: any }

export type ContextData = {
  state: State
  addBackground: (style?: any) => void
  removeBackground: () => void
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setBackground':
      return { ...state, hasBackground: true, style: action.data }
    case 'clearBackground':
      return { ...state, hasBackground: false }
    default:
      return { ...initialState }
  }
}

export const BackgroundContext = React.createContext<ContextData>({
  state: { ...initialState },
  addBackground: () => {},
  removeBackground: () => {}
})

const BackgroundProvider = ({ children }: any): JSX.Element => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const addBackground = (style?: any): void => {
    dispatch({ type: 'setBackground', data: style })
  }
  const removeBackground = (): void => {
    dispatch({ type: 'clearBackground' })
  }
  const value = useMemo(
    () => ({ state, addBackground, removeBackground }),
    [state]
  )
  return (
    <BackgroundContext.Provider value={value}>
      <GradientBackground hasBackground={state.hasBackground} sx={state.style}>
        {children}
      </GradientBackground>
    </BackgroundContext.Provider>
  )
}

export default BackgroundProvider
