/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, {
  useState,
  ReactElement,
  useContext,
  useEffect,
  useRef
} from 'react'
import ImpersonatorBanner from 'components/ImpersonatorBanner'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useRollbar, useRollbarPerson } from '@rollbar/react'
import mixpanel from 'mixpanel-browser'
import BiasScanner from 'pages/BiasScanner'
import NotFound from 'pages/NotFound'
import KnowledgeCenter from 'pages/KnowledgeCenter'
import EquityIndex from 'pages/EquityIndex'
import { AccountContext } from 'context/AccountContext'
import {
  EmployerStaffAccountEhiAccessLevel,
  EmployerStaffAccountRole
} from 'model/organization'
import Grid from '@mui/material/Unstable_Grid2'

import ActionPlan from 'pages/ActionPlan'
import Podium from 'pages/Podium'
import Orbit from 'pages/Orbit'
import Pulsar from 'pages/Pulsar'
import { AccessProvider } from 'context/AccessContext'
import { FeatureFlagsContext } from 'context/FeatureFlagsContext'
import { CircularProgress } from '@mui/material'
import { AuthenticationContext } from 'context/AuthenticationContext'
import SideNavigation from 'components/SideNavigation'
import Laboratory from 'pages/Laboratory'
import AIEI from 'pages/Laboratory/AIEI'
import { useIntercom } from 'react-use-intercom'
import {
  checkServices,
  fenderEntry,
  kcEntry,
  namoraEntry,
  podiumEntry,
  bumperEntry,
  orbitEntry,
  pulsarEntry
} from 'utils/entryProvider'
import { prefetchApps } from 'qiankun'
import AdminPortal from 'pages/AdminPortal'
import { AdminProvider } from 'context/AdminContext'
import { AtsMockProvider } from 'context/AtsMockContext'
import { hasAccessToAdminPortal } from 'utils/authCheck'
import NotAuthorized from 'pages/NotAuthorized'
import AtsHome from 'pages/AtsHome'
import { AtsProvider } from 'context/AtsContext'
import MicroServiceContainer from './MicroServiceContainer'

const ProtectedRoutes = (): ReactElement => {
  const { logout } = useContext(AuthenticationContext)
  const { flags, isFlagEnabled } = useContext(FeatureFlagsContext)
  const [open, setOpen] = useState(false)
  const [onHover, setOnHover] = useState(false)
  const [userAccount, setUserAccount] = useState<any>(null)

  useEffect(() => {
    const prefetch = async () => {
      await checkServices()

      prefetchApps([
        { name: 'namora', entry: namoraEntry },
        { name: 'kc-portal', entry: kcEntry },
        { name: 'bumper', entry: bumperEntry },
        { name: 'fender', entry: fenderEntry },
        { name: 'podium', entry: podiumEntry },
        { name: 'orbit', entry: orbitEntry },
        { name: 'pulsar', entry: pulsarEntry }
      ])
    }
    prefetch()
  }, [])
  const {
    data: { currentAccount },
    isImpersonator
  } = useContext(AccountContext)

  const { email, user, ehiAccess, roles } = currentAccount
  const isAdmin: boolean = hasAccessToAdminPortal(currentAccount)
  const isAtsEnabled: boolean = isFlagEnabled('enable-ats') || false

  const location = useLocation()

  // Send user data to rollbar
  useRollbarPerson(userAccount)
  const rollbar = useRollbar()
  try {
    rollbar.info(`User ${user?.primaryEmail} has successfully logged in.`)
  } catch (e) {
    rollbar.error(`User object ${user} caused an error: ${e}`)
  }

  const userRole = roles.includes(EmployerStaffAccountRole.BASIC_USER)
    ? 'Basic User'
    : roles.includes(EmployerStaffAccountRole.MANAGER)
    ? 'Manager'
    : 'Super Manager'

  // Send user data to Mixpanel
  if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    mixpanel.identify(user.id)
    mixpanel.people.set({
      $name: user.name,
      $email: user.primaryEmail,
      user_role: userRole
    })
    mixpanel.track('User Session Start', {
      user_id: user.id,
      user_name: user.name,
      user_email: user.primaryEmail,
      user_role: userRole,
      timestamp: new Date().toISOString()
    })
  }

  const sessionStartTime = useRef(Date.now())

  useEffect(() => {
    const handleBeforeUnload = () => {
      const sessionDuration = Date.now() - sessionStartTime.current

      if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        // Send user data to mixpanel
        mixpanel.track('Session End', {
          user_id: user.id,
          user_name: user.name,
          user_email: user.primaryEmail,
          user_role: userRole,
          timestamp: new Date().toISOString(),
          duration: sessionDuration
        })
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [user.id, user.name, user.primaryEmail, userRole])

  const { boot } = useIntercom()

  useEffect(() => {
    const messageDate = new Date()
    const messageCreatedAt = messageDate.getTime().toString()
    const pathName = location.pathname
    if (process.env.REACT_APP_INTERCOM_APP_ID) {
      boot({
        name: user?.name,
        email: user?.primaryEmail,
        createdAt: messageCreatedAt,
        customAttributes: {
          path_name: pathName,
          user_id: currentAccount.id,
          epid: currentAccount.employer.id,
          ep_code: currentAccount.employer.code
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Send user information to Rollbar
  useEffect(() => {
    if (currentAccount) {
      setUserAccount(currentAccount)
    }
  }, [currentAccount])

  // Loading state for feature flag
  if (flags === undefined) {
    return (
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh' }}
      >
        <CircularProgress size={40} />
      </Grid>
    )
  }

  const expanded = !open || onHover

  return (
    <AccessProvider>
      <AdminProvider>
        <SideNavigation
          isAdmin={isAdmin}
          basicUser={roles.includes(EmployerStaffAccountRole.BASIC_USER)}
          user={{
            username: user?.name,
            email,
            logout
          }}
          expanded={expanded}
          setOpen={setOpen}
          setOnHover={setOnHover}
          open={open}
        />

        {isImpersonator && <ImpersonatorBanner />}

        <AtsProvider>
          <AtsMockProvider>
            <Grid
              container
              sx={{ minHeight: 'calc(100vh - 64px)' }}
              id='site-container'
            >
              <Grid
                width={expanded ? 250 : 72}
                sx={{ transitionDuration: '0.3s' }}
              />

              <Grid
                xs
                sx={{
                  mx: 'auto'
                }}
              >
                <MicroServiceContainer />
                <Routes>
                  <Route path='' element={<Podium />} />
                  {isAdmin && flags && flags['enable-new-admin-portal'] && (
                    <>
                      <Route path='/admin-portal' element={<AdminPortal />} />
                      <Route
                        path='/admin-portal/ep/:epId'
                        element={<AdminPortal />}
                      />
                    </>
                  )}
                  <Route path='/organization-settings/*' element={<Podium />} />
                  <Route path='/bias-scanner' element={<BiasScanner />} />
                  {flags?.enable_laboratory && (
                    <Route path='/laboratory/*' element={<Laboratory />} />
                  )}
                  {flags?.enable_laboratory && (
                    <Route path='/laboratory/ai-ei/*' element={<AIEI />} />
                  )}
                  {ehiAccess ===
                    EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS && (
                    <Route path='/equity-index/*' element={<EquityIndex />} />
                  )}
                  <Route path='/action-plan/*' element={<ActionPlan />} />
                  <Route
                    path='/knowledge-center/*'
                    element={<KnowledgeCenter />}
                  />
                  {flags?.enable_fe_multi_orgs && (
                    <Route path='/groups/*' element={<Orbit />} />
                  )}
                  {flags?.enable_dashboard && (
                    <>
                      <Route path='/dashboard/*' element={<Pulsar />} />
                      <Route path='/hris/*' element={<Pulsar />} />
                    </>
                  )}
                  {isAtsEnabled && (
                    <>
                      <Route path='/ats' element={<AtsHome />} />
                      <Route path='/ats/job/:jobId' element={<AtsHome />} />
                    </>
                  )}
                  <Route path='/unauthorized/*' element={<NotAuthorized />} />

                  <Route path='/*' element={<NotFound />} />
                </Routes>
              </Grid>
            </Grid>
          </AtsMockProvider>
        </AtsProvider>
      </AdminProvider>
    </AccessProvider>
  )
}

export default ProtectedRoutes
