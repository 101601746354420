import React from 'react'
import {
  Toolbar,
  AppBar as MuiAppBar,
  Grid,
  IconButton,
  styled
} from '@mui/material'
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { Color } from '@mathison-inc/components'

import { useNavigate } from 'react-router-dom'
import {
  KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
  KeyboardDoubleArrowLeft as KeyboardDoubleArrowLeftIcon
} from '@mui/icons-material/'
import UserSection from './UserSection'

const drawerWidth = 250

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  boxShadow: 'none',
  border: '1px solid #E5E5E5',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))
const AppBar = ({
  expanded,
  open,
  setOpen,

  user,
  basicUser
}: any) => {
  const navigate = useNavigate()
  const redirectTo = (path: string) => {
    navigate(path)
  }

  return (
    <>
      <div style={{ height: '64px', width: '100%' }} />
      <StyledAppBar
        position='fixed'
        open={expanded}
        color='transparent'
        sx={{
          top: 0,
          backgroundColor: Color.white,
          zIndex: `1201 !important` as any,
          borderLeft: 'none',
          borderRight: 'none',
          borderTop: 'none'
        }}
      >
        <Toolbar
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            position: 'initial'
          }}
        >
          <Grid container paddingLeft={expanded ? 0 : '72px'}>
            <IconButton
              onClick={() => {
                setOpen(!open)
              }}
              sx={{
                position: 'absolute',
                left: expanded ? 26 : 94,
                top: 10,
                backgroundColor: Color.grey100
              }}
            >
              {open ? (
                <KeyboardDoubleArrowRightIcon />
              ) : (
                <KeyboardDoubleArrowLeftIcon />
              )}
            </IconButton>
          </Grid>

          <Grid
            container
            alignItems='center'
            justifyContent='flex-end'
            sx={{ maxWidth: 450, paddingRight: '24px' }}
          >
            <UserSection
              handleClick={(path: string) => {
                redirectTo(path)
              }}
              basicUser={basicUser}
              user={user}
            />
          </Grid>
        </Toolbar>
      </StyledAppBar>
    </>
  )
}
export default AppBar
