export type ImpersonatorStateCallback = (state: ImpersonatorState) => void
export type ImpersonatorState = {
  isImpersonating: boolean
  wasImpersonating: boolean
  employerCode: string | null
}

const ImpersonationCodeKey = 'impersonation-code'

let impersonationCode: string | null = null

const get = (): string | null => {
  if (!impersonationCode)
    impersonationCode = localStorage.getItem(ImpersonationCodeKey)

  return impersonationCode
}

const getAccessToken = () => {
  const tokens = localStorage.getItem('mathison-token-storage')
  const accessToken = JSON.parse(tokens ?? '{}')
  return accessToken
}

const set = (code: string) => {
  impersonationCode = code
  localStorage.setItem(ImpersonationCodeKey, code)
}

const clear = () => {
  localStorage.removeItem(ImpersonationCodeKey)
}

const isSet = () => {
  return !!get()
}

// Subscribe to impersonation state changes. It's important to note that the
// callback will only be called if the change was made in another tab, not the
// current one. This means that if a call to `set` was made this function will
// not trigger in the same tab, but it will in other open tabs.
const subscribe = (callback: ImpersonatorStateCallback) => {
  window.addEventListener('storage', ({ key, newValue, oldValue }) => {
    if (key === ImpersonationCodeKey)
      callback({
        isImpersonating: !!newValue,
        wasImpersonating: !!oldValue,
        employerCode: newValue
      } as ImpersonatorState)
  })
}

export { get, set, clear, isSet, subscribe, getAccessToken }
