import { Color } from 'components/SharedStyles'
import { Grid, Button as MuiButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Grid)({
  width: '100vw',
  backgroundColor: '#FBF2EB'
})
export const Button = styled(MuiButton)({
  color: Color.main,
  borderColor: Color.main,
  padding: '6px 12px'
})
export const EmployerName = styled('div')({
  fontWeight: 'bold',
  display: 'inline'
})
