import { AnalyticsBrowser } from '@segment/analytics-next'

// eslint-disable-next-line import/no-mutable-exports, no-var
export var analytics: AnalyticsBrowser | undefined

export const init = () => {
  if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
    analytics = AnalyticsBrowser.load({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY
    })
  }
}

export const identify = (id: string, props: any) => {
  analytics?.identify(id, props)
}
