/* eslint-disable no-console */
import React, { useMemo } from 'react'
import { Box, Typography, AlertColor, Stack } from '@mui/material'
import {
  Circle as StatusIcon,
  AccountCircle as UserIcon
} from '@mui/icons-material'
import {
  DataGridPro,
  GridValueGetterParams,
  GridRenderCellParams,
  GridEventListener,
  GridRowParams
} from '@mui/x-data-grid-pro'
import AlertErrorMessage from 'components/AlertErrorMessage'
import { UserProfile } from 'model/organization'
import {
  getFilteredUsersByStatus,
  getFormattedDate,
  getPrettyRoleName,
  truncateString
} from 'utils/adminData'
import FilterButton from 'components/FilterButton'
import { Color } from '@mathison-inc/components'
import SearchBar from 'components/SearchBar'
import SidePanel from 'components/SidePanel'
import LoadingIndicator from 'components/LoadingIndicator'
import { tableStyles } from './styles'
import EditUserSidePanel from './EditUserSidePanel'

interface UsersTableProps {
  users: UserProfile[]
  error: any
  isLoading: boolean
  epId: string
  getSelectedUser: (epId: string, userEmail: string) => void
  selectedUser: UserProfile | undefined | null
  selectedUserError: any
  selectedUserIsLoading: boolean
  submitRoleChanges: (epId: string, userId: string, roles: string[]) => void
  roleChangeSuccess: boolean
  roleChangeError: boolean
  roleChangeUnauthorized: boolean
  deactivateUser: (userId: string, epId: string) => void
  deactivateUserSuccess: boolean
  deactivateUserError: boolean
  deactivateUserUnauthorized: boolean
  activateUser: (email: string, epId: string) => void
  activateUserSuccess: boolean
  activateUserError: boolean
  activateUserUnauthorized: boolean
}

const UsersTable = ({
  users,
  error,
  isLoading,
  epId,
  getSelectedUser,
  selectedUser,
  selectedUserError,
  selectedUserIsLoading,
  submitRoleChanges,
  roleChangeError,
  roleChangeSuccess,
  roleChangeUnauthorized,
  deactivateUser,
  deactivateUserError,
  deactivateUserSuccess,
  deactivateUserUnauthorized,
  activateUser,
  activateUserError,
  activateUserSuccess,
  activateUserUnauthorized
}: UsersTableProps) => {
  const [search, setSearch] = React.useState('')
  const [filterValue, setFilterValue] = React.useState('Active')
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [openAlert, setOpenAlert] = React.useState<boolean>(false)
  const [severity, setSeverity] = React.useState<AlertColor>('error')
  const [filteredUsers, setFilteredUsers] = React.useState<UserProfile[]>(users)
  const editPanelRef = React.useRef<any>(null)

  React.useEffect(() => {
    if (users && users.length > 0) {
      setFilteredUsers(getFilteredUsersByStatus(users, filterValue))
    }
  }, [users, filterValue])

  const handleOnChange = (e: any) => {
    setSearch(e.target.value)
  }

  const clearSearch = () => {
    setSearch('')
  }

  const searchFilteredUsers = (usersArray: UserProfile[]) => {
    return usersArray.filter((u: UserProfile) =>
      search.toLowerCase() === ''
        ? u
        : u.email.toLowerCase().includes(search.toLowerCase()) ||
          u.fullName?.toLowerCase().includes(search.toLowerCase())
    )
  }

  const closeEditUserPanel = (onExitedCallback?: any) => {
    editPanelRef.current?.closeSidePanel(onExitedCallback)
  }

  const handleRowClick: GridEventListener<'rowClick'> = (
    params: GridRowParams
  ) => {
    setOpenAlert(false)
    getSelectedUser(epId, params?.row?.email)
    editPanelRef.current?.openSidePanel()
  }

  useMemo(() => {
    if (selectedUserError) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
      }, 5000)
      setErrorMessage('There was an error retrieving the user')
      setSeverity('error')
    }
  }, [selectedUserError])

  useMemo(() => {
    if (deactivateUserSuccess) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
      }, 5000)
      setErrorMessage('User has been deactivated')
      setSeverity('success')
    }
  }, [deactivateUserSuccess])

  useMemo(() => {
    if (deactivateUserError) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
      }, 5000)
      setErrorMessage('There was an error deactivating the user')
      setSeverity('error')
    }
  }, [deactivateUserError])

  useMemo(() => {
    if (deactivateUserUnauthorized) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
      }, 5000)
      setErrorMessage('You are not authorized to deactivate this user')
      setSeverity('warning')
    }
  }, [deactivateUserUnauthorized])

  useMemo(() => {
    if (activateUserSuccess) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
      }, 5000)
      setErrorMessage('User has been reactivated')
      setSeverity('success')
    }
  }, [activateUserSuccess])

  useMemo(() => {
    if (activateUserError) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
      }, 5000)
      setErrorMessage('There was an error reactivating the user')
      setSeverity('error')
    }
  }, [activateUserError])

  useMemo(() => {
    if (activateUserUnauthorized) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
      }, 5000)
      setErrorMessage('You are not authorized to reactivate this user')
      setSeverity('warning')
    }
  }, [activateUserUnauthorized])

  useMemo(() => {
    if (roleChangeSuccess) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
      }, 5000)
      setErrorMessage('User permissions have been updated')
      setSeverity('success')
    }
  }, [roleChangeSuccess])

  useMemo(() => {
    if (roleChangeError) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
      }, 5000)
      setErrorMessage('There was an error changing the roles for the user')
      setSeverity('error')
    }
  }, [roleChangeError])

  useMemo(() => {
    if (roleChangeUnauthorized) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
      }, 5000)
      setErrorMessage(
        'You are not authorized to change the permissions for this user'
      )
      setSeverity('warning')
    }
  }, [roleChangeUnauthorized])

  return (
    <Box>
      <Box
        sx={{
          padding: '20px 0'
        }}
      >
        <Stack direction='row' alignItems='left'>
          <UserIcon
            sx={{
              fontSize: '25px',
              color: '#3148D3',
              marginTop: '3px',
              marginRight: '5px'
            }}
          />
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 700
            }}
          >
            User Management
          </Typography>
        </Stack>
      </Box>
      <Box>
        <AlertErrorMessage
          severity={severity}
          message={errorMessage}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
        />
      </Box>
      <Stack
        padding={4}
        borderRadius={2}
        border={`1px solid ${Color.grey300}`}
        sx={{ backgroundColor: 'white' }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '20px 5px'
          }}
        >
          <SearchBar
            value={search}
            placeHolderText='Search users by name or email'
            onChange={handleOnChange}
            clearSearch={clearSearch}
          />
          <FilterButton
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            dataLabel='Status'
            options={['All', 'Active', 'Inactive', 'Pending']}
          />
        </Box>
        <Box>
          {isLoading || (!users && !error && filteredUsers.length === 0) ? (
            <LoadingIndicator circleSize={50} />
          ) : (
            <Box>
              {error ? (
                <Box>
                  <Typography>
                    There was an error retrieving the users for this EP.
                  </Typography>
                  <pre>{JSON.stringify(error, null, 2)}</pre>
                </Box>
              ) : (
                <Box>
                  {filteredUsers && filteredUsers.length > 0 ? (
                    <Box>
                      <DataGridPro
                        sx={tableStyles}
                        disableColumnMenu
                        pagination
                        getRowId={(row: any) => row?.id}
                        pageSizeOptions={[5, 10, 15, 50]}
                        initialState={{
                          pagination: {
                            paginationModel: { pageSize: 10, page: 0 }
                          }
                        }}
                        rows={searchFilteredUsers(filteredUsers)}
                        onRowClick={handleRowClick}
                        columns={[
                          {
                            field: 'email',
                            headerName: 'Email',
                            flex: 1,
                            maxWidth: 300,
                            headerClassName: 'headers',
                            valueGetter: (params: GridValueGetterParams) => {
                              return params?.row?.email
                            },
                            renderCell: (params: GridRenderCellParams) => {
                              return (
                                <Typography
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0
                                    },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 'normal',
                                    fontSize: '14px'
                                  }}
                                >
                                  {params?.row?.email}
                                </Typography>
                              )
                            }
                          },
                          {
                            field: 'name',
                            headerName: 'Name',
                            flex: 1,
                            headerClassName: 'headers',
                            valueGetter: (params: GridValueGetterParams) => {
                              return params?.row?.fullName
                            },
                            renderCell: (params: GridRenderCellParams) => {
                              return (
                                <Typography
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0
                                    },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '14px'
                                  }}
                                >
                                  {truncateString(params?.row?.fullName, 35)}
                                </Typography>
                              )
                            }
                          },
                          {
                            field: 'role',
                            headerName: 'Role',
                            flex: 1,
                            headerClassName: 'headers',
                            valueGetter: (params: GridValueGetterParams) => {
                              return params?.row?.roles[0]
                            },
                            renderCell: (params: GridRenderCellParams) => {
                              return (
                                <Typography
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0
                                    },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 'normal',
                                    fontSize: '14px'
                                  }}
                                >
                                  {getPrettyRoleName(params?.row?.roles[0])}
                                </Typography>
                              )
                            }
                          },
                          {
                            field: 'eiAccess',
                            headerName: 'EI Access',
                            flex: 1,
                            headerClassName: 'headers',
                            valueGetter: (params: GridValueGetterParams) => {
                              return params?.row?.roles[2]
                            },
                            renderCell: (params: GridRenderCellParams) => {
                              return (
                                <Typography
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0
                                    },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 'normal',
                                    fontSize: '14px'
                                  }}
                                >
                                  {getPrettyRoleName(params?.row?.roles[2])}
                                </Typography>
                              )
                            }
                          },
                          {
                            field: 'status',
                            headerName: 'Status',
                            flex: 1,
                            maxWidth: 100,
                            headerClassName: 'headers',
                            valueGetter: (params: GridValueGetterParams) => {
                              return params?.row?.status
                            },
                            renderCell: (params: GridRenderCellParams) => {
                              return (
                                <Stack
                                  alignItems='center'
                                  direction='row'
                                  gap={1}
                                >
                                  <StatusIcon
                                    sx={{
                                      fontSize: '9px',
                                      color:
                                        params?.row?.status === 'ACTIVE'
                                          ? '#5DEFA9'
                                          : '#F12757'
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      '&:last-child td, &:last-child th': {
                                        border: 0
                                      },
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      fontWeight: 700,
                                      textTransform: 'uppercase',
                                      fontSize: '10px'
                                    }}
                                  >
                                    {params?.row?.status}
                                  </Typography>
                                </Stack>
                              )
                            }
                          },
                          {
                            field: 'updated',
                            headerName: 'Updated',
                            flex: 1,
                            maxWidth: 100,
                            headerClassName: 'headers',
                            valueGetter: (params: GridValueGetterParams) => {
                              return params?.row?.updatedAt
                            },
                            renderCell: (params: GridRenderCellParams) => {
                              return (
                                <Typography
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0
                                    },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 'normal',
                                    textTransform: 'uppercase',
                                    fontSize: '14px'
                                  }}
                                >
                                  {getFormattedDate(params?.row?.updatedAt)}
                                </Typography>
                              )
                            }
                          }
                        ]}
                      />
                      <SidePanel ref={editPanelRef}>
                        <EditUserSidePanel
                          user={selectedUser}
                          epId={epId}
                          submitRoleChanges={submitRoleChanges}
                          deactivateUser={deactivateUser}
                          activateUser={activateUser}
                          isLoading={selectedUserIsLoading}
                          error={selectedUserError}
                          closeSidePanel={closeEditUserPanel}
                        />
                      </SidePanel>
                    </Box>
                  ) : (
                    <Box>
                      <Typography>
                        There are currently no users for this EP
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  )
}
export default UsersTable
