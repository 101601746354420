/* eslint-disable no-console */
import React from 'react'
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Box
} from '@mui/material'

interface FilterButtonProps {
  dataLabel: string
  options: string[]
  setRoleValue: React.Dispatch<React.SetStateAction<string>>
  role: string
}

const RolesButton = ({
  dataLabel,
  options,
  setRoleValue,
  role
}: FilterButtonProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    setRoleValue(event.target.value as string)
  }

  return (
    <Box sx={{ margin: '0 10px', backgroundColor: '#F7F7F7' }}>
      <FormControl
        fullWidth
        sx={{
          minWidth: '150px',
          backgroundColor: '##F7F7F7',
          border: '1px solid #E5E5E5',
          boxShadow: 'none'
        }}
      >
        <InputLabel id='role-select-label'>{dataLabel}</InputLabel>
        <Select
          labelId='role-select-label'
          id='role-select'
          value={role}
          label={dataLabel}
          onChange={handleChange}
        >
          {options.map(option => (
            <MenuItem value={option}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
export default RolesButton
