import { useContext, useEffect } from 'react'
import { loadMicroApp } from 'qiankun'
import { NotificationContext } from 'context/NotificationContext'
import { AccountContext } from 'context/AccountContext'
import Client from 'utils/createApolloMaestroClient'
import { AccessContext } from 'context/AccessContext'
import { namoraEntry } from 'utils/entryProvider'
import { FeatureFlagsContext } from 'context/FeatureFlagsContext'

const ActionPlan = () => {
  const { displayNotification } = useContext(NotificationContext)
  const {
    data: { currentAccount }
  } = useContext(AccountContext)
  const { hasAccessToViewRecommendations, hasAccessToViewDataViz } =
    useContext(AccessContext)
  const { flags } = useContext(FeatureFlagsContext)
  useEffect(() => {
    const namoraApp = loadMicroApp({
      name: 'namora',
      entry: namoraEntry,
      container: '#namora-root',
      props: {
        displayNotification,
        maestroClient: Client(),
        currentAccount,
        hasAccessToViewRecommendations,
        hasAccessToViewDataViz,
        flags
      }
    })

    return () => {
      if (namoraApp.getStatus() === 'MOUNTED') {
        namoraApp.unmount()
      }
    }
    // eslint-disable-next-line
  }, [])

  return null
}

export default ActionPlan
