/* eslint-disable no-console */
import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Box, Typography, Grid, Stack, Tooltip } from '@mui/material'
import { Info as InfoIcon } from '@mui/icons-material'
import { EeocPieData, EeocBarData, Requisition } from 'model/atsMock'
import { Serie } from '@nivo/line'
import { createHorizontalBarChart, createPieChart } from 'hooks/useJobsData'
import { AtsMockContext } from 'context/AtsMockContext'
import LoadingIndicator from 'components/LoadingIndicator'
import FilterButton from 'components/FilterButton'
import AtsPieChart from 'components/AtsPieChart'
import HorizontalBarChart from 'components/HorizontalBarChart'
import AtsLineChart from 'components/AtsLineChart'
import { filterDataByGenderEthnicity } from './mockHooks'

interface MockJobPageProps {
  job: Requisition
  isLoading: boolean
  error: string
}

const MockJobPage = ({ job, isLoading, error }: MockJobPageProps) => {
  const [pieChartData, setPieChartData] = useState<EeocPieData>(
    {} as EeocPieData
  )

  const [horizontalData, setHorizontalData] = useState<EeocBarData>(
    {} as EeocBarData
  )

  const { jobResponse, jobCumulativeResponse } = useContext(AtsMockContext)

  console.log(JSON.stringify(jobCumulativeResponse))

  // Filter state for ATS Current Totals by stage
  const [filterCurrentGenderValue, setFilterCurrentGenderValue] = useState('')
  const [filteredCurrentGender, setFilteredCurrentGender] = useState<Serie[]>(
    []
  )

  const [filterCurrentEthnicityValue, setFilterCurrentEthnicityValue] =
    useState('')
  const [filteredCurrentEthnicity, setFilteredCurrentEthnicity] = useState<
    Serie[]
  >([])

  // Filter state for ATS Funnel Chart
  const [filterFunnelGenderValue, setFilterFunnelGenderValue] = useState('')
  const [filteredGenderFunnel, setFilteredGenderFunnel] = useState<Serie[]>(
    jobCumulativeResponse
  )

  const [filterFunnelEthnicityValue, setFilterFunnelEthnicityValue] =
    useState('')
  const [filteredEthnicityFunnel, setFilteredEthnicityFunnel] = useState<
    Serie[]
  >(jobCumulativeResponse)

  const getFilteredApplicantsByGroup = (
    allCandidates: Serie[],
    group: string
  ): Serie[] => {
    if (!group || group === '' || group === 'All') return allCandidates
    const filterParam = group.toString().toLowerCase()
    return allCandidates.filter(
      (candidate: Serie) =>
        candidate.type.toString().toLowerCase() === filterParam ||
        candidate.id.toString().toLowerCase() === filterParam
    )
  }

  useEffect(() => {
    if (job && job.analytics && job.analytics.applicants) {
      const pData: EeocPieData = createPieChart(job.analytics.applicants, true)
      setPieChartData(pData)
      const hData: EeocBarData = createHorizontalBarChart(
        job.analytics.applicants,
        true
      )
      setHorizontalData(hData)
    }
  }, [job])

  useMemo(() => {
    const genderJobResponse = filterDataByGenderEthnicity(jobResponse, 'GENDER')
    setFilteredCurrentGender(
      getFilteredApplicantsByGroup(genderJobResponse, filterCurrentGenderValue)
    )
  }, [filterCurrentGenderValue, jobResponse])

  useMemo(() => {
    const ethnicityJobResponse = filterDataByGenderEthnicity(
      jobResponse,
      'RACE'
    )
    setFilteredCurrentEthnicity(
      getFilteredApplicantsByGroup(
        ethnicityJobResponse,
        filterCurrentEthnicityValue
      )
    )
  }, [filterCurrentEthnicityValue, jobResponse])

  useMemo(() => {
    const genderJobCumulativeResponse = filterDataByGenderEthnicity(
      jobCumulativeResponse,
      'GENDER'
    )
    setFilteredGenderFunnel(
      getFilteredApplicantsByGroup(
        genderJobCumulativeResponse,
        filterFunnelGenderValue
      )
    )
  }, [filterFunnelGenderValue, jobCumulativeResponse])

  useMemo(() => {
    const ethnicityJobCumulativeResponse = filterDataByGenderEthnicity(
      jobCumulativeResponse,
      'RACE'
    )
    setFilteredEthnicityFunnel(
      getFilteredApplicantsByGroup(
        ethnicityJobCumulativeResponse,
        filterFunnelEthnicityValue
      )
    )
  }, [filterFunnelEthnicityValue, jobCumulativeResponse])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
      {job && job.id ? (
        <Box>
          <Typography variant='h2'>{job.title} - Applicant Data</Typography>
          <Box
            sx={{
              margin: '50px 0 230px 0',
              width: '100%',
              height: '500px',
              padding: '0px'
            }}
          >
            <Box
              sx={{
                marginBottom: '20px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Stack direction='row' spacing={1}>
                <Typography variant='h5'>
                  Gender: Applicant Funnel Chart (Cumulative Line Chart)
                </Typography>
                <Tooltip
                  sx={{
                    paddingTop: '4px',
                    fontSize: '16px',
                    '&:hover': { cursor: 'pointer' }
                  }}
                  title='Funnel chart showing total applicants received per stage, for the given date range. For example, if a person is currently in stage 3, they would be counted in stages 1, 2 and 3.'
                  placement='right-start'
                >
                  <InfoIcon />
                </Tooltip>
              </Stack>
            </Box>
            <Box sx={{ margin: '10px 0' }}>
              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' spacing={1}>
                  <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                    September 10, 2024 - November 8, 2024
                  </Typography>
                  <Tooltip
                    sx={{
                      paddingTop: '4px',
                      fontSize: '16px',
                      '&:hover': { cursor: 'pointer' }
                    }}
                    title='Default date range from the creation of the job posting to the last synced date.'
                    placement='right-start'
                  >
                    <InfoIcon />
                  </Tooltip>
                </Stack>

                <Stack direction='row' spacing={1}>
                  <FilterButton
                    filterValue={filterFunnelGenderValue}
                    setFilterValue={setFilterFunnelGenderValue}
                    dataLabel='Demographic'
                    options={['All', 'TOTAL', 'men', 'women', 'other GENDER']}
                  />
                  {/* <Box
                    sx={{
                      width: '250px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px dashed #191919',
                      borderRadius: '3px'
                    }}
                  >
                    Date Picker
                  </Box> */}
                </Stack>
              </Stack>
            </Box>
            <Box
              sx={{
                height: 'inherit',
                padding: '20px',
                backgroundColor: 'white',
                borderRadius: '8px'
              }}
            >
              <AtsLineChart data={filteredGenderFunnel} />
            </Box>
          </Box>
          <Box
            sx={{
              margin: '50px 0 230px 0',
              width: '100%',
              height: '500px',
              padding: '0px'
            }}
          >
            <Box
              sx={{
                marginBottom: '20px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Stack direction='row' spacing={1}>
                <Typography variant='h5'>
                  Ethnicity: Applicant Funnel Chart (Cumulative Line Chart)
                </Typography>
                <Tooltip
                  sx={{
                    paddingTop: '4px',
                    fontSize: '16px',
                    '&:hover': { cursor: 'pointer' }
                  }}
                  title='Funnel chart showing total applicants received per stage, for the given date range. For example, if a person is currently in stage 3, they would be counted in stages 1, 2 and 3.'
                  placement='right-start'
                >
                  <InfoIcon />
                </Tooltip>
              </Stack>
            </Box>
            <Box sx={{ margin: '10px 0' }}>
              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' spacing={1}>
                  <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                    September 10, 2024 - November 8, 2024
                  </Typography>
                  <Tooltip
                    sx={{
                      paddingTop: '4px',
                      fontSize: '16px',
                      '&:hover': { cursor: 'pointer' }
                    }}
                    title='Default date range from the creation of the job posting to the last synced date.'
                    placement='right-start'
                  >
                    <InfoIcon />
                  </Tooltip>
                </Stack>

                <Stack direction='row' spacing={1}>
                  <FilterButton
                    filterValue={filterFunnelEthnicityValue}
                    setFilterValue={setFilterFunnelEthnicityValue}
                    dataLabel='Demographic'
                    options={[
                      'All',
                      'TOTAL',
                      'other',
                      'multiracial',
                      'indegenous',
                      'asian',
                      'latine',
                      'black',
                      'white'
                    ]}
                  />
                  {/* <Box
                    sx={{
                      width: '250px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px dashed #191919',
                      borderRadius: '3px'
                    }}
                  >
                    Date Picker
                  </Box> */}
                </Stack>
              </Stack>
            </Box>
            <Box
              sx={{
                height: 'inherit',
                padding: '20px',
                backgroundColor: 'white',
                borderRadius: '8px'
              }}
            >
              <AtsLineChart data={filteredEthnicityFunnel} />
            </Box>
          </Box>
          <Box
            sx={{
              margin: '50px 0 230px 0',
              width: '100%',
              height: '500px',
              padding: '0px'
            }}
          >
            <Box sx={{ marginBottom: '20px' }}>
              <Stack direction='row' spacing={1}>
                <Typography variant='h5'>
                  Gender: Current Active Applicant Totals By Hiring Stage
                </Typography>
                <Tooltip
                  sx={{
                    paddingTop: '4px',
                    fontSize: '16px',
                    '&:hover': { cursor: 'pointer' }
                  }}
                  title='The total active applicants currently in each stage. The default view is a snapshot of the last synced date.'
                  placement='right-start'
                >
                  <InfoIcon />
                </Tooltip>
              </Stack>
            </Box>
            <Box sx={{ margin: '10px 0' }}>
              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' spacing={1}>
                  <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Last Synced Date: November 8, 2024
                  </Typography>
                  <Tooltip
                    sx={{
                      paddingTop: '4px',
                      fontSize: '16px',
                      '&:hover': { cursor: 'pointer' }
                    }}
                    title='The default view is the snapshot for the last synced date.'
                    placement='right-start'
                  >
                    <InfoIcon />
                  </Tooltip>
                </Stack>
                <Stack direction='row' spacing={1}>
                  <FilterButton
                    filterValue={filterCurrentGenderValue}
                    setFilterValue={setFilterCurrentGenderValue}
                    dataLabel='Demographic'
                    options={['All', 'TOTAL', 'men', 'women', 'other GENDER']}
                  />
                  {/* <Box
                    sx={{
                      width: '250px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px dashed #191919',
                      borderRadius: '3px'
                    }}
                  >
                    Date Picker
                  </Box> */}
                </Stack>
              </Stack>
            </Box>
            <Box
              sx={{
                height: 'inherit',
                padding: '20px',
                backgroundColor: 'white',
                borderRadius: '8px'
              }}
            >
              <AtsLineChart data={filteredCurrentGender} />
            </Box>
          </Box>
          <Box
            sx={{
              margin: '50px 0 230px 0',
              width: '100%',
              height: '500px',
              padding: '0px'
            }}
          >
            <Box sx={{ marginBottom: '20px' }}>
              <Stack direction='row' spacing={1}>
                <Typography variant='h5'>
                  Ethnicity: Current Active Applicant Totals By Hiring Stage
                </Typography>
                <Tooltip
                  sx={{
                    paddingTop: '4px',
                    fontSize: '16px',
                    '&:hover': { cursor: 'pointer' }
                  }}
                  title='The total active applicants currently in each stage. The default view is a snapshot of the last synced date.'
                  placement='right-start'
                >
                  <InfoIcon />
                </Tooltip>
              </Stack>
            </Box>
            <Box sx={{ margin: '10px 0' }}>
              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' spacing={1}>
                  <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                    Last Synced Date: November 8, 2024
                  </Typography>
                  <Tooltip
                    sx={{
                      paddingTop: '4px',
                      fontSize: '16px',
                      '&:hover': { cursor: 'pointer' }
                    }}
                    title='The default view is the snapshot for the last synced date.'
                    placement='right-start'
                  >
                    <InfoIcon />
                  </Tooltip>
                </Stack>
                <Stack direction='row' spacing={1}>
                  <FilterButton
                    filterValue={filterCurrentGenderValue}
                    setFilterValue={setFilterCurrentEthnicityValue}
                    dataLabel='Demographic'
                    options={[
                      'All',
                      'TOTAL',
                      'other',
                      'multiracial',
                      'indegenous',
                      'asian',
                      'latine',
                      'black',
                      'white'
                    ]}
                  />
                  {/* <Box
                    sx={{
                      width: '250px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px dashed #191919',
                      borderRadius: '3px'
                    }}
                  >
                    Date Picker
                  </Box> */}
                </Stack>
              </Stack>
            </Box>
            <Box
              sx={{
                height: 'inherit',
                padding: '20px',
                backgroundColor: 'white',
                borderRadius: '8px'
              }}
            >
              <AtsLineChart data={filteredCurrentEthnicity} />
            </Box>
          </Box>
          <Stack direction='row' spacing={3}>
            <Typography variant='h5'>Total Applicant Demographics</Typography>
            <Tooltip
              sx={{
                paddingTop: '4px',
                fontSize: '16px',
                '&:hover': { cursor: 'pointer' }
              }}
              title='Total makeup of applicants by EEOC data (gender, race, disability, lgbtqia+, and veteran status).'
              placement='right-start'
            >
              <InfoIcon />
            </Tooltip>
          </Stack>
          <Box sx={{ padding: '20px 0', marginTop: '20px' }}>
            <Box
              sx={{
                backgroundColor: 'white',
                border: '1px solid rgb(229, 229, 229)',
                borderRadius: '8px',
                padding: '40px'
              }}
            >
              <Grid container sx={{ marginBottom: '100px' }}>
                {pieChartData.gender && (
                  <Grid item xs={12} md={6}>
                    <AtsPieChart
                      title='Gender'
                      data={pieChartData.gender || []}
                    />
                  </Grid>
                )}
                {pieChartData.ethnicty && (
                  <Grid item xs={12} md={6}>
                    <AtsPieChart
                      title='Ethnicity'
                      data={pieChartData.ethnicty || []}
                    />
                  </Grid>
                )}
              </Grid>
              {horizontalData.disability && (
                <Box sx={{ margin: '20px' }}>
                  <HorizontalBarChart
                    title='Disability'
                    data={horizontalData.disability}
                  />
                </Box>
              )}
              {horizontalData.lgbtqia && (
                <Box sx={{ margin: '20px' }}>
                  <HorizontalBarChart
                    title='LGBTQIA+'
                    data={horizontalData.lgbtqia}
                  />
                </Box>
              )}
              {horizontalData.veteran && (
                <Box sx={{ margin: '20px' }}>
                  <HorizontalBarChart
                    title='Veteran'
                    data={horizontalData.veteran}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          {isLoading ? (
            <LoadingIndicator containerHeight={500} circleSize={100} />
          ) : (
            <Box>
              <Typography>Oops... Something went wrong</Typography>
              {error && <pre>{error}</pre>}
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
export default MockJobPage
