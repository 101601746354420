import { useInviteUsers } from 'hooks/useInviteUsers'
import React from 'react'

type InviteUserContextType = {
  inviteUsers: (emails: string[], epId: string) => any
  inviteSuccess: boolean
  inviteError: boolean
  inviteUnauthorized: boolean
  setInviteSuccess: React.Dispatch<React.SetStateAction<boolean>>
  setInviteError: React.Dispatch<React.SetStateAction<boolean>>
  setInviteUnauthorized: React.Dispatch<React.SetStateAction<boolean>>
}

export const INITIAL_DATA: InviteUserContextType = {
  inviteUsers: () => {},
  inviteSuccess: false,
  inviteError: false,
  inviteUnauthorized: false,
  setInviteSuccess: () => false,
  setInviteError: () => false,
  setInviteUnauthorized: () => false
}

export const InviteUserContext =
  React.createContext<InviteUserContextType>(INITIAL_DATA)

export const InviteUserProvider = ({ children }: any): JSX.Element => {
  const {
    inviteUsers,
    inviteSuccess,
    inviteError,
    inviteUnauthorized,
    setInviteSuccess,
    setInviteError,
    setInviteUnauthorized
  } = useInviteUsers()

  const value = React.useMemo(
    () => ({
      inviteUsers,
      inviteSuccess,
      inviteError,
      inviteUnauthorized,
      setInviteSuccess,
      setInviteError,
      setInviteUnauthorized
    }),
    [
      inviteUsers,
      inviteSuccess,
      inviteError,
      inviteUnauthorized,
      setInviteSuccess,
      setInviteError,
      setInviteUnauthorized
    ]
  )

  return (
    <InviteUserContext.Provider value={value}>
      {children}
    </InviteUserContext.Provider>
  )
}
