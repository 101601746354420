import React, { useState, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import {
  DataGridPro,
  GridEventListener,
  GridRowParams
} from '@mui/x-data-grid-pro'
import { useNavigate } from 'react-router-dom'
import SearchBar from 'components/SearchBar'
import FilterButton from 'components/FilterButton'
import { Job } from 'model/ats'
import { tableStyles } from './config/styles'
import { jobColumns } from './config'

interface JobsPageProps {
  jobs: Job[]
}

const JobsPage = ({ jobs }: JobsPageProps) => {
  const navigate = useNavigate()

  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('OPEN')
  const [filteredJobs, setFilteredJobs] = useState<Job[]>(jobs)

  const handleRowClick: GridEventListener<'rowClick'> = (
    params: GridRowParams
  ) => {
    const jobId = params?.row?.id
    if (jobId) {
      navigate(`/ats/job/${jobId}`)
    }
  }

  const handleOnChange = (e: any) => {
    setSearch(e.target.value)
  }

  const clearSearch = () => {
    setSearch('')
  }

  const searchFilteredJobs = (jobsArray: Job[]) => {
    return jobsArray.filter((job: Job) =>
      search.toLowerCase() === ''
        ? job
        : job.name.toLowerCase().includes(search.toLowerCase()) ||
          job.code.toLowerCase().includes(search.toLowerCase())
    )
  }

  const getFilteredJobs = (allJobs: Job[], reqStatus: string) => {
    const result = allJobs.filter(job => {
      return !reqStatus || reqStatus === 'All' || job.status === reqStatus
    })
    return result
  }

  useMemo(() => {
    setFilteredJobs(getFilteredJobs(jobs, status))
  }, [status, jobs])

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='h2' sx={{ textAlign: 'left', marginBottom: '40px' }}>
        Requisitions
      </Typography>
      <Box
        sx={{
          backgroundColor: 'white',
          border: '1px solid rgb(229, 229, 229)',
          borderRadius: '8px',
          padding: '40px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '20px 5px'
          }}
        >
          <SearchBar
            value={search}
            placeHolderText='Search positions by name, id, or job code'
            onChange={handleOnChange}
            clearSearch={clearSearch}
          />
          <FilterButton
            filterValue={status}
            setFilterValue={setStatus}
            dataLabel='Status'
            options={['All', 'OPEN', 'DRAFT', 'PENDING', 'ARCHIVED', 'CLOSED']}
          />
        </Box>
        <DataGridPro
          sx={tableStyles}
          rows={searchFilteredJobs(filteredJobs)}
          columns={jobColumns}
          pagination
          disableColumnMenu
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              }
            }
          }}
          pageSizeOptions={[5, 10, 20]}
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  )
}
export default JobsPage
