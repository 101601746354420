/* eslint-disable no-console */
import { useContext, useEffect, useReducer, useState } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import {
  ACTIVATE_EMPLOYER_STAFF_ACCOUNTS,
  CHANGE_EMPLOYER_STAFF_ACCOUNT_PERMISSIONS,
  DEACTIVE_EMPLOYER_STAFF_ACCOUNTS,
  EMPLOYER_STAFF_ACCOUNTS,
  INVITE_EMPLOYER_STAFF_ACCOUNTS,
  RESEND_EMPLOYER_STAFF_ACCOUNT_INVITE
} from 'context/UserAdminContext/gql'
import { AlertContext } from 'context/AlertContext'
import { PaginationState } from 'model/users'

export const INITIAL_STAFF_MEMBER_FILTER = {
  roles: [],
  name: '',
  statuses: ['ACTIVE', 'PENDING']
}

export const INITIA_PAGINATION_STATE: PaginationState = {
  currentPage: 0,
  rowsPerPage: 10,
  cursor: undefined
}

const useUserAdmin = () => {
  const [deactivateUserSuccess, setDeactivateUserSuccess] =
    useState<boolean>(false)
  const [deactivateUserError, setDeactivateUserError] = useState<boolean>(false)
  const [deactivateUserUnauthorized, setDeactivateUserUnauthorized] =
    useState<boolean>(false)
  const [activateUserSuccess, setActivateUserSuccess] = useState<boolean>(false)
  const [activateUserError, setActivateUserError] = useState<boolean>(false)
  const [activateUserUnauthorized, setActivateUserUnauthorized] =
    useState<boolean>(false)
  const [changePermissionSuccess, setChangePermissionSuccess] =
    useState<boolean>(false)
  const [changePermissionError, setChangePermissionError] =
    useState<boolean>(false)
  const [changePermissionUnauthorized, setChangePermissionUnauthorized] =
    useState<boolean>(false)
  const { displayAlertMessage } = useContext(AlertContext)
  const [filterState, dispatchFilter] = useReducer(
    (formState: any, action: any) => {
      return {
        ...formState,
        [action.index]: action.value
      }
    },
    INITIAL_STAFF_MEMBER_FILTER
  )
  const [paginationState, dispatchPaginationState] = useReducer(
    (state: PaginationState, action: any) => {
      switch (action.type) {
        case 'rowsPerPageChange':
          return {
            ...state,
            rowsPerPage: action.value,
            currentPage: 0,
            cursor: undefined
          }
        case 'reset':
          return {
            ...state,
            cursor: undefined,
            currentPage: 0
          }
        default:
          return { ...state, [action.index]: action.value }
      }
    },
    INITIA_PAGINATION_STATE
  )

  const {
    data: { employerStaffAccounts } = {
      employerStaffAccounts: { edges: [] }
    },
    loading,
    fetchMore,
    refetch
  } = useQuery(EMPLOYER_STAFF_ACCOUNTS, {
    variables: {
      first: paginationState.rowsPerPage,
      searchTerm: filterState.name,
      roles: filterState.roles,
      statuses: filterState.statuses
    }
  })

  const [
    getEmployerUsers,
    {
      data: getEmployerUsersResponse,
      error: getEmployerUsersError,
      loading: getEmployerUsersIsLoading,
      refetch: refetchUsers
    }
  ] = useLazyQuery(EMPLOYER_STAFF_ACCOUNTS, { fetchPolicy: 'no-cache' })

  const [
    getEmployerUser,
    {
      data: selectedUserData,
      error: selectedUserError,
      loading: selectedUserIsLoading
    }
  ] = useLazyQuery(EMPLOYER_STAFF_ACCOUNTS, { fetchPolicy: 'no-cache' })

  useEffect(() => {
    refetch({
      first: paginationState.rowsPerPage,
      searchTerm: filterState.name,
      roles: filterState.roles,
      statuses: filterState.statuses
    })
    dispatchPaginationState({
      type: 'reset'
    })
  }, [
    filterState.name,
    filterState.roles,
    filterState.statuses,
    paginationState.rowsPerPage,
    refetch
  ])

  useEffect(() => {
    if (!employerStaffAccounts.edges.length) return

    const { endCursor } = employerStaffAccounts.pageInfo
    const { currentPage, rowsPerPage } = paginationState

    if (currentPage * rowsPerPage >= employerStaffAccounts.edges.length) {
      fetchMore({
        variables: {
          after: endCursor
        }
      })
    }
  }, [
    employerStaffAccounts,
    employerStaffAccounts.edges.length,
    employerStaffAccounts.length,
    employerStaffAccounts.pageInfo,
    fetchMore,
    paginationState,
    paginationState.currentPage,
    paginationState.rowsPerPage
  ])

  const [handleChangePermission] = useMutation(
    CHANGE_EMPLOYER_STAFF_ACCOUNT_PERMISSIONS
  )

  const [resendInvite] = useMutation(RESEND_EMPLOYER_STAFF_ACCOUNT_INVITE, {
    onCompleted: () => {
      displayAlertMessage('invite resent')
    }
  })

  const [inviteStaff] = useMutation(INVITE_EMPLOYER_STAFF_ACCOUNTS, {})

  const [handleDeactivateStaff] = useMutation(
    DEACTIVE_EMPLOYER_STAFF_ACCOUNTS,
    {}
  )
  const [handleActivateStaff] = useMutation(
    ACTIVATE_EMPLOYER_STAFF_ACCOUNTS,
    {}
  )

  const changePermission = async (
    userId: string,
    epId: string,
    roles: string[]
  ) => {
    setChangePermissionSuccess(false)
    setChangePermissionError(false)
    setChangePermissionUnauthorized(false)
    try {
      const result = await handleChangePermission({
        variables: {
          input: {
            epId,
            userId,
            roles
          }
        }
      })
      if (result && result.data) {
        setChangePermissionSuccess(true)
      } else if (result) {
        setChangePermissionUnauthorized(true)
      } else {
        setChangePermissionError(true)
      }
      const changePermissionUserProfileId =
        result?.data?.updateUserPermission?.id
      if (changePermissionUserProfileId) {
        refetchUsers({
          epId,
          searchTerm: '',
          roles: [],
          statuses: []
        })
      }
    } catch (e) {
      setChangePermissionError(true)
    }
  }

  const deactivateUser = async (userId: string, epId: string) => {
    setDeactivateUserError(false)
    setDeactivateUserSuccess(false)
    setDeactivateUserUnauthorized(false)
    try {
      const result = await handleDeactivateStaff({
        variables: {
          input: {
            userProfileId: userId
          }
        }
      })
      if (result && result.data) {
        setDeactivateUserSuccess(true)
      } else if (result) {
        setDeactivateUserUnauthorized(true)
      } else {
        setDeactivateUserError(true)
      }
      const deactivateUserProfileId = result?.data?.deactivateUserProfile?.id
      if (deactivateUserProfileId) {
        refetchUsers({
          epId,
          searchTerm: '',
          roles: [],
          statuses: []
        })
      }
    } catch (e) {
      setDeactivateUserError(true)
    }
  }

  const activateUser = async (email: string, epId: string) => {
    setActivateUserError(false)
    setActivateUserSuccess(false)
    setActivateUserUnauthorized(false)
    try {
      const result = await handleActivateStaff({
        variables: { input: { email } }
      })
      if (result && result.data) {
        setActivateUserSuccess(true)
      } else if (result) {
        setActivateUserUnauthorized(true)
      } else {
        setActivateUserError(true)
      }
      const activateUserProfileId = result?.data?.activateUserProfile?.id
      if (activateUserProfileId) {
        refetchUsers({
          epId,
          searchTerm: '',
          roles: [],
          statuses: []
        })
      }
    } catch (e) {
      setActivateUserError(true)
    }

    return email
  }

  return {
    inviteStaff,
    resendInvite,
    employerStaffAccounts,
    getEmployerUsers,
    refetchUsers,
    getEmployerUsersError,
    getEmployerUsersResponse,
    getEmployerUsersIsLoading,
    loading,
    paginationState,
    filterState,
    dispatchFilter,
    dispatchPaginationState,
    fetchMore,
    changePermission,
    changePermissionSuccess,
    changePermissionError,
    changePermissionUnauthorized,
    deactivateUser,
    deactivateUserSuccess,
    deactivateUserError,
    deactivateUserUnauthorized,
    activateUser,
    activateUserSuccess,
    activateUserError,
    activateUserUnauthorized,
    getEmployerUser,
    selectedUserResponse: selectedUserData,
    selectedUserError,
    selectedUserIsLoading
  }
}

export default useUserAdmin
