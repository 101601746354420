import { useContext, useEffect } from 'react'
import { BackgroundContext } from 'context/GradientBackground'
import { DefaultBackground } from './style'

const WithGradientBackground = () => {
  const { addBackground, removeBackground } = useContext(BackgroundContext)
  useEffect(() => {
    addBackground(DefaultBackground)
    return () => {
      removeBackground()
    }
    // eslint-disable-next-line
  }, [])

  return null
}
export default WithGradientBackground
