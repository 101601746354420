import React from 'react'
import { Button, ButtonProps, styled, Stack } from '@mui/material'
import { PowerSettingsNew as DeactivateIcon } from '@mui/icons-material'

const StyledButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#F2F3F3',
  color: '#747779',
  padding: '13px 17px 13px 20px',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: '#F12757',
    color: '#FFFFFF'
  }
}))

interface DeactivateButtonProps {
  onClick: () => void
}

const DeactivateButton = ({ onClick }: DeactivateButtonProps) => {
  return (
    <StyledButton onClick={onClick}>
      <Stack direction='row' alignContent='center'>
        <DeactivateIcon
          sx={{ fontSize: '20px', marginRight: '5px', marginTop: '1px' }}
        />
        Deactivate
      </Stack>
    </StyledButton>
  )
}
export default DeactivateButton
