import React, { useState } from 'react'
import {
  Menu,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Typography,
  Grid
} from '@mui/material'
import { Color, UserAvatar } from '@mathison-inc/components'
import { ExitIcon, OrganizationSettingsIcon } from '@mathison-inc/icons'

export interface UserSectionProps {
  handleClick: (path: string) => void
  basicUser: boolean
  user: any
}

const UserSection = ({ handleClick, basicUser, user }: UserSectionProps) => {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(
    null
  )
  const { username, email, logout } = user
  return (
    <div style={{ display: 'flex', marginRight: 8, maxWidth: 90 }}>
      <Grid
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={e => setUserMenuAnchorEl(e.currentTarget)}
      >
        <UserAvatar username={username} />
      </Grid>
      <Menu
        id='user-menu'
        sx={{
          '& .divider': {
            borderColor: '#d9d9d9'
          },
          '& .menuItem': {
            paddingLeft: '16px',
            paddingTop: '16px',
            paddingBottom: '16px',
            '& .logoutIcon': {
              marginLeft: '1.7px'
            },
            '& .logoutTitle': {
              marginLeft: '17.8px',
              color: Color.main,
              fontFamily: 'IBM Plex Sans',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: 16,
              lineHeight: '20px',
              letterSpacing: '0.01em'
            }
          }
        }}
        className='settingMenuList'
        anchorEl={userMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(userMenuAnchorEl)}
        onClose={() => setUserMenuAnchorEl(null)}
      >
        <Grid
          sx={{
            paddingLeft: '16px',
            paddingTop: '12px',
            paddingBottom: '12px',
            display: 'flex',
            alignItems: 'center',
            '& .primaryText': {
              display: 'block',
              color: Color.main,
              fontFamily: 'IBM Plex Sans',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: 14,
              lineHeight: '18px',
              letterSpacing: '0.01em'
            },
            '& .secondaryText': {
              color: Color.black60,
              fontFamily: 'IBM Plex Sans',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: 14,
              lineHeight: '18px',
              letterSpacing: '0.01em'
            }
          }}
        >
          <ListItemAvatar>
            <UserAvatar username={username} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant='body2Medium' className='primaryText'>
                {username}
              </Typography>
            }
            secondary={
              <Typography variant='body2' className='secondaryText'>
                {email}
              </Typography>
            }
          />
        </Grid>

        {!basicUser && (
          <MenuItem
            className='menuItem'
            onClick={() => {
              handleClick('/organization-settings')
              setUserMenuAnchorEl(null)
            }}
          >
            <OrganizationSettingsIcon className='logoutIcon' />
            <Typography variant='body2' className='logoutTitle'>
              Organization settings
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          className='menuItem'
          onClick={() => {
            logout(user)
            setUserMenuAnchorEl(null)
          }}
        >
          <ExitIcon className='logoutIcon' />
          <Typography variant='body2' className='logoutTitle'>
            Log out
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default UserSection
