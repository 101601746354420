/* eslint-disable consistent-return */
import { useEffect, useContext } from 'react'
import { NotificationContext } from 'context/NotificationContext'
import Client from 'utils/createApolloMaestroClient'
import { loadMicroApp } from 'qiankun'
import { AccountContext } from 'context/AccountContext'
import { AccessContext } from 'context/AccessContext'
import { fenderEntry } from 'utils/entryProvider'
import { FeatureFlagsContext } from 'context/FeatureFlagsContext'

const EmployeeSurvey = () => {
  const { displayNotification } = useContext(NotificationContext)
  const {
    data: { currentAccount }
  } = useContext(AccountContext)
  const { hasAccessToSurveySummary } = useContext(AccessContext)
  const { flags, isFlagEnabled } = useContext(FeatureFlagsContext)

  useEffect(() => {
    if (!flags) return

    const {
      enable_es_equitable_behaviors_data_pipeline:
        enableEquitableBehaviorsDataPipeline,
      enable_es_equitable_behaviors_demo_data: enableEquitableBehaviorsDemoData
    } = flags as any
    const app = loadMicroApp({
      name: 'fender', // app name registered, same as the one in package.json
      entry: fenderEntry,
      container: '#fender-root',
      props: {
        displayNotification,
        currentAccount,
        maestroClient: Client(),
        hasAccessToSurveySummary,
        enableEquitableBehaviorsDataPipeline,
        enableEquitableBehaviorsDemoData,
        isFlagEnabled
      }
    })

    // eslint-disable-next-line consistent-return
    return () => {
      if (app.getStatus() === 'MOUNTED') {
        app.unmount()
      }
    }
    // eslint-disable-next-line
  }, [flags])

  return null
}

export default EmployeeSurvey
