import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Laboratory = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    if (pathname === '/bias-scanner')
      document.title = 'Bias Scanner v2 | Mathison'
    return () => {
      document.title = 'Mathison'
    }
  }, [pathname])
  return (
    <iframe
      title='bias-scanner'
      src='https://mathison-ai-bias-scanner.streamlit.app/?embed=true'
      style={{ width: '100%', height: 'calc(100vh - 56px - 147px)' }}
      frameBorder='0'
    />
  )
}

export default Laboratory
