/* eslint-disable no-nested-ternary */
import React from 'react'
import {
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import { Stack, Typography, Chip, styled, ChipProps } from '@mui/material'
import { Circle as StatusIcon } from '@mui/icons-material'
import { getFormattedDate } from 'utils/adminData'

interface StyledChipProps extends ChipProps {
  status: string
}

const StyledChip = styled(Chip, {
  shouldForwardProp: prop => prop !== 'status'
})<StyledChipProps>(({ status }) => ({
  backgroundColor:
    status && status === 'achieved'
      ? '#3eb59d'
      : status && status === 'improving'
      ? '#fcff72'
      : '#F12757',
  color:
    status && status === 'achieved'
      ? 'white'
      : status && status === 'improving'
      ? '#191919'
      : 'white',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '10px',
  height: '25px',
  width: '95px',
  border: `1px solid ${
    status && status === 'achieved'
      ? '#3eb59d'
      : status && status === 'improving'
      ? '#d9dc62'
      : '#F12757'
  }
    `
}))

export const jobColumns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Job Title',
    description: 'The title for this job in the ATS.',
    headerClassName: 'job-table-header',
    cellClassName: 'job-title',
    type: 'string',
    flex: 1
  },
  {
    field: 'createdAt',
    headerName: 'Date Created',
    description: 'The date this job was created in the ATS.',
    type: 'string',
    valueGetter: (params: GridValueGetterParams) => {
      return params?.row?.createdAt
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            '&:last-child td, &:last-child th': {
              border: 0
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '14px',
            width: '100%'
          }}
        >
          {getFormattedDate(params?.row?.createdAt)}
        </Typography>
      )
    },
    flex: 1
  },
  {
    field: 'updatedAt',
    headerName: 'Date Updated',
    description: 'The last date this job has been updated in the ATS.',
    type: 'string',
    valueGetter: (params: GridValueGetterParams) => {
      return params?.row?.updatedAt
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            '&:last-child td, &:last-child th': {
              border: 0
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '14px',
            width: '100%'
          }}
        >
          {getFormattedDate(params?.row?.updatedAt)}
        </Typography>
      )
    },
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    description: 'The job status - open, pending, or closed.',
    type: 'string',
    valueGetter: (params: GridValueGetterParams) => {
      return params?.row?.status
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Stack
          width='100%'
          display='flex'
          justifyContent='left'
          alignItems='center'
          direction='row'
          gap={1}
        >
          <StatusIcon
            sx={{
              fontSize: '9px',
              color:
                params?.row?.status === 'OPEN'
                  ? '#3eb59d'
                  : params?.row?.status === 'PENDING'
                  ? '#fcff72'
                  : '#F12757'
            }}
          />
          <Typography
            sx={{
              '&:last-child td, &:last-child th': {
                border: 0
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 700,
              textTransform: 'uppercase',
              fontSize: '12px'
            }}
          >
            {params?.row?.status}
          </Typography>
        </Stack>
      )
    },
    flex: 1
  },
  {
    field: 'stageName',
    headerName: 'Hiring Stage',
    description:
      'The farthest hiring stage an applicant has reached for this job.',
    cellClassName: 'hiring-stage',
    type: 'string',
    flex: 1
  },
  {
    field: 'totalApplicants',
    headerName: 'Total Applicants',
    description:
      'The total number of applicants who have applied to this position',
    type: 'number',
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row?.analytics?.applicants?.totalApplicants) {
        return params.row?.analytics?.applicants?.totalApplicants
      }
      return 0
    },
    flex: 1
  },
  {
    field: 'diverstiyStatus',
    headerName: 'Diversity Goal',
    description:
      'This column shows the demographic mix of the applicants to this job and gives a status based on how well the percentages line up to the diversity demographic goal.',
    type: 'string',
    valueGetter: (params: GridValueGetterParams) => {
      return params?.row?.diversityStatus
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Stack
          width='100%'
          display='flex'
          justifyContent='left'
          alignItems='center'
          direction='row'
          gap={1}
        >
          <StyledChip
            status={params?.row?.diversityStatus}
            label={params?.row?.diversityStatus || 'na'}
          />
        </Stack>
      )
    },
    flex: 1
  }
]
