import { gql } from '@apollo/client'

export const GET_EMPLOYERS = gql`
  query GetEmployers {
    employers {
      edges {
        node {
          name
          id
          code
          authenticationStatus
          status
          insertedAt
          updatedAt
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`

export const GET_EMPLOYER = gql`
  query GetEmployer($id: ID!) {
    employer(id: $id) {
      id
      name
      code
      status
    }
  }
`

export const CREATE_EMPLOYER = gql`
  mutation CreateEmployer($name: String!, $code: String!, $status: String!) {
    createEmployer(input: { name: $name, code: $code, status: $status }) {
      id
      code
      name
    }
  }
`

export const UPDATE_EMPLOYER_NAME = gql`
  mutation UpdateEmployerName($name: String!, $id: ID!) {
    updateEmployerName(input: { name: $name, id: $id }) {
      id
      name
      code
      organizationId
      authenticationStatus
      status
    }
  }
`

export const UPDATE_EMPLOYER_IMPACT_MANAGER = gql`
  mutation UpdateEmployerImpactManager($id: ID!, $impactManagerId: ID!) {
    updateEmployerImpactManager(
      input: { id: $id, impactManagerId: $impactManagerId }
    ) {
      id
      impactManagerId
    }
  }
`

export const GET_EMPLOYER_LOGIN_TYPE = gql`
  query GetEmployerAuthenticationStrategy($employerId: ID) {
    employerAuthenticationSettings(employerId: $employerId) {
      employerId
      strategy
      idpType
    }
  }
`
