import React from 'react'
import { Grid } from '@mui/material'
import RoutesContainer from './RoutesContainer'

const EquityIndex = () => {
  return (
    <Grid
      sx={{
        margin: 'auto',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <RoutesContainer />
    </Grid>
  )
}

export default EquityIndex
