import { gql } from '@apollo/client'

export const INVITE_USERS = gql`
  mutation InviteOrganizationUsers(
    $emails: [String!]!
    $organizationId: String!
  ) {
    inviteOrganizationUsers(emails: $emails, organizationId: $organizationId) {
      failed
      reactivationRequired
      successful
    }
  }
`
