/* eslint-disable no-console */
import React, { useContext } from 'react'
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Color } from '@mathison-inc/components'
import { AccountContext } from 'context/AccountContext'
import ExpandableList from './ExpandableList'

const NavigationTab = ({ label, expanded, icon, sublist, url }: any) => {
  const navigate = useNavigate()
  const { pathname } = window.location
  const isSelected =
    pathname === '/laboratory/ai-ei'
      ? pathname === url.split('?')[0]
      : pathname.includes(url.split('?')[0]) && label !== 'Add Goal'

  const {
    data: { currentAccount }
  } = useContext(AccountContext)
  const { jobsAccess, ehiAccess } = currentAccount

  const isExpandIconNeededWithAccessLimit = sublist?.find(
    (item: any) => !item.access || [ehiAccess, jobsAccess].includes(item.access)
  )

  return (
    <ListItem key={label} disablePadding sx={{ display: 'block' }}>
      {sublist && isExpandIconNeededWithAccessLimit ? (
        <ExpandableList
          label={label}
          icon={icon}
          sublist={sublist}
          sidePanelExpanded={expanded}
          url={url}
        />
      ) : (
        <ListItemButton
          sx={{
            minHeight: 32,
            height: 32,
            borderRadius: '4px',
            mb: 1,
            justifyContent: expanded ? 'initial' : 'center',
            px: 2.5,

            color: isSelected ? '#fff' : Color.grey700,
            backgroundColor: isSelected ? Color.grape400 : '#fff',
            '&:hover': {
              backgroundColor: Color.grape400,
              '& .MuiListItemIcon-root': {
                // use the class name of the ListItemIcon
                color: Color.white
              },
              '& .MuiTypography-root': { color: Color.white },
              '& .MuiSvgIcon-root': { color: Color.white }
            }
          }}
          onClick={() => {
            navigate(url)
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: expanded ? 1.5 : 'auto',
              justifyContent: 'center',
              width: expanded ? '24px' : '16px',
              color: isSelected ? '#fff' : Color.grey500,
              WebkitTransition: 'opacity 0.3s ease, visibility 0.3s ease',
              transition: 'opacity 0.3s ease, visibility 0.3s ease'
            }}
          >
            {icon ?? <div style={{ height: '20px', width: '20px' }} />}
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant='body14Light'>{label}</Typography>}
            sx={{ opacity: expanded ? 1 : 0 }}
          />
        </ListItemButton>
      )}
    </ListItem>
  )
}

export default NavigationTab
