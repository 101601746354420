import React, { Box, Stack } from '@mui/material'
import { Circle as ValueIcon } from '@mui/icons-material'
import { Point } from '@nivo/line'

const CustomTooltip = ({ point }: { point: Point }) => {
  const { x, y } = point.data
  const { serieColor, serieId } = point

  const xValue = x instanceof Date ? x.toLocaleString() : x
  const yValue = y instanceof Date ? y.toLocaleString() : y

  return (
    <Box
      style={{
        background: 'white',
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px'
      }}
    >
      <Stack direction='row' spacing={1} sx={{ fontSize: '12px' }}>
        <ValueIcon
          sx={{ color: serieColor, fontSize: '10px', paddingTop: '5px' }}
        />
        <Box sx={{ fontWeight: 'bold' }}>
          group: <span style={{ fontWeight: 400 }}>{serieId}</span>
        </Box>
        <Box sx={{ fontWeight: 'bold' }}>
          stage: <span style={{ fontWeight: 400 }}>{xValue}</span>
        </Box>
        <Box sx={{ fontWeight: 'bold' }}>
          count: <span style={{ fontWeight: 400 }}>{yValue}</span>
        </Box>
      </Stack>
    </Box>
  )
}
export default CustomTooltip
