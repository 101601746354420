/* eslint-disable no-console */
import mixpanel from 'mixpanel-browser'

export const init = () => {
  const mixPanelToken = process.env.REACT_APP_MIXPANEL_TOKEN
  console.log(mixPanelToken)
  if (mixPanelToken) {
    mixpanel.init(mixPanelToken, {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage'
    })
  }
}
