/* eslint-disable no-console */
import React, { useMemo, useState } from 'react'
import {
  Grid,
  Box,
  InputBase,
  InputBaseProps,
  Typography,
  Button,
  styled,
  Stack
} from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import { employerNameCache } from 'utils/adminData'
import { Color } from '@mathison-inc/components'
import InputErrorMessage from 'components/InputErrorMessage'

type NameInputWithCounterProps = {
  countLimit: number
  fontSize?: number
  label?: string
}

const NameInput = styled(InputBase)<InputBaseProps | NameInputWithCounterProps>(
  ({ fontSize }: any) => ({
    padding: '4px 0 8px',
    '& .MuiInputBase-input': {
      borderRadius: 8,
      position: 'relative',
      lineHeight: 1.2,
      backgroundColor: Color.white,
      border: `2px solid ${Color.black}`,
      fontSize,
      fontWeight: 700,
      padding: '32px 16px'
    },
    '&.Mui-error': {
      '& .MuiInputBase-input': {
        borderColor: '#FD404C'
      }
    }
  })
)

const NameInputWithCounter = (
  props: InputBaseProps | NameInputWithCounterProps
) => {
  const {
    value,
    countLimit = 75,
    fontSize = 24,
    label
  } = props as {
    value: string
    countLimit: number
    fontSize?: number
    label: string
  }

  return (
    <NameInput
      fontSize={fontSize}
      endAdornment={
        <>
          <Typography
            variant='body2'
            sx={{ position: 'absolute', bottom: '12px', right: '8px' }}
          >
            {value.replace(/(\r\n|\n|\r)/g, ' ').length} / {countLimit}
          </Typography>
          <Typography
            variant='body2'
            sx={{ position: 'absolute', top: '12px', left: '16px' }}
          >
            {label}
          </Typography>
        </>
      }
      {...props}
    />
  )
}

interface IProps {
  userId: string
  currentName: string
  readOnly: boolean
  submitName: (name: string) => void
}

const EmployeeNameInput = ({
  userId,
  currentName,
  readOnly,
  submitName
}: IProps) => {
  const [employerName, setEmployerName] = useState(
    employerNameCache.getItem(userId) || currentName || ''
  )
  const [isEditingName, setIsEditingName] = useState(false)
  const [isLessThan5, setIsLessThan5] = useState(false)
  const [hasUnsavedChange, setHasUnsavedChange] = useState(false)
  const unsavedName = employerNameCache.getItem(userId)

  useMemo(() => {
    if (userId) {
      setIsLessThan5(false)
      setEmployerName(unsavedName || currentName || '')
      if (!unsavedName) {
        setHasUnsavedChange(false)
        setIsEditingName(false)
      } else {
        setHasUnsavedChange(true)
        setIsEditingName(true)
      }
    }
  }, [userId, currentName, unsavedName])

  const handleEndingChange = () => {
    setIsEditingName(false)
    setHasUnsavedChange(false)
    setIsLessThan5(false)
    employerNameCache.removeItem(userId)
  }

  const handleOnSubmit = async () => {
    setHasUnsavedChange(false)
    if (employerName.length < 5) {
      setIsLessThan5(true)
    } else {
      submitName(employerName)
      handleEndingChange()
    }
  }

  return (
    <Grid
      item
      xs={8}
      tabIndex={0}
      id='nameEditorContainer'
      onBlur={(e: any) => {
        if (
          document
            .getElementById('nameEditorContainer')!
            .contains(e.relatedTarget)
        )
          return

        if (employerName && employerName !== currentName) {
          employerNameCache.setItem(employerName, userId)
          setIsLessThan5(false)
          setHasUnsavedChange(true)
        }
      }}
    >
      <Box sx={{ width: '100%' }}>
        {(!isEditingName || readOnly) && (
          <Box>
            <Typography variant='h2' marginBottom={2}>
              {currentName}
            </Typography>

            {!readOnly && (
              <Button
                variant='action'
                sx={{
                  marginTop: '8px',
                  fontSize: '14px',
                  fontWeight: '600',
                  backgroundColor: 'transparent'
                }}
                onClick={() => setIsEditingName(true)}
              >
                <Stack direction='row'>
                  <EditIcon
                    sx={{
                      fontSize: '15px',
                      marginTop: '3px',
                      marginRight: '5px',
                      color: '#5F71E5'
                    }}
                  />
                  Edit
                </Stack>
              </Button>
            )}
          </Box>
        )}
        {isEditingName && (
          <Box>
            <NameInputWithCounter
              fullWidth
              label='Name'
              onFocus={e => {
                e.stopPropagation()
                setHasUnsavedChange(false)
              }}
              countLimit={75}
              multiline
              error={
                !employerName ||
                employerName.length >= 76 ||
                isLessThan5 ||
                hasUnsavedChange
              }
              value={employerName}
              onChange={e => {
                setIsLessThan5(false)
                e.stopPropagation()
                setEmployerName(e.target.value)
              }}
            />
            <Stack direction='row' justifyContent='space-between' marginTop={2}>
              <Box>
                {!employerName && (
                  <InputErrorMessage>
                    Employer must have a name
                  </InputErrorMessage>
                )}
                {employerName && employerName.length > 75 && (
                  <InputErrorMessage>
                    Employer name must be less than 75 characters
                  </InputErrorMessage>
                )}
                {isLessThan5 && (
                  <InputErrorMessage>
                    Employer name must be at least 5 characters
                  </InputErrorMessage>
                )}
                {hasUnsavedChange && (
                  <InputErrorMessage>
                    Your updates won’t be posted until you save your changes
                  </InputErrorMessage>
                )}
              </Box>
              <Stack direction='row' spacing={1}>
                <Button
                  variant='underline'
                  aria-label='employer-name-button'
                  onClick={e => {
                    e.preventDefault()
                    setEmployerName(currentName)
                    handleEndingChange()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  aria-label='employer-name-button'
                  size='small'
                  disabled={!employerName || employerName.length >= 76}
                  onClick={() => {
                    handleOnSubmit()
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </Box>
        )}
      </Box>
    </Grid>
  )
}
export default EmployeeNameInput
