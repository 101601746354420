import React, { ChangeEventHandler } from 'react'
import { Paper, IconButton, InputBase } from '@mui/material'
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material'

interface SearchBarProps {
  value: string | number
  placeHolderText: string
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  clearSearch: () => void
}

const SearchBar = ({
  value,
  placeHolderText,
  onChange,
  clearSearch
}: SearchBarProps) => {
  return (
    <Paper
      component='form'
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        border: '1px solid #CECECE',
        boxShadow: 'none'
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeHolderText}
        aria-label='search organizations'
        value={value}
        onChange={onChange}
      />
      <IconButton
        type='button'
        sx={{ p: '10px' }}
        aria-label='search'
        onClick={clearSearch}
      >
        {value ? <ClearIcon /> : <SearchIcon />}
      </IconButton>
    </Paper>
  )
}
export default SearchBar
