import React from 'react'
import {
  Box,
  Stack,
  Button,
  ButtonProps,
  IconButton,
  Typography,
  styled
} from '@mui/material'
import {
  Close as CloseIcon,
  WarningAmber as WarningIcon
} from '@mui/icons-material'
import LoadingIndicator from 'components/LoadingIndicator'

const CancelButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#050B0E',
  border: '1px solid #050B0E',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F1F1F1',
    color: '#050B0E',
    border: '1px solid #CCCCCC'
  }
}))

const ConfirmButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#3148D3',
  color: '#FFFFFF',
  border: '1px solid #3148D3',
  marginLeft: '20px',
  '&:hover': {
    backgroundColor: '#F1F1F1',
    color: '#050B0E',
    border: '1px solid #CCCCCC'
  }
}))

interface DeactivateConfirmProps {
  identifier: string
  isEp: boolean
  epId: string
  closeSidePanel: () => void
  setIsDeactivating: React.Dispatch<React.SetStateAction<boolean>>
  userId?: string
  epCode?: string
  deactivateUser?: (userId: string, epId: string) => void
  deactivateEp?: (epCode: string) => void
  deactivateEpInProgress?: boolean
  setDeactivateEpInProgress?: React.Dispatch<React.SetStateAction<boolean>>
}

const DeactivateConfirm = ({
  identifier,
  isEp,
  epId,
  epCode,
  userId,
  setIsDeactivating,
  deactivateUser,
  deactivateEp,
  closeSidePanel,
  deactivateEpInProgress,
  setDeactivateEpInProgress
}: DeactivateConfirmProps) => {
  return (
    <Box>
      {deactivateEpInProgress ? (
        <Box>
          <Typography>Deactivating EP...</Typography>
          <LoadingIndicator />
        </Box>
      ) : (
        <Box>
          <Box>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{ padding: '20px 0' }}
            >
              <WarningIcon sx={{ color: '#FD404C', fontSize: '30px' }} />
              <IconButton onClick={() => setIsDeactivating(false)}>
                <CloseIcon sx={{ color: '#050B0E', fontSize: '30px' }} />
              </IconButton>
            </Stack>
            <Typography variant='h3' sx={{ padding: '20px 0' }}>
              Deactivate <span style={{ color: '#FD404C' }}>{identifier}</span>?
            </Typography>
            {isEp ? (
              <Typography>
                Deactivating this org will prevent all users from accessing this
                Mathison instance. Orgs can only be reactivated by Mathison
                Admins. All org users and data will be preserved unless a data
                deletion request is processed.{' '}
              </Typography>
            ) : (
              <Typography>
                Deactivating this user will prevent them from accessing your
                organization&rsquo;s Mathison instance. Users can only be
                reactivated by your organizatino&rsquo;s Admins. All org users
                and data will be preserved unless a data deletion request is
                processed.{' '}
              </Typography>
            )}
          </Box>
          <Stack
            direction='row'
            justifyContent='right'
            sx={{ padding: '40px 0' }}
          >
            <CancelButton onClick={() => setIsDeactivating(false)}>
              Cancel
            </CancelButton>
            {!isEp && deactivateUser && userId && epId && (
              <ConfirmButton
                onClick={() => {
                  closeSidePanel()
                  setIsDeactivating(false)
                  deactivateUser(userId, epId)
                }}
              >
                {' '}
                Confirm
              </ConfirmButton>
            )}
            {isEp && deactivateEp && epCode && setDeactivateEpInProgress && (
              <ConfirmButton
                onClick={() => {
                  setDeactivateEpInProgress(true)
                  deactivateEp(epCode)
                }}
              >
                {' '}
                Confirm
              </ConfirmButton>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  )
}
export default DeactivateConfirm
