import { Navigation } from 'model'

export const Navigations: Navigation[] = [
  {
    label: 'LEGAL',
    link: '/legal',
    isTitle: true
  },
  { label: 'Privacy policy', link: '/legal/privacy-policy' },
  {
    label: 'Privacy Notice for California Residents',
    link: '/legal/privacy-notice-for-california-residents'
  },
  {
    label: 'Terms of Service',
    link: '/legal/terms-of-service'
  },
  {
    label: 'COMMITMENT TO INCLUSION',
    link: '/commitment-to-inclusion',
    isTitle: true
  },
  {
    label: 'Accessibility',
    link: '/commitment-to-inclusion/accessibility'
  }
]
