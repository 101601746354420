/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import {
  Box,
  Button,
  ButtonProps,
  Typography,
  Input,
  IconButton,
  IconButtonProps,
  styled,
  Stack,
  Alert,
  Grid
} from '@mui/material'
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  PersonAdd as InviteIcon
} from '@mui/icons-material'
import InputErrorMessage from 'components/InputErrorMessage'

const StyledIconButton = styled(IconButton)<IconButtonProps>(() => ({
  border: '1px solid black',
  width: '25px',
  height: '25px',
  margin: '0 10px'
}))

interface SendInviteButtonProps extends ButtonProps {
  emailsAreValid: boolean
}

const SendInviteButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'emailsAreValid'
})<SendInviteButtonProps>(({ emailsAreValid }) => ({
  color: emailsAreValid ? '#FFFFFF' : '#747779',
  backgroundColor: emailsAreValid ? '#3148D3' : '#F2F3F3',
  padding: '15px 20px',
  '&:hover': {
    backgroundColor: emailsAreValid ? 'black' : '#F2F3F3',
    color: emailsAreValid ? '#FFFFFF' : '#747779'
  }
}))

interface InviteUsersProps {
  handleInvite: (emails: string[]) => void
  inputs: string[]
  setInputs: React.Dispatch<React.SetStateAction<string[]>>
  emailIsEmpty: boolean
  setEmailIsEmpty: React.Dispatch<React.SetStateAction<boolean>>
  emailIsInvalid: boolean
  setEmailIsInvalid: React.Dispatch<React.SetStateAction<boolean>>
  currentIndex: number
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
}

const InviteUsers = ({
  handleInvite,
  inputs,
  setInputs,
  emailIsEmpty,
  setEmailIsEmpty,
  emailIsInvalid,
  setEmailIsInvalid,
  currentIndex,
  setCurrentIndex
}: InviteUsersProps) => {
  const maxInviteNumber = 10
  const [emailsAreValid, setEmailsAreValid] = React.useState<boolean>(false)
  const [valueIsNotSet, setValueIsNotSet] = React.useState<boolean>(true)

  const handleEmailCheckBeforeSend = (inviteEmails: string[]) => {
    const cleanedEmails: string[] = inviteEmails.filter(invite => invite !== '')
    const lowerCaseInvites: string[] = cleanedEmails.map(email =>
      email.toLowerCase()
    )
    const invites = [...new Set(lowerCaseInvites)]
    handleInvite(invites)
  }

  const handleInputCheck = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target
    const emailIsValid: boolean =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    if (emailIsValid) {
      setEmailIsInvalid(false)
    } else {
      setEmailIsInvalid(true)
    }

    if (!value || value === '') {
      setEmailIsEmpty(true)
    } else {
      setEmailIsEmpty(false)
    }
  }

  const handleAddInput = () => {
    setInputs([...inputs, ''])
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { value } = event.target
    if (value) {
      setValueIsNotSet(false)
    }
    const onChangeValue = [...inputs]
    onChangeValue[index] = value
    setInputs(onChangeValue)
    handleInputCheck(event)
    if (currentIndex !== index) {
      setCurrentIndex(index)
    }
  }

  const handleDeleteInput = (index: number) => {
    const newArray = [...inputs]
    newArray.splice(index, 1)
    setInputs(newArray)
  }

  React.useMemo(() => {
    if (
      emailIsEmpty ||
      emailIsInvalid ||
      (currentIndex === 0 && valueIsNotSet)
    ) {
      setEmailsAreValid(false)
    } else {
      setEmailsAreValid(true)
    }
  }, [emailIsEmpty, emailIsInvalid, currentIndex, valueIsNotSet])

  return (
    <Box>
      <Typography variant='h2' sx={{ marginBottom: '40px' }}>
        Invite Users
      </Typography>
      <Alert severity='info' sx={{ marginBottom: '40px' }}>
        New staff are invited as Basic Users. You can edit their roles once
        they&rsquo;ve created an account.
      </Alert>
      <Box
        sx={{
          padding: '10px 0',
          margin: '20px 0',
          borderBottom: '1px solid #e5e5e5'
        }}
      >
        <Stack direction='row'>
          <InviteIcon
            sx={{
              fontSize: '18px',
              color: '#3148D3',
              marginTop: '1px',
              marginRight: '5px'
            }}
          />
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 700,
              textTransform: 'uppercase'
            }}
          >
            Invite
          </Typography>
        </Stack>
      </Box>
      <Box>
        {inputs.length === maxInviteNumber && (
          <Alert severity='warning' sx={{ marginBottom: '40px' }}>
            {`You can only invite up to ${maxInviteNumber} users`}.
          </Alert>
        )}
      </Box>
      {inputs.map((email, index) => (
        <Box key={`invite-email-${index}`} sx={{ marginBottom: '20px' }}>
          <Grid container flexDirection='row'>
            <Grid item xs={8}>
              <Input
                name='email'
                type='email'
                value={email}
                placeholder='Email'
                onChange={event => handleChange(event, index)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              display='flex'
              justifyContent='right'
              alignItems='center'
            >
              {inputs.length > 1 && inputs.length < maxInviteNumber && (
                <StyledIconButton onClick={() => handleDeleteInput(index)}>
                  <RemoveIcon />
                </StyledIconButton>
              )}
              {index === inputs.length - 1 &&
                inputs.length < maxInviteNumber && (
                  <StyledIconButton onClick={() => handleAddInput()}>
                    <AddIcon />
                  </StyledIconButton>
                )}
            </Grid>
          </Grid>
          {(emailIsInvalid || emailIsEmpty) && currentIndex === index && (
            <InputErrorMessage>
              Please provide a valid email address
            </InputErrorMessage>
          )}
        </Box>
      ))}
      <Box sx={{ padding: '40px 0' }}>
        <SendInviteButton
          onClick={() => handleEmailCheckBeforeSend(inputs)}
          emailsAreValid={emailsAreValid}
          disabled={!emailsAreValid}
        >
          Send Invites
        </SendInviteButton>
      </Box>
    </Box>
  )
}
export default InviteUsers
