import { Route, Routes, Navigate } from 'react-router-dom'
import React from 'react'
import mathisonLogo from 'assets/images/Mathison_Colour_Logo.png'
import { Grid } from '@mui/material'
import { WithGradientBackground } from 'components'
import { Content } from './constant'
import SidebarNav from './SidebarNav'
import Document from './Document'
import { Header, MainContainer } from './style'

const StatementHub = (): React.ReactElement => {
  return (
    <>
      <Header>
        <Grid maxWidth='1460px' marginLeft='auto' marginRight='auto' container>
          <img src={mathisonLogo} width='148' alt='MathisonLogo' />
        </Grid>
      </Header>
      <MainContainer display='flex'>
        <WithGradientBackground />
        <Grid
          container
          marginTop='60px'
          maxWidth='1460px'
          wrap='nowrap'
          marginLeft='auto'
          marginRight='auto'
        >
          <SidebarNav />
          <Routes>
            {Content.map(item => {
              return (
                item.document && (
                  <Route
                    path={item.link}
                    element={<Document documentProps={item.document} />}
                  />
                )
              )
            })}
            <Route
              path='*'
              element={
                <Navigate to='/statement/legal/privacy-policy' replace />
              }
            />
          </Routes>
        </Grid>
      </MainContainer>
    </>
  )
}

export default StatementHub
