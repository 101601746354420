import React from 'react'
import { WithGradientBackground } from 'components'
import { Grid } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import EmployeeSurvey from 'pages/EmployeeSurvey'
import Assessment from './Assessment'

const RoutesContainer = () => {
  return (
    <>
      <WithGradientBackground />

      <Grid sx={{ width: '100%' }}>
        <Routes>
          <Route path='' element={<Assessment />} />
          <Route path='assessment/*' element={<Assessment />} />
          <Route path='history/*' element={<Assessment />} />
          <Route path='employee-survey/*' element={<EmployeeSurvey />} />
        </Routes>
      </Grid>
    </>
  )
}

export default RoutesContainer
