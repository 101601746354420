import React from 'react'
import { Box, Typography } from '@mui/material'
import AtsCard from 'components/AtsCard'
import { ERROR_CARD_DATA } from '../constants/copy'

interface ErrorPageProps {
  onClick: () => void
}

const ErrorPage = ({ onClick }: ErrorPageProps) => {
  return (
    <Box>
      <AtsCard data={ERROR_CARD_DATA} onClick={onClick} isError />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        <Box sx={{ paddingBottom: '40px', width: '100%' }}>
          <Box sx={{ width: '100%', textAlign: 'left', margin: '40px 0' }}>
            <Typography variant='h2'>Data Import Error</Typography>
            <Typography sx={{ padding: '40px 0', fontWeight: 'bold' }}>
              We&lsquo;ve been working on merging the data from your ATS to
              display on this application, so there may be an issue with the
              data pipeline. <br />
              Please contact us so that we can resolve the issue.
              <br /> Thank you for your patience.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default ErrorPage
