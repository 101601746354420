import React from 'react'
import { Modal, Box, Button } from '@mui/material'

interface MockMergeModalProps {
  isOpen: boolean
  setIsAtsLinked: React.Dispatch<React.SetStateAction<boolean>>
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const MockMergeModal = ({
  isOpen,
  setIsAtsLinked,
  setIsOpen
}: MockMergeModalProps) => {
  const mockMergeCall = () => {
    setIsAtsLinked(true)
    setIsOpen(false)
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Button onClick={mockMergeCall}>
          <img
            src='https://www.datocms-assets.com/54533/1732145346-merge_modal.png'
            style={{ width: '350px' }}
            alt='Merge ATS'
          />
        </Button>
      </Box>
    </Modal>
  )
}
export default MockMergeModal
