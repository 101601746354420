import React, { useEffect, useContext } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { OktaAuth } from '@okta/okta-auth-js'

import { AuthenticationContext } from 'context/AuthenticationContext'
import * as impersonationStorage from 'utils/impersonationStorage'

import { Button, CircularProgress, Typography } from '@mui/material'

export const oktaAuthClient: OktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${
    process.env.REACT_APP_URL || 'http://localhost:7200'
  }/login/callback`,

  tokenManager: {
    storageKey: 'mathison-token-storage',
    autoRenew: false
  },
  // transform auth state: by default Okta SDK will check the validity of both,
  // ID and access tokens, this function overrides this behaviour checking only
  // for access token freshness
  transformAuthState: async (oktaAuth, authState) => {
    // do nothing if we are authenticated
    if (authState.isAuthenticated) return authState

    const { accessToken } = oktaAuth.tokenManager.getTokensSync()

    // only check whether the access token is valid, do not check id token which
    // always expires after one hour
    // eslint-disable-next-line no-param-reassign
    authState.isAuthenticated =
      !!accessToken && !oktaAuth.tokenManager.hasExpired(accessToken)

    return authState
  }
})

export const authenticateAdminWithEmployerCode = (employerCode: string) => {
  const DefaultScopes = ['openid', 'email', 'profile']
  oktaAuthClient.token.getWithRedirect({
    scopes: DefaultScopes,
    prompt: 'none',
    state: queryString.stringify({ employerCode })
  })
}
const Impersonate = (): React.ReactElement => {
  const { isAuthenticated } = useContext(AuthenticationContext)
  const { search } = useLocation()
  const navigate = useNavigate()
  const params = queryString.parse(search)

  useEffect(() => {
    const { 'as-employer': employerParam } = params

    if (employerParam) {
      const employerCode = employerParam as string

      // When isAuthenticated is undefined the socket connection is not
      // established yet, we set the code now in case we are already
      // authenticated so the connection will take the employer code and pass it
      // as a parameter. In case we are not authenticated, we try to
      // authenticate the admin with the employer code, in which case the
      // employer will be overridden by code from the authenticate callback
      // state. When isAuthenticated is true we will be redirected to the
      // dashboard no need to take any action there.
      if (isAuthenticated === undefined) {
        impersonationStorage.set(employerCode)
      } else if (isAuthenticated === false) {
        authenticateAdminWithEmployerCode(employerCode)
      }
    }

    // eslint-disable-next-line
  }, [isAuthenticated, search])

  if (!search) return <Navigate to='/' replace />

  return (
    <>
      <CircularProgress />
      <Typography>
        if you are not getting redirected, please logout your account and try
        again
      </Typography>
      <div>
        <Button
          variant='contained'
          onClick={() => {
            localStorage.clear()
            navigate('/logout')
          }}
        >
          Log out{' '}
        </Button>
      </div>
    </>
  )
}

export default Impersonate
