import React, { ResponsiveLine, Serie } from '@nivo/line'
import { chartTheme } from './config'
import CustomTooltip from './components/CustomTooltip'

interface AtsLineChartProps {
  data: Serie[]
}

const AtsLineChart = ({ data }: AtsLineChartProps) => (
  <ResponsiveLine
    theme={chartTheme}
    data={data}
    margin={{ top: 50, right: 150, bottom: 50, left: 100 }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: false,
      reverse: false
    }}
    yFormat=' >-.2f'
    curve='linear'
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Hiring Stage',
      legendOffset: 36,
      legendPosition: 'middle',
      truncateTickAt: 0
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Count',
      legendOffset: -55,
      legendPosition: 'middle',
      truncateTickAt: 0
    }}
    tooltip={CustomTooltip}
    colors={{ scheme: 'tableau10' }}
    lineWidth={4}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabel='data.yFormatted'
    pointLabelYOffset={-12}
    areaOpacity={0}
    enableTouchCrosshair
    useMesh
    legends={[
      {
        anchor: 'right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 10,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
  />
)
export default AtsLineChart
