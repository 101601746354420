import { gql } from '@apollo/client'

export const DEACTIVATE_EP = gql`
  mutation DeactivateEmployer($input: DeactivateEmployerInput!) {
    deactivateEmployer(input: $input) {
      id
      status
    }
  }
`

export const ACTIVATE_EP = gql`
  mutation ActivateEmployer($input: ActivateEmployerInput!) {
    activateEmployer(input: $input) {
      id
      status
    }
  }
`
