/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
import {
  Requisition,
  RequisitionMock,
  Applicants,
  EeocPieData,
  EeocBarData,
  HorizontalDataPoint,
  PieChartDataPoint,
  DiversityStatus
} from 'model/atsMock'

export const createMockJobsData = (
  requisitions: RequisitionMock[],
  eeocData: any
): Requisition[] | [] => {
  const result: Requisition[] = [] as Requisition[]

  requisitions.forEach((requisition: RequisitionMock) => {
    const applicants: Applicants = {
      currentApplicants: requisition.currentApplicants,
      totalApplicants: requisition.totalApplicants,
      gender: eeocData.gender,
      race: eeocData.race,
      veteran_status: eeocData.veteran_status,
      disability_status: eeocData.disability_status
    }

    const finalPosition: Requisition = {
      id: requisition.id,
      createdAt: requisition.createdAt,
      updatedAt: requisition.updatedAt,
      status: requisition.status,
      title: requisition.title,
      stage: requisition.stage,
      stageName: requisition.stageName,
      descriptionHtml: requisition.descriptionHtml,
      analytics: {
        applicants
      }
    }

    result.push(finalPosition)
  })

  return result
}

const generateMockYesNoNoAnswerCounts = (totalApplicants: number) => {
  const yesPercentage = Math.random() * 0.15
  const noPercentage = Math.random() * 0.8
  const yesCount = Math.round(totalApplicants * yesPercentage)
  const noCount = Math.round(totalApplicants * noPercentage)
  const noAnswerCount = totalApplicants - yesCount - noCount

  const yes = (yesCount / totalApplicants) * 100
  const no = (noCount / totalApplicants) * 100
  const noAnswer = (noAnswerCount / totalApplicants) * 100

  // Build mock data array
  const mockData = {
    yes: yes.toFixed(1),
    no: no.toFixed(1),
    noAnswer: noAnswer.toFixed(1)
  }

  return mockData
}

const generateMockGenderData = (totalApplicants: number) => {
  // Define arbitrary percentages for each gender category
  const otherPercentage = Math.random() * 0.2
  const femalePercentage = Math.random() * 0.4

  // Calculate counts based on percentages and total applicants
  const otherCount = Math.round(totalApplicants * otherPercentage)
  const femaleCount = Math.round(totalApplicants * femalePercentage)
  const maleCount = totalApplicants - otherCount - femaleCount

  // Build mock data array
  const mockData = {
    male: maleCount,
    female: femaleCount,
    other: otherCount
  }

  return mockData
}

const generateMockEthnicityData = (totalApplicants: number) => {
  // Define arbitrary percentages for each gender category
  const multiPercentage = Math.random() * 0.1
  const latinePercentage = Math.random() * 0.1
  const asianPercentage = Math.random() * 0.2
  const indegenousPercentage = Math.random() * 0.1
  const blackPercentage = Math.random() * 0.2

  // Calculate counts based on percentages and total applicants
  const multiCount = Math.round(totalApplicants * multiPercentage)
  const latineCount = Math.round(totalApplicants * latinePercentage)
  const asianCount = Math.round(totalApplicants * asianPercentage)
  const blackCount = Math.round(totalApplicants * blackPercentage)
  const indegenousCount = Math.round(totalApplicants * indegenousPercentage)
  const whiteCount =
    totalApplicants -
    multiCount -
    asianCount -
    blackCount -
    latineCount -
    indegenousCount

  // Build mock data array
  const mockData = {
    white: whiteCount,
    latine: latineCount,
    black: blackCount,
    asian: asianCount,
    indegenous: indegenousCount,
    multiRacial: multiCount
  }

  return mockData
}

export const generateMockRepresentationStatus = (
  requisitions: Requisition[]
) => {
  const statuses: DiversityStatus[] = ['achieved', 'lagging', 'improving']

  requisitions.forEach(requisition => {
    const randomIndex = Math.floor(Math.random() * statuses.length)
    requisition.diversityStatus = statuses[randomIndex]
  })

  return requisitions
}

export const createPieChart = (
  applicants: Applicants,
  mockData?: boolean
): EeocPieData => {
  const result: EeocPieData = {} as EeocPieData

  if (mockData) {
    const mockGenderData = generateMockGenderData(applicants.totalApplicants)
    const mockEthnicityData = generateMockEthnicityData(
      applicants.totalApplicants
    )

    // construct gender array
    const genderArray: PieChartDataPoint[] = [
      {
        id: 1,
        value: mockGenderData.female,
        label: 'Female'
      },
      {
        id: 2,
        value: mockGenderData.male,
        label: 'Male'
      },
      {
        id: 3,
        value: mockGenderData.other,
        label: 'Other'
      }
    ]

    result.gender = genderArray

    // construct ethnicity array
    const ethnicityArray: PieChartDataPoint[] = [
      {
        id: 1,
        value: mockEthnicityData.white,
        label: 'White'
      },
      {
        id: 2,
        value: mockEthnicityData.latine,
        label: 'Latine'
      },
      {
        id: 3,
        value: mockEthnicityData.black,
        label: 'Black'
      },
      {
        id: 4,
        value: mockEthnicityData.asian,
        label: 'Asian'
      },
      {
        id: 5,
        value: mockEthnicityData.multiRacial,
        label: 'Multi-Racial'
      },
      {
        id: 6,
        value: mockEthnicityData.indegenous,
        label: 'Indegenous'
      }
    ]

    result.ethnicty = ethnicityArray
  } else {
    // construct gender array
    const genderArray: PieChartDataPoint[] = [
      {
        id: 1,
        value: applicants?.gender?.female || 0,
        label: 'Female'
      },
      {
        id: 2,
        value: applicants?.gender?.male || 0,
        label: 'Male'
      },
      {
        id: 3,
        value:
          applicants?.gender?.decline_to_self_identify +
            applicants?.gender?.no_data || 0,
        label: 'Other'
      }
    ]

    result.gender = genderArray

    // construct ethnicity array
    const ethnicityArray: PieChartDataPoint[] = [
      {
        id: 1,
        value: applicants?.race?.white || 0,
        label: 'White'
      },
      {
        id: 2,
        value: applicants?.race?.hispanic_or_latino || 0,
        label: 'Latine'
      },
      {
        id: 3,
        value: applicants?.race?.black_or_african_american || 0,
        label: 'Black'
      },
      {
        id: 4,
        value: applicants?.race?.asian || 0,
        label: 'Asian'
      },
      {
        id: 5,
        value: applicants?.race?.two_or_more_races || 0,
        label: 'Multi-Racial'
      },
      {
        id: 6,
        value: applicants?.race?.american_indian_or_alaskan_native || 0,
        label: 'Indegenous'
      }
    ]

    result.ethnicty = ethnicityArray
  }

  return result
}

export const createHorizontalBarChart = (
  applicants: Applicants,
  mockData?: boolean
): EeocBarData => {
  if (mockData) {
    const mockDataLgbtqia = generateMockYesNoNoAnswerCounts(
      applicants.totalApplicants
    )
    const mockVeteran = generateMockYesNoNoAnswerCounts(
      applicants.totalApplicants
    )
    const mockDisability = generateMockYesNoNoAnswerCounts(
      applicants.totalApplicants
    )
    const result: EeocBarData = {
      lgbtqia: {
        no: mockDataLgbtqia.no,
        yes: mockDataLgbtqia.yes,
        noAnswer: mockDataLgbtqia.noAnswer
      } as HorizontalDataPoint,
      disability: {
        no: mockDisability.no,
        yes: mockDisability.yes,
        noAnswer: mockDisability.noAnswer
      } as HorizontalDataPoint,
      veteran: {
        no: mockVeteran.no,
        yes: mockVeteran.yes,
        noAnswer: mockVeteran.noAnswer
      } as HorizontalDataPoint
    }

    return result
  }

  const result: EeocBarData = {
    lgbtqia: {
      no: 0,
      yes: 0,
      noAnswer: 0
    } as HorizontalDataPoint,
    veteran: {
      no: 0,
      yes: 0,
      noAnswer: applicants?.veteran_status.no_data
    } as HorizontalDataPoint,
    disability: {
      no: applicants?.disability_status?.no_i_dont_have_a_disability || 0,
      yes:
        applicants?.disability_status
          ?.yes_i_have_a_disability_or_previously_had_a_disability || 0,
      noAnswer:
        applicants?.disability_status?.no_data +
          applicants?.disability_status.i_dont_wish_to_answer || 0
    } as HorizontalDataPoint
  }

  return result
}
