import { gql, useQuery } from '@apollo/client'
import { LatestAssessment } from 'model/assessment'
import { useState } from 'react'

export const GET_LATEST_ASSESSMENT = gql`
  query ($year: String) {
    employerAssessmentHistory(year: $year) {
      latestAssessment {
        id
        acquiredScore
        endAt
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }
    }
  }
`

const useAssessment = () => {
  const [hasCompletedAnAssessment, setHasCompletedAnAssessment] =
    useState<boolean>(false)
  const [latestAssessment, setLatestAssessment] =
    useState<LatestAssessment | null>(null)

  const { loading } = useQuery(GET_LATEST_ASSESSMENT, {
    onCompleted: (result: any) => {
      if (result.employerAssessmentHistory.latestAssessment) {
        setHasCompletedAnAssessment(true)
        setLatestAssessment(result.employerAssessmentHistory.latestAssessment)
      }
    },
    variables: {}
  })

  return {
    latestAssessment,
    hasCompletedAnAssessment,
    assessmentIsLoading: loading
  }
}

export default useAssessment
