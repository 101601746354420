import React, { useContext, useEffect } from 'react'
import { loadMicroApp } from 'qiankun'
import { NotificationContext } from 'context/NotificationContext'
import { AccountContext } from 'context/AccountContext'
import Client from 'utils/createApolloMaestroClient'
import { AccessContext } from 'context/AccessContext'
import { bumperEntry } from 'utils/entryProvider'
import LoadingIndicator from 'components/LoadingIndicator'

const Assessment = (): React.ReactElement => {
  const { displayNotification } = useContext(NotificationContext)
  const {
    data: { currentAccount }
  } = useContext(AccountContext)
  const { hasAccessToViewDashboard, hasAccessToViewAssessment } =
    useContext(AccessContext)

  useEffect(() => {
    const bumperApp = loadMicroApp({
      name: 'bumper',
      entry: bumperEntry,
      container: '#bumper-root',
      props: {
        displayNotification,
        maestroClient: Client(),
        currentAccount,
        hasAccessToViewDashboard,
        hasAccessToViewAssessment
      }
    })

    return () => {
      if (bumperApp.getStatus() === 'MOUNTED') {
        bumperApp.unmount()
      }
    }
    // eslint-disable-next-line
  }, [])

  return <LoadingIndicator timeout={3000} />
}

export default Assessment
