/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_ATS_JOBS, GET_ATS_JOB_BY_ID } from 'context/AtsContext/gql'
import { AtsJobsData, AtsJobsResponse, Job, JobEdge } from 'model/ats'

// maps the jobs response to get all the nodes (Job[])
const getJobsFromResponse = (atsJobsData: AtsJobsData): Job[] => {
  const jobs: Job[] = atsJobsData.atsJobs.edges.map(
    (edge: JobEdge) => edge.node
  )
  return jobs
}

export const useAts = () => {
  // get ats job by id from BE
  const [getAtsJobById, { data: atsJob, error: atsJobError }] =
    useLazyQuery(GET_ATS_JOB_BY_ID)

  const INITIAL_JOBS_RESPONSE: AtsJobsResponse = {
    data: null
  }

  const [atsJobsData, setAtsJobsData] = useState<AtsJobsData | null>(null)
  const [jobs, setJobs] = useState<Job[]>([] as Job[])
  const [jobsIsLoading, setJobsIsLoading] = useState<boolean>(true)
  const [jobsError, setJobsError] = useState<boolean>(false)
  const [job, setJob] = useState<Job | null>(null)
  const [jobIsLoading, setJobIsLoading] = useState<boolean>(true)
  const [jobError, setJobError] = useState<boolean>(false)

  // get ats jobs from BE
  const {
    data: atsJobsResponse = INITIAL_JOBS_RESPONSE,
    error: atsJobsResponseError,
    refetch: refetchAtsJobs
  } = useQuery(GET_ATS_JOBS)

  // set job by ID
  useEffect(() => {
    if (atsJob) {
      setJob(atsJob.data.atsJob)
      setJobIsLoading(false)
    }
  }, [atsJob])

  // handle job error response
  useEffect(() => {
    if (atsJobError) {
      setJobError(true)
      setJobIsLoading(false)
    }
  }, [atsJobError])

  // set jobs data
  useEffect(() => {
    if (atsJobsResponse) {
      setAtsJobsData(atsJobsResponse)
    }
  }, [atsJobsResponse])

  // process atsJobs into Job[]
  useEffect(() => {
    if (atsJobsData && atsJobsData.atsJobs) {
      const nodeJobs: Job[] = getJobsFromResponse(atsJobsData)
      setJobs(nodeJobs)
      setJobsIsLoading(false)
    }
  }, [atsJobsData])

  // handle jobs error response
  useEffect(() => {
    if (atsJobsResponseError) {
      setJobsError(true)
      setJobsIsLoading(false)
    }
  }, [atsJobsResponseError])

  return {
    refetchAtsJobs,
    jobs,
    jobsIsLoading,
    jobsError,
    getAtsJobById,
    job,
    jobError,
    jobIsLoading
  }
}
