import React, { createContext, useEffect, useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export type Route = {
  to: string | undefined
  from: string | undefined
}

export type RouteHistoryContextData = {
  route: Route
}

export const RouteHistoryContext = createContext<RouteHistoryContextData>({
  route: { to: undefined, from: undefined }
})

export const RouteHistoryProvider = ({ children }: any): JSX.Element => {
  const location = useLocation()
  const [route, setRoute] = useState<Route>({
    to: location.pathname,
    from: location.pathname
  })

  useEffect(() => {
    setRoute(prev => ({ to: location.pathname, from: prev.to }))
  }, [location])

  const routeValue = useMemo<RouteHistoryContextData>(
    () => ({ route }),
    [route]
  )

  return (
    <RouteHistoryContext.Provider value={routeValue}>
      {children}
    </RouteHistoryContext.Provider>
  )
}
