import { useEffect } from 'react'
import { loadMicroApp } from 'qiankun'
import { kcEntry } from 'utils/entryProvider'

const KnowledgeCenter = () => {
  useEffect(() => {
    const kcApp = loadMicroApp({
      name: 'kc-portal',
      entry: kcEntry,
      container: '#kc-root',
      props: { isFromKangaroo: true }
    })

    return () => {
      if (kcApp.getStatus() === 'MOUNTED') {
        kcApp.unmount()
      }
    }
    // eslint-disable-next-line
  }, [])

  return null
}

export default KnowledgeCenter
