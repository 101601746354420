import React, { useEffect, useContext } from 'react'

import { loadMicroApp } from 'qiankun'
import { pulsarEntry } from 'utils/entryProvider'
import Client from 'utils/createApolloMaestroClient'
import { AccountContext } from 'context/AccountContext'
import { FeatureFlagsContext } from 'context/FeatureFlagsContext'
import { RouteHistoryContext } from 'context/RouteHistoryContext'
import { NotificationContext } from 'context/NotificationContext'
import LoadingIndicator from 'components/LoadingIndicator'
import PulsarSpacer from 'components/PulsarSpacer'

const Pulsar = () => {
  const {
    data: { currentAccount }
  } = useContext(AccountContext)

  const { isFlagEnabled } = useContext(FeatureFlagsContext)
  const { route } = useContext(RouteHistoryContext)
  const { displayNotification } = useContext(NotificationContext)

  useEffect(() => {
    const app = loadMicroApp({
      name: 'pulsar', // app name registered, same as the one in package.json
      entry: pulsarEntry,
      container: '#pulsar-root',
      props: {
        currentAccount,
        maestroClient: Client(),
        isFlagEnabled,
        route,
        displayNotification
      }
    })

    return () => {
      if (app.getStatus() === 'MOUNTED') {
        app.unmount()
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <PulsarSpacer>
      <LoadingIndicator timeout={5000} />
    </PulsarSpacer>
  )
}

export default Pulsar
