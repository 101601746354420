/* eslint-disable no-console */
import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react'
import { ApolloError } from '@apollo/client'
import { Navigate } from 'react-router-dom'
import { AlertColor, Box, Typography } from '@mui/material'
import { AccountContext } from 'context/AccountContext'
import { AdminContext } from 'context/AdminContext'
import { hasAccessToAdminPortal } from 'utils/authCheck'
import { getFormattedDate } from 'utils/adminData'
import {
  EmployerLoginType,
  UpdateEmployerNameResponseType
} from 'model/organization'
import AlertErrorMessage from 'components/AlertErrorMessage'
import DeactivateConfirm from 'components/DeactivateConfirm'
import { EmployerActivationContext } from 'context/EmployerActivationContext'
import SidePanelHeader from './SidePanelHeader'
import SidePanelBody from './SidePanelBody'
import SidePanelFooter from './SidePanelFooter'

interface EmployerSidePanelProps {
  closeSidePanel: () => void
}

const EmployerSidePanel = ({ closeSidePanel }: EmployerSidePanelProps) => {
  const errorPathname = '/unauthorized'
  const {
    data: { currentAccount }
  } = useContext(AccountContext)
  const {
    selectedEmployer,
    hasAccessToSearchParamEmployer,
    updateEmployerName,
    updateEmployerNameError,
    updateEmployerNameResponse,
    loginType
  } = useContext(AdminContext)

  const {
    activateEp,
    activateEpError,
    activateEpSuccess,
    activateEpUnauthorized,
    setActivateEpSuccess,
    setActivateEpError,
    setActivateEpUnauthorized,
    deactivateEp,
    deactivateEpError,
    deactivateEpSuccess,
    deactivateEpUnauthorized,
    setDeactivateEpSuccess,
    setDeactivateEpError,
    setDeactivateEpUnauthorized
  } = useContext(EmployerActivationContext)

  const [userId, setUserId] = useState<string>('')
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [lastEpUpdated, setLastEpUpdated] = useState(updateEmployerNameResponse)
  const [editErrorMessage, setEditErrorMessage] = useState('')
  const [editSeverity, setEditSeverity] = useState<AlertColor>('error')
  const [openEditAlert, setOpenEditAlert] = useState<boolean>(false)
  const [selectionSet, setSelectionSet] = useState(
    updateEmployerNameError
      ? updateEmployerNameError?.graphQLErrors[0]?.extensions?.selectionSet
      : ''
  )
  const [isDeactivating, setIsDeactivating] = useState<boolean>(false)
  const [deactivateEmployerInProgress, setDeactivateEmployerInProgress] =
    useState<boolean>(false)
  const [isActivating, setIsActivating] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [openAlert, setOpenAlert] = React.useState<boolean>(false)
  const [severity, setSeverity] = React.useState<AlertColor>('error')

  const handleEditError = useCallback(
    (requestError: ApolloError | undefined) => {
      const message =
        requestError && requestError?.graphQLErrors[0]
          ? requestError?.graphQLErrors[0]?.message
          : 'There was an error. Please try again.'
      setEditSeverity('error')
      setEditErrorMessage(message)
      setOpenEditAlert(true)
      setTimeout(() => {
        setOpenEditAlert(false)
      }, 5000)
    },
    []
  )

  const handleEditSuccess = useCallback(
    (
      response: UpdateEmployerNameResponseType | undefined | null,
      isUpdateName?: boolean
    ) => {
      const message = isUpdateName
        ? 'The organization name has been updated.'
        : `The organization ${response?.name} has successfully been created.`
      setEditSeverity('success')
      setEditErrorMessage(message)
      setOpenEditAlert(true)
      setTimeout(() => {
        setOpenEditAlert(false)
      }, 5000)
    },
    []
  )

  const deactivateEmployer = (code: string) => {
    deactivateEp(code)
  }

  useEffect(() => {
    if (currentAccount && currentAccount.userId) {
      setUserId(currentAccount?.userId)
      setIsAdmin(hasAccessToAdminPortal(currentAccount))
    }
  }, [currentAccount])

  useMemo(() => {
    if (
      updateEmployerNameResponse &&
      updateEmployerNameResponse !== lastEpUpdated
    ) {
      setLastEpUpdated(updateEmployerNameResponse)
      handleEditSuccess(updateEmployerNameResponse, true)
    }
  }, [updateEmployerNameResponse, lastEpUpdated, handleEditSuccess])

  useMemo(() => {
    if (
      updateEmployerNameError &&
      selectionSet !==
        updateEmployerNameError?.graphQLErrors[0]?.extensions?.selectionSet
    ) {
      handleEditError(updateEmployerNameError)
    }
  }, [updateEmployerNameError, handleEditError, selectionSet])

  useMemo(() => {
    if (deactivateEpSuccess) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
        setDeactivateEpSuccess(false)
      }, 5000)
      setErrorMessage('Employer has been deactivated')
      setSeverity('success')
      setIsDeactivating(false)
      setDeactivateEmployerInProgress(false)
    }
  }, [deactivateEpSuccess, setDeactivateEpSuccess])

  useMemo(() => {
    if (deactivateEpError) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
        setDeactivateEpError(false)
      }, 5000)
      setErrorMessage('There was an error deactivating the employer')
      setSeverity('error')
      setIsDeactivating(false)
      setDeactivateEmployerInProgress(false)
    }
  }, [deactivateEpError, setDeactivateEpError])

  useMemo(() => {
    if (deactivateEpUnauthorized) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
        setDeactivateEpUnauthorized(false)
      }, 5000)
      setErrorMessage('You are not authorized to deactivate this employer')
      setSeverity('warning')
      setIsDeactivating(false)
      setDeactivateEmployerInProgress(false)
    }
  }, [deactivateEpUnauthorized, setDeactivateEpUnauthorized])

  useMemo(() => {
    if (activateEpSuccess) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
        setActivateEpSuccess(false)
      }, 5000)
      setErrorMessage('Employer has been reactivated')
      setSeverity('success')
      setIsActivating(false)
    }
  }, [activateEpSuccess, setActivateEpSuccess])

  useMemo(() => {
    if (activateEpError) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
        setActivateEpError(false)
      }, 5000)
      setErrorMessage('There was an error reactivating the employer')
      setSeverity('error')
      setIsActivating(false)
    }
  }, [activateEpError, setActivateEpError])

  useMemo(() => {
    if (activateEpUnauthorized) {
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
        setActivateEpUnauthorized(false)
      }, 5000)
      setErrorMessage('You are not authorized to reactivate this employer')
      setSeverity('warning')
      setIsActivating(false)
    }
  }, [activateEpUnauthorized, setActivateEpUnauthorized])

  if (!selectedEmployer) return null

  const submitName = async (name: string) => {
    setSelectionSet('')
    updateEmployerName({ name, id: selectedEmployer.id })
  }

  const formattedLoginType = (authType: EmployerLoginType): string => {
    if (!authType) return 'NA'
    if (authType && authType.strategy === 'DOMAIN_LOCK') {
      return `${authType.idpType} SSO`
    }
    if (authType && !authType.strategy) {
      return 'NA'
    }
    return 'Invitation Only'
  }

  if (hasAccessToSearchParamEmployer && isAdmin) {
    return (
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Box>
          <AlertErrorMessage
            severity={editSeverity}
            message={editErrorMessage}
            openAlert={openEditAlert}
            setOpenAlert={setOpenEditAlert}
          />
        </Box>
        <Box>
          <AlertErrorMessage
            severity={severity}
            message={errorMessage}
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
          />
        </Box>
        {isDeactivating ? (
          <Box>
            <DeactivateConfirm
              isEp
              epId={selectedEmployer.id}
              epCode={selectedEmployer.code}
              closeSidePanel={closeSidePanel}
              identifier={selectedEmployer.code}
              deactivateEp={deactivateEmployer}
              deactivateEpInProgress={deactivateEmployerInProgress}
              setIsDeactivating={setIsDeactivating}
              setDeactivateEpInProgress={setDeactivateEmployerInProgress}
            />
          </Box>
        ) : (
          <Box>
            {isActivating ? (
              <Box>
                <Typography>Employer reactivation in progress...</Typography>
              </Box>
            ) : (
              <Box>
                <SidePanelHeader
                  epId={selectedEmployer.id}
                  submitName={submitName}
                  userId={userId}
                  code={selectedEmployer.code}
                  name={selectedEmployer.name}
                  status={selectedEmployer.status}
                />
                <SidePanelBody
                  loginType={formattedLoginType(loginType)}
                  created={getFormattedDate(selectedEmployer.insertedAt)}
                  id={selectedEmployer.id}
                />
                <SidePanelFooter
                  epCode={selectedEmployer.code}
                  epStatus={selectedEmployer.status}
                  setIsDeactivating={setIsDeactivating}
                  setIsActivating={setIsActivating}
                  activateEP={activateEp}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    )
  }
  return <Navigate to={errorPathname} />
}

export default EmployerSidePanel
