import React from 'react'
import { Box } from '@mui/material'
import DeactivateButton from 'components/DeactivateButton'
import ActivateButton from 'components/ActivateButton'

interface IProps {
  epCode: string
  epStatus: string
  setIsActivating: React.Dispatch<React.SetStateAction<boolean>>
  setIsDeactivating: React.Dispatch<React.SetStateAction<boolean>>
  activateEP: (epCode: string) => void
}

const SidePanelFooter = ({
  epCode,
  epStatus,
  setIsActivating,
  setIsDeactivating,
  activateEP
}: IProps) => {
  return (
    <Box
      sx={{
        margin: '100px 0 40px',
        padding: '20px 0',
        borderTop: '1px solid rgba(219, 219, 219, 1)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right'
      }}
    >
      {epStatus.toLowerCase() === 'inactive' ? (
        <ActivateButton
          onClick={() => {
            setIsActivating(true)
            activateEP(epCode)
          }}
        />
      ) : (
        <DeactivateButton
          onClick={() => {
            setIsDeactivating(true)
          }}
        />
      )}
    </Box>
  )
}
export default SidePanelFooter
