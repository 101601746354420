/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { Circle as StatusIcon } from '@mui/icons-material'

interface StatusPillProps {
  status: string
}

const StatusPill = ({ status }: StatusPillProps) => {
  const statusValue: string = status.toLowerCase()

  return (
    <Box
      sx={{
        border:
          statusValue === 'active'
            ? '1px solid #5DEFA9'
            : statusValue === 'pending'
            ? '1px solid #FFCD1A'
            : '1px solid #F12757',
        borderRadius: '5px',
        padding: '5px 10px',
        backgroundColor: 'transparent',
        width: '100px'
      }}
    >
      <Stack
        alignItems='center'
        justifyContent='center'
        direction='row'
        gap={1}
      >
        <StatusIcon
          sx={{
            color:
              statusValue === 'active'
                ? '#5DEFA9'
                : statusValue === 'pending'
                ? '#FFCD1A'
                : '#F12757',
            fontSize: '12px'
          }}
        />
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 700,
            textTransform: 'uppercase'
          }}
        >
          {status}
        </Typography>
      </Stack>
    </Box>
  )
}
export default StatusPill
