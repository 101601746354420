/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  useState,
  useMemo,
  useCallback,
  createContext,
  Context
} from 'react'
import { Requisition } from 'model/atsMock'
import { useAtsMock } from 'hooks/useAtsMock'

export type AtsMockContextType = {
  jobsResponse: Requisition[]
  jobsError: unknown
  jobsAreLoading: boolean
  getJobById: (id: string) => void
  jobByIdResponse: Requisition | undefined | null
  jobByIdError: unknown
  jobByIdIsLoading: boolean
  selectedJobId: string | undefined
  jobResponse: any[]
  jobError: unknown
  jobIsLoading: boolean
  jobCumulativeResponse: any[]
  jobCumulativeError: unknown
  jobCumulativeIsLoading: boolean
}

export const AtsMockContext: Context<AtsMockContextType> =
  createContext<AtsMockContextType>({
    jobsResponse: [] as Requisition[],
    jobsError: null,
    jobsAreLoading: true,
    getJobById: () => {},
    jobByIdResponse: null,
    jobByIdError: null,
    jobByIdIsLoading: true,
    selectedJobId: '',
    jobResponse: [],
    jobError: null,
    jobIsLoading: true,
    jobCumulativeResponse: [],
    jobCumulativeError: null,
    jobCumulativeIsLoading: true
  })

export const AtsMockProvider = ({ children }: any): JSX.Element => {
  const {
    jobsResponse,
    jobsAreLoading,
    jobsError,
    getJobDataById,
    jobByIdError,
    jobByIdIsLoading,
    jobResponse,
    jobError,
    jobIsLoading,
    jobCumulativeResponse,
    jobCumulativeError,
    jobCumulativeIsLoading
  } = useAtsMock()
  const [selectedJob, setSelectedJob] = useState<Requisition | undefined>(
    undefined
  )
  const [selectedJobId, setSelectedJobId] = useState<string | undefined>('')

  const [jobByIdResponse, setJobByIdResponse] = useState<
    Requisition | undefined
  >(undefined)

  const getJobById = useCallback(
    async (id: string) => {
      if (id) {
        setSelectedJobId(id)
        if (jobsResponse) {
          const job = jobsResponse.find(job => job.id === id)
          setSelectedJob(job)
        }
        await getJobDataById({
          variables: { id }
        })
      }
    },
    [getJobDataById, jobsResponse]
  )

  useMemo(() => {
    if (selectedJob) {
      setJobByIdResponse({
        id: selectedJob.id,
        createdAt: selectedJob.createdAt,
        updatedAt: selectedJob.updatedAt,
        status: selectedJob.status,
        title: selectedJob.title,
        stage: selectedJob.stage,
        descriptionHtml: selectedJob.descriptionHtml,
        analytics: selectedJob.analytics
      } as Requisition)
    }
  }, [selectedJob])

  const values = useMemo(
    () => ({
      jobsResponse,
      jobsAreLoading,
      jobsError,
      getJobById,
      jobByIdResponse,
      jobByIdError,
      jobByIdIsLoading,
      selectedJobId,
      jobResponse,
      jobError,
      jobIsLoading,
      jobCumulativeResponse,
      jobCumulativeError,
      jobCumulativeIsLoading
    }),
    [
      jobsResponse,
      jobsAreLoading,
      jobsError,
      getJobById,
      jobByIdResponse,
      jobByIdError,
      jobByIdIsLoading,
      selectedJobId,
      jobResponse,
      jobError,
      jobIsLoading,
      jobCumulativeResponse,
      jobCumulativeError,
      jobCumulativeIsLoading
    ]
  )

  return (
    <AtsMockContext.Provider value={values}>{children}</AtsMockContext.Provider>
  )
}
