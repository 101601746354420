/* eslint-disable no-console */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  Box,
  Button,
  Stack,
  Typography,
  Tooltip,
  TooltipProps,
  styled,
  OutlinedInputProps,
  TextField,
  TextFieldProps,
  tooltipClasses
} from '@mui/material'
import { Color } from '@mathison-inc/components'
import { Business as BusinessIcon } from '@mui/icons-material'
import { AdminContext } from 'context/AdminContext'
import LoadingIndicator from 'components/LoadingIndicator'
import { ApolloError } from '@apollo/client'
import { CreateEmployerResponseType } from 'model/organization'

export const PanelTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement='top-end' />
))(() => ({
  inset: 'auto 0px -12px auto !important',
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Color.main,
    color: Color.white,
    fontFamily: 'IBM Plex Sans',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    padding: '8px 10px',
    maxWidth: '600px'
  }
}))

interface TextFieldInputProps {
  type?: 'name' | 'code'
  value: string
  countLimit: number
}

export const TextFieldInput = styled(
  (props: TextFieldProps & TextFieldInputProps) => (
    <TextField
      InputProps={
        {
          disableUnderline: true,
          endAdornment: (
            <Typography
              variant='body2'
              sx={{ position: 'absolute', bottom: '12px', right: '8px' }}
            >
              {props.value.length} / {props.countLimit}
            </Typography>
          )
        } as Partial<OutlinedInputProps>
      }
      {...props}
    />
  )
)(({ theme, type }) => {
  const baseStyles = {
    '& .MuiFilledInput-root': {
      overflow: 'hidden',
      borderRadius: 8,
      backgroundColor: Color.white,
      border: '1px solid',
      borderColor: Color.grey100,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow'
      ]),
      '&:hover': {
        backgroundColor: Color.grey50
      },
      '&.Mui-focused': {
        backgroundColor: Color.grey50,
        borderColor: Color.main
      },
      '& .MuiInputBase-input':
        type === 'name'
          ? {
              fontFamily: 'IBM Plex Sans',
              fontWeight: 700,
              fontSize: 28,
              lineHeight: '32px',
              letterSpacing: '-0.02rem'
            }
          : {}
    }
  }
  const nameStyles = {
    '& .MuiInputBase-input': {
      fontFamily: 'IBM Plex Sans',
      fontWeight: 700,
      fontSize: 28,
      lineHeight: '32px',
      letterSpacing: '-0.02rem'
    }
  }
  if (type === 'name') {
    return { ...baseStyles, ...nameStyles }
  }
  return baseStyles
})

interface IProps {
  closeSidePanel: (onExitedCallback?: any) => void
  handleError: (error?: ApolloError) => void | undefined
  handleSuccess: (
    response: CreateEmployerResponseType | undefined | null
  ) => void
}

const CreateEpSidePanel = ({
  closeSidePanel,
  handleError,
  handleSuccess
}: IProps) => {
  const {
    createEmployer,
    createEmployerError,
    createEmployerResponse,
    refetchOrgs
  } = useContext(AdminContext)
  const [selectionSet, setSelectionSet] = useState(
    createEmployerError
      ? createEmployerError?.graphQLErrors[0]?.extensions?.selectionSet
      : ''
  )
  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [lastEpCreated, setLastEpCreated] = useState(createEmployerResponse)

  const checkError = useCallback(() => {
    const letters = /^[A-Za-z]+$/
    switch (true) {
      case !name:
        setError('no-name')
        break
      case !code:
        setError('no-code')
        break
      case name.length < 5:
        setError('name-less-5')
        break
      case name.length > 75:
        setError('name-greater-75')
        break
      case code && code.length !== 4:
        setError('code-incorrect-length')
        break
      case !code.match(letters):
        setError('code-incorrect-format')
        break
      default:
        setError('')
    }
  }, [name, code])

  useEffect(() => {
    checkError()
  }, [checkError])

  const errorMessages: any = {
    'no-name': (
      <>
        You need to <strong>add the Employer name</strong>, and{' '}
        <strong>add a code</strong> before creating this EP
      </>
    ),
    'name-less-5': 'Goal title must be at least 5 characters',
    'name-greater-75': 'Goal title must be less than 300 characters',
    'code-incorrect-length': 'Code must be exactly 4 characters',
    'code-incorrect-format': 'Code must only contain letters',
    'no-code': 'You must add a 4-character code'
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    setSelectionSet('')
    createEmployer({ name, code: code.toUpperCase(), status: 'active' })
  }

  useMemo(() => {
    if (createEmployerResponse && createEmployerResponse !== lastEpCreated) {
      setLastEpCreated(createEmployerResponse)
      handleSuccess(createEmployerResponse)
      refetchOrgs()
    }
  }, [createEmployerResponse, lastEpCreated, handleSuccess, refetchOrgs])

  useMemo(() => {
    if (
      createEmployerError &&
      selectionSet !==
        createEmployerError?.graphQLErrors[0]?.extensions?.selectionSet
    ) {
      handleError(createEmployerError)
    }
  }, [createEmployerError, handleError, selectionSet])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {isLoading && !createEmployerError ? (
        <LoadingIndicator />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h2'>Add New EP/Org</Typography>
          <Box
            sx={{
              padding: '10px 0',
              margin: '20px 0',
              borderBottom: '1px solid #e5e5e5'
            }}
          >
            <Stack direction='row'>
              <BusinessIcon
                sx={{
                  fontSize: '18px',
                  color: '#3148D3',
                  marginTop: '1px',
                  marginRight: '5px'
                }}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 700,
                  textTransform: 'uppercase'
                }}
              >
                Org Details
              </Typography>
            </Stack>
          </Box>
          <TextFieldInput
            countLimit={75}
            label={
              <Typography variant='body20Light' lineHeight='32px'>
                Name
                <Typography variant='body20Light' color={Color.error}>
                  *
                </Typography>
              </Typography>
            }
            value={name}
            variant='filled'
            type='name'
            minRows={1}
            multiline
            sx={{ marginTop: '14px' }}
            onChange={e => {
              e.stopPropagation()
              const newName = e.target.value
              setName(newName)
            }}
          />
          <TextFieldInput
            countLimit={4}
            label={
              <Typography variant='body20Light' lineHeight='12px'>
                Code
                <Typography variant='body20Light' color={Color.error}>
                  *
                </Typography>
              </Typography>
            }
            value={code.toUpperCase()}
            variant='filled'
            type='code'
            minRows={1}
            sx={{
              marginTop: '14px',
              maxWidth: '180px'
            }}
            onChange={e => {
              e.stopPropagation()
              const newCode = e.target.value
              setCode(newCode)
            }}
          />
          <Stack direction='row' justifyContent='flex-start' marginTop={5}>
            <Stack direction='row' spacing={3.5}>
              <Button
                variant='underline'
                aria-label='cancel-create-ep'
                onClick={() => closeSidePanel()}
              >
                <Typography variant='body16'>Cancel</Typography>
              </Button>
              <PanelTooltip title={error && <Box>{errorMessages[error]}</Box>}>
                <Box>
                  <Button
                    variant='contained'
                    aria-label='create-ep-button'
                    disabled={Boolean(error)}
                    onClick={e => {
                      e.preventDefault()
                      closeSidePanel()
                      handleSubmit()
                    }}
                  >
                    Create EP
                  </Button>
                </Box>
              </PanelTooltip>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  )
}

export default CreateEpSidePanel
