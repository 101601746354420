import React from 'react'
import { Box, Alert, Collapse, IconButton, AlertColor } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

interface IProps {
  message: string
  openAlert: boolean
  setOpenAlert: React.Dispatch<React.SetStateAction<boolean>>
  severity: AlertColor
}

const AlertErrorMessage = ({
  message,
  openAlert,
  setOpenAlert,
  severity
}: IProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={openAlert}>
        <Alert
          variant='filled'
          severity={severity || 'error'}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setOpenAlert(false)
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  )
}
export default AlertErrorMessage
