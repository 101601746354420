export const filterDataByGenderEthnicity = (
  data: any,
  filterType: 'GENDER' | 'RACE'
) => {
  if (!data) {
    return []
  }
  const filteredData: any[] = data.filter(
    (datum: any) => datum.type === filterType || datum.type === 'TOTAL'
  )
  return filteredData
}
